import React, { Component } from 'react';
import $ from 'jquery';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { shipStationOrders } from "../../../redux/actions/supplier/shipStationOrders";
class supplierShipmentOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            responseApi: false
        }
    }

    componentDidMount() {               // loads before page loads
        this.props.shipStationOrders(this.props.auth.user.supplier_type);

    }

    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.orders !== prevProps.orders) {
            if (this.props.orders.success) {
                this.setState({ orders: this.props.orders.message, responseApi: true })
            }
        }
    }
    handleShip = (orderID) => {
        this.props.shipOrder(orderID);
    }
    render() {
        var orders = this.state.orders;
        var shop = '';
        var displayLoader = '';
        var opts = {};
        if (this.state.disableBtn) {
            opts['disabled'] = 'disabled';
        }

        console.log("this.state.orders", orders)
        if (this.state.responseApi) {
            displayLoader = 'd-none';
        }
        if (orders.length > 0) {
            shop = 'Store: ' + this.state.orders[0].store;
        }
        if (!$.fn.DataTable.isDataTable('#myStoreTable')) {
            $('#myStoreTable').DataTable({
                data: this.state.orders,
                columns: [
                    { title: "Order ID", data: "order_id" },
                    { title: "Shipment Object ID", data: "shipment_objectId" },
                    { title: "Shimpment date", data: "shipment_date" },
                    { title: "Shimpment Status", data: "tracking_status" },
                    {
                        data: null,
                        className: "center",
                        render: function (data) {
                            return `<a href="/supplier/shipping/details/${data.id}" class="btn btn-info btn-sm link" style="color:white">Details</a>`;
                        }
                    }
                ],
                "order": [[1, "desc"]],
                ordering: true,
                // createdRow: function (row, data, dataIndex) {
                //     if (data['order_status'] == 'in_transit') {
                //         $(row).addClass('successTableRow');
                //     }
                //     else if (data['order_status'] == 'failure') {
                //         $(row).addClass('dangerTableRow');
                //     }
                //     else if (data['order_status'] == 'label_printed') {
                //         $(row).addClass('warningTableRow');
                //     }
                //     else {
                //         $(row).addClass('bg-light');
                //     }
                // }
            });
        }
        const table = {
            width: "100%",
        };
        return (
            <div className="storeOrderTable text-center" style={{ margin: "auto", height: "100%" }}>
                <img className={displayLoader} src="/media/loading.gif" style={{ maxWidth: '50px', position: "absolute", top: "50%" }} />
                <div className="bg-white p-2 mb-3 text-center"><h4>{shop}</h4></div>
                <table id="myStoreTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>
                </table>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
            </div>)
    }
}
const mapStateToProps = state => (
    // console.log('here',state)      // getting response  of api and loading in props
    {
        orders: state.ShipStationOrders,
        auth: state.auth,
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    shipStationOrders: (type) => shipStationOrders(type, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(supplierShipmentOrders);