import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import GiftModal from '../giftcardModal';
import moment from 'moment';
import './style.css'

export default function GiftCards({ data }) {
    const [enabled, setEnabled] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [processing, setProcessing] = useState(null)
    
    const handleChange = (value) => {
    }

    const handleModel = (item, value, processing) => {
        setModalData(item)
        setShowModal(value)
        setProcessing(processing)
    }
    return (
        <>
            <div className='main-inner-left-last-tbl'>
                <div className='main-fill-scroll'>
                    <div className='main-inr-tbl-data main-ursor-scrol'>
                        <GiftModal 
                            show={showModal}
                            data={modalData}
                            processing={processing}
                            onHide={() => setShowModal(false)}
                        />
                        <table>
                            <tr className='none-bodr'>
                                <th>Code</th>
                                <th>Status</th>
                                <th>Customer</th>
                                <th>Date Issued</th>
                                <th>Remaining / Value</th>
                            </tr>
                            {data !== null ?
                                data.length > 0 ?
                                    data.map((itm, idx) => {
                                        const processing = parseFloat(itm.balance) / parseFloat(itm.initial_value) * 100

                                        return (
                                            <tr key={idx} onClick={() => handleModel(itm, !showModal, processing)}>
                                                <td>Code Ending {itm.last_characters}</td>
                                                <td>
                                                    <button 
                                                        className={`${itm.disabled_at !== null ? 'bg-warning text-white' : itm.expires_on !== null ? 'bg-danger text-dark' : 'Shipped'}`} 
                                                        onClick={() => handleChange(itm.disabled_at)}
                                                    >
                                                        {itm.disabled_at !== null ? 'Disabled' : itm.expires_on !== null ? 'Expired' : 'Enabled'}
                                                    </button>
                                                </td>
                                                <td>{itm.customer_name || 'N/A' }</td>
                                                <td>{moment(itm.created_at).format("MMM DD, YYYY")}</td>
                                                <td>${itm.balance} / ${itm.initial_value}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="5" rowSpan="4" className='text-center'>No Data Found</td>
                                    </tr>
                                :
                                <tr>
                                    <td colSpan="5" rowSpan="4" className='text-center'>
                                        <Spinner animation="border" variant="primary" />
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
