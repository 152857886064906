import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'

export default function MyVerticallyCenteredModal(props) {


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Gift Card Details
                </Modal.Title>
                <div className='close-icon' onClick={props.onHide}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.3125 1.3125L12.6875 12.6875M12.6875 1.3125L1.3125 12.6875L12.6875 1.3125Z" stroke="#C5C5C5" strokeWidth="1.5" strokeLinecap="round" strokLinejoin="round" />
                    </svg>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6 text-left'>
                        <div className='main-inner-bar-modal'>
                            <p>Current Balance</p>
                        </div>
                    </div>
                    <div className='col-md-6 text-right'>
                        <div className='main-right-br'>
                            {props.data && props.data !== null ? 
                                <p>${props.data.balance} / ${props.data.initial_value}</p>
                                : 
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='main-inr-progress-br-mai'>
                            <div>
                                <ProgressBar now={props.processing} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4 text-left'>
                        <div className='main-iner-tbl-of-this-mdl'>
                            <p>Order</p>
                            <h4>#{props.data && props.data !== null && props.data.order_id ? props.data.order_id : 'N/A' }</h4>
                        </div>
                    </div>
                    <div className='col-md-4 text-left'>
                        <div className='main-iner-tbl-of-this-mdl'>
                            <p>Gift Card Products</p>
                            <h4>Code Ending {props.data && props.data !== null && props.data.last_characters }</h4>
                        </div>
                    </div>
                    <div className='col-md-4 text-left'>
                        <div className='main-iner-tbl-of-this-mdl'>
                            <p>Expiration Date</p>
                            <h4>
                                {props.data && props.data !== null && props.data.expires_on ?
                                        moment(props.data && props.data !== null && props.data.expires_on).format("MMM DD, YYYY")
                                    : 
                                        'N/A'
                                }
                            </h4>
                        </div>
                    </div>
               </div>
            </Modal.Body>
        </Modal>
    );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);