import axios from "axios";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const IS_LOGIN = "IS_LOGIN";


// calling api to login
export const loginUser = (email, password, dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/login?email=' + email + '&password=' + password)
        .then(response => {
            if (response.data === "wrong password" || response.data === "email not found") {
                dispatch({
                    type: LOGIN_USER_FAILURE,
                    message: response.data
                });
            }
            else {
                localStorage.setItem('user',JSON.stringify(response.data.data));
                localStorage.setItem('token',response.data.token);
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                    message: response.data.data,
                    token: response.token
                });
            }

        }).catch(error => {
            console.log("error", error)
        });
};

