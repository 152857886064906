import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react'
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.mode == 'add' && 'Add New Store' }
                    {props.mode == 'edit' && 'Edit Store' }
                    {props.mode == 'view' && 'View Store' }
                </Modal.Title>
                <div className='close-icon' onClick={props.onHide}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.3125 1.3125L12.6875 12.6875M12.6875 1.3125L1.3125 12.6875L12.6875 1.3125Z" stroke="#C5C5C5" strokeWidth="1.5" strokeLinecap="round" strokLinejoin="round" />
                    </svg>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='main-innermodal-inputs'>
                            <label>Store Name</label>
                            <input type='text'></input>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='main-innermodal-inputs'>
                            <label>Owner First Name</label>
                            <input type='text'></input>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='main-innermodal-inputs'>
                            <label>Owner Last Name</label>
                            <input type='text'></input>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='main-innermodal-inputs'>
                            <label>Email Address</label>
                            <input type='email'></input>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                <Button className='main-save-btn' onClick={props.onHide}>Add New Store</Button>
            </Modal.Footer>
        </Modal>
    );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);