import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';

class AllNotifications extends Component {
  state = {
    notifications: []
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + 'api/all-notifications')
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ notifications: booksList });
      });
  }

  render() {
    let columns = [
      { title: "Order Id", data: "order_id" },
      { title: "Shop Name", data: "shop_name" },
      { title: "Created On", data: "created_at" },
      {
        data: null,
        className: "center",
        render: function (data, type, row) {
          return `<a href="/orderDetails/${data.order_id}/${data.shop_name}/${data.access_token}" class=" btn btn-info btn-sm link" style="color:white">View</a>`;
        }
      }
    ];
    if (!$.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable({
        data: this.state.notifications,
        columns,
        ordering: true,
      });
    }
    const table = {
      width: "100%",
    };

    return (
      <div>
        <h3>All Notifications</h3>
        <table id="myTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>

        </table>
      </div>
    )
  }

}

export default AllNotifications;