import React from 'react'
import {Link} from "react-router-dom";
import { useLocation } from "react-router";

export default function BreadCrumb({ type }) {
      let location = useLocation()

  return (
      <div className='main-inner-breadcrum'>
            {type == 'order_details' ?
                  <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/all-orders"> Orders </Link> - <Link to={location.pathname}> Order details </Link> </h4>
                  :
                  type == 'orders' ?
                        <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/all-orders"> Orders </Link></h4>
                        :
                        type == 'stores' ?
                              <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/stores/all"> Stores </Link></h4>
                              :
                              type == 'giftcards' ?
                                    <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/latest-gift-cards"> Manage Gift Cards </Link></h4>
                                    :
                                    type == 'help' ?
                                          <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/help"> Help </Link></h4>
                                          :
                                          type == 'notification' ?
                                                <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/notifications"> Notifications </Link></h4>
                                                :
                                                type == 'top-product' ?
                                                      <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/top-products"> Top Products </Link></h4>
                                                      :
                                                      type == 'inventory' ?
                                                            <h4> <Link to="/latest-dashboard"> Home </Link> - <Link to="/inventory"> Inventory </Link></h4>
                                                            :
                                                            <h4> <Link to="/latest-dashboard"> Home </Link></h4>
            }
      </div>
  )
}
