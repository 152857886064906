import React from 'react'
// import { Button } from 'react-bootstrap'
import { useState } from 'react'
import './style.css'

export default function Accordian() {
    const [trigger, setTrigger] = useState(false)

    return (
        <>
            <div className='main-inner-right-news-cls'>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" key="1" onClick={() => setTrigger(trigger === 1 ? 0 : 1)}>
                            Where does it come from?
                            </button>
                        </h2>
                        <div id="collapseOne" className={`accordion-collapse collapse ${trigger === 1 ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" key="2" onClick={() => setTrigger(trigger === 2 ? 0 : 2)}>
                            Why do we use it?
                            </button>
                        </h2>
                        <div id="collapseTwo" className={`accordion-collapse collapse ${trigger === 2 ? 'show' : ''}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" key="3" onClick={() => setTrigger(trigger === 3 ? 0 : 3)}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            </button>
                        </h2>
                        <div id="collapseThree" className={`accordion-collapse collapse ${trigger === 3 ? 'show' : ''}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" key="4" onClick={() => setTrigger(trigger === 4 ? 0 : 4)}>
                            There are many variations of passages of Lorem
                            </button>
                        </h2>
                        <div id="collapseThree" className={`accordion-collapse collapse ${trigger === 4 ? 'show' : ''}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" key="5" onClick={() => setTrigger(trigger === 5 ? 0 : 5)}>
                            Where does it come from?
                            </button>
                        </h2>
                        <div id="collapseThree" className={`accordion-collapse collapse ${trigger === 5 ? 'show' : ''}`} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
