import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios'

class StoreOrdersSwag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      orders: [],
      shop: '',
      token: '',
      responseApi: false
    };
  }



  componentDidMount() {

    const info = {
      shop: this.props.match.params.shop,
      token: this.props.match.params.token
    }
    fetch(process.env.REACT_APP_API_URL + 'api/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    })
      .then((response) => response.json())
      .then(ordersList => {
        console.log('ordersList ::::', ordersList)
        //this.setState({ ordersList });
        this.setState({ order: ordersList, responseApi: true });


      });
    //console.log(this.state.order); 
  }
  async getUsersData() {
    const res = await axios.get(process.env.REACT_APP_API_URL + 'api/orders/pagination?shop=' + this.props.match.params.shop + '&token=' + this.props.match.params.token);
    console.log('res.data :::::', res.data)
    this.setState({ loading: false, orders: res.data });
  }
  
  // Init table data as component is mounted
  componentDidMount() {
    this.getUsersData().then(() => this.syncTable());
  }
  syncTable() {
    var shop = this.props.match.params.shop;
    var token = this.props.match.params.token;
    if (!$.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable({
        data: this.state.orders.aaData,
        ordering: true,
        order: [[0, "desc"]],
        processing: true,
        columns: [
          { title: "Order ID", data: "order_id" },
          { title: "Order Number", data: "order_number" },
          { title: "SanMar Order Status", data: "sanmar_status" },
          { title: "SanMar tracking no", data: "sanmar_tracking" },
          { title: "Alphabroder Order Status", data: "alphabroder_status" },
          { title: "Alphabroder tracking no", data: "alphabroder_tracking" },
          { title: "SS Order Status", data: "ss_status" },
          { title: "SS tracking no", data: "ss_tracking" },
          { data: null, className: "center", render: function (data) { return `<a href="/orderDetails/${data.order_id}/${shop}/${token}" class="btn btn-info btn-sm link" style="color:white">Order Details</a>`; } }
        ],
        createdRow: function (row, data, dataIndex) {
          // console.log("row", $(row).find('td:nth-child(2)').css('color','red'));
          if (data['sanmar_status'] == 'Delivered') {
            $(row).find('td:nth-child(3)').addClass('successTableRow');
          }
          else if (data['sanmar_status'] == 'Shipped') {
            $(row).find('td:nth-child(3)').addClass('infoTableRow');
          }
          else if (data['sanmar_status'] == 'Cancelled') {
            $(row).find('td:nth-child(3)').addClass('dangerTableRow');
          }
          else if (data['sanmar_status'] == 'In Progress') {
            $(row).find('td:nth-child(3)').addClass('warningTableRow');
          }
          else {
            $(row).find('td:nth-child(3)').addClass('bg-light');
          }
          //alpha
          if (data['alphabroder_status'] == 'Delivered') {
            $(row).find('td:nth-child(5)').addClass('successTableRow');
          }
          else if (data['alphabroder_status'] == 'Shipped') {
            $(row).find('td:nth-child(5)').addClass('infoTableRow');
          }
          else if (data['alphabroder_status'] == 'Cancelled') {
            $(row).find('td:nth-child(5)').addClass('dangerTableRow');
          }
          else if (data['alphabroder_status'] == 'In Production') {
            $(row).find('td:nth-child(5)').addClass('warningTableRow');
          }
          else {
            $(row).find('td:nth-child(5)').addClass('bg-light');
          }
          //SS
          if (data['ss_status'] == 'Delivered') {
            $(row).find('td:nth-child(7)').addClass('successTableRow');
          }
          else if (data['ss_status'] == 'In_transit') {
            $(row).find('td:nth-child(7)').addClass('infoTableRow');
          }
          else if (data['ss_status'] == 'Failure' || data['ss_status'] == 'Cancelled') {
            $(row).find('td:nth-child(7)').addClass('dangerTableRow');
          }
          else if (data['ss_status'] == 'label_printed') {
            $(row).find('td:nth-child(7)').addClass('warningTableRow');
          }
          else {
            $(row).find('td:nth-child(7)').addClass('bg-light');
          }
        }
      });
    }
  }
  render() {
    var displayLoader = '';
    if (this.state.responseApi) {
      displayLoader = 'd-none';
    }
    const table = {
      width: "100%",
    };

    return (
      <div className="storeOrderTable text-center" style={{ margin: "auto", height: "100%" }}>
        <img className={displayLoader} src="/media/loading.gif" style={{ maxWidth: '50px', position: "absolute", top: "50%" }} />
        <table id="myTable" responsive="true" hover="true" className="project-table table table-striped" style={table}>
        </table>
      </div>
    )
  }

}

export default StoreOrdersSwag;