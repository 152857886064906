import React, { Component } from "react";
import { connect } from 'react-redux';
import { getShops } from '../../redux/actions/getShops';
import { addManager } from '../../redux/actions/addManager';
class createManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shops: [],
            errorMsg: ""
        };
    }
    componentDidMount() {
        this.props.getShops();
    }
    componentDidUpdate(prevProps) {
        if (this.props.res !== prevProps.res) {
            if (this.props.res.success) {
                this.setState({ shops: this.props.res.message });
            }
        }
        if (this.props.added !== prevProps.added) {
            let errorMsg = "";
            if (this.props.added.success) {
                const { history: { push } } = this.props;
                push('/managers');
            }
            else {
                errorMsg = this.props.added.message;
            }
            this.setState({ errorMsg })
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let name = e.target.name.value;
        let email = e.target.email.value;
        let password = e.target.password.value;
        let store = e.target.store.value;
        let location = e.target.location.value;
        let errorMsg = "";
        if (name === "" || email === "" || password === "" || store === "-1" || location === "") {
            errorMsg = "Please fill all required fields";
        }
        else {
            this.props.addManager(name, email, password, store, location);
        }
        this.setState({ errorMsg });
    }
    render() {
        return (
            <form className="demoForm" onSubmit={this.handleSubmit}>
                <div className={this.state.errorMsg !== "" ? "p-5 my-5 bg-danger text-white" : "d-none"}>{this.state.errorMsg}</div>
                <h2>Add Brand Manager</h2>
                <div className="form-group">
                    <label htmlFor="name">Name<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" name="name" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email<span className="text-danger">*</span></label>
                    <input type="email" className="form-control" name="email" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password<span className="text-danger">*</span></label>
                    <input type="password" className="form-control" name="password" />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Store<span className="text-danger">*</span></label>
                    <select className="form-control" name="store" >
                        <option value="-1">Select Store</option>
                        {this.state.shops.length > 0 && this.state.shops.map((itm, i) => (
                            <option key={i} value={itm.id} >{itm.shop_name}</option>
                        ))
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" name="location" />
                </div>
                <button type="submit" className="btn btn-primary">
                    Add
                </button>
            </form>
        )
    }

}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        res: state.GetShops,
        auth: state.auth,
        added: state.AddManager
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getShops: () => getShops(dispatch),
    addManager: (name, email, password, store, location) => addManager(name, email, password, store, location, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(createManager);