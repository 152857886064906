import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  MixedWidget1Manager, MixedWidget1Supplier, MixedWidget1Admin, MixedWidget1Printer, MixedWidget1TieDyeAdmin,
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
export function Demo1Dashboard() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  const [stores, setStores] = useState(0);
  const [orders, setOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  useEffect(() => {
    setTimeout(function () {
      if (user.role == undefined) {
        window.location.reload();
      }
      if (user.role != undefined ) {
        let url = user.role == 3 ? 
          process.env.REACT_APP_API_URL + 'api/dashboard?supplier_type=' + user.supplier_type 
          : user.role == 2 ? 
            process.env.REACT_APP_API_URL + 'api/dashboard?store_id=' + user.store_id 
            : user.role == 4 ? 
              process.env.REACT_APP_API_URL + 'api/printer/dashboard' 
          : process.env.REACT_APP_API_URL + `api/admin/dashboard/${user.role}/all`;
        // Update the document title using the browser API
        fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            // 'X-Shopify-Access-Token': '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'
          }
        })
          .then((response) => response.json())
          .then(resp => {
            console.log('resp :::', resp)
            setOrders(resp.orders)
            setDeliveredOrders(resp.deliveredOrders)
            setStores(resp.stores)
            setCancelledOrders(resp.cancelledOrders)
          });
      }
    }, 50)
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-6 col-xxl-4">
          {user.role == 3
            ?
            <MixedWidget1Supplier className="card-stretch gutter-b" />
            : user.role == 2 ?
              <MixedWidget1Manager className="card-stretch gutter-b" />
              :
              user.role == 4 ?
                <MixedWidget1Printer className="card-stretch gutter-b" />
                :
                user.role == 5 ?
                  <MixedWidget1TieDyeAdmin className="card-stretch gutter-b" />
                  :
                  <MixedWidget1Admin className="card-stretch gutter-b" />
          }
        </div>
        <div className="col-6 col-xxl-6">
          {user.role == 3 ?
            <div className="row">
              <div className="col-6">
                <div className="panel padder-v item bg-success p-5 mb-2">
                  <div className="h1 text-light font-thin h1">{orders.length > 0 && orders[0].count}</div>
                  <span className="text-light h6">Orders</span>
                </div>
              </div>
            </div>
            :
            user.role == 2 ?
              <div className="row">
                <div className="col-6">
                  <div className="panel padder-v item bg-success p-5 mb-2">
                    <div className="h1 text-light font-thin h1">{orders.length > 0 && orders[0].count}</div>
                    <span className="text-light h6">Orders</span>
                  </div>
                </div>
              </div>
              :
              user.role == 4 ?
                <div className="row">
                  <div className="col-6">
                    <div className="panel padder-v item bg-danger p-5 mb-2">
                      <div className="h1 text-light font-thin h1">{orders.length > 0 && orders[0].count}</div>
                      <span className="text-light h6">Orders</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="panel padder-v item bg-info p-5 mb-2">
                      <div className="h1 text-white font-thin h1">{stores}</div>
                      <span className="text-white h6">Stores</span>
                    </div>
                  </div>
                </div>
                :
                <div className="col-6">
                  <div className="panel padder-v item bg-info p-5 mb-2">
                    <div className="h1 text-white font-thin h1">{stores}</div>
                    <span className="text-white h6">Total Stores</span>
                  </div>
                </div>
          }
        </div>
        {user.role == 1 ?
          <div className="col-12">
            <div className="row">
              {orders.length > 0 && orders.map((order, key) => (
                order.store !== 'flockandfeathers.myshopify.com' ?
                  <a key={key} href={"/StoreOrdersSwag/" + order.store + "/orders"} style={{display: "contents"}}>
                    <div className="col-6 bg-white">
                      <div className="row border p-2 border-1">
                        <h3 className="mt-3 ml-3 bg-white px-3 py-2 w-100">{order.store}</h3>
                        <div className="col-6 mt-3">
                          <div className="panel padder-v item bg-danger p-5 mb-2">
                            <div className="h1 text-light font-thin h1">{order.count}</div>
                            <span className="text-light h6">Orders</span>
                          </div>
                        </div>
                        <div className="col-6 mt-3">
                          <div className="panel padder-v item bg-success p-5 mb-2">
                            <div className="h1 text-light font-thin h1">
                              {deliveredOrders.map(del => (
                                del.store == order.store ? del.count : ""
                              ))}
                            </div>
                            <span className="text-white h6">Shipped Orders</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="panel padder-v item bg-warning p-5 mb-2">
                            <div className="h1 text-white font-thin h1">
                              {cancelledOrders.map(can => (
                                can.store == order.store ? can.count : ""
                              ))}
                            </div>
                            <span className="text-white h6">Cancelled Orders</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  : ""
              ))}
            </div>
          </div>
          : ""}
      </div>
    </>
  );
}
