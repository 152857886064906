import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { managOrders } from "../../../redux/actions/manager/orders";
import $ from 'jquery';
import DataTable from 'datatables.net';
class managerOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            dimensionError: {},
            disableBtn: false
        }
    }

    componentDidMount() {
        // loads before page loads
        this.props.managOrders(this.props.auth.user.store_id);
    }
    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.orders !== prevProps.orders) {
            if (this.props.orders.success) {
                let orders = this.props.orders.message;
                var error = {};
                if (typeof orders != 'undefined' && orders.length > 0) {
                    this.setState({ orders: orders })
                }
            }
        }
        if (this.props.ShipOrder !== prevProps.ShipOrder) {
            this.setState({ disableBtn: false })
            if (this.props.ShipOrder.success) {
                ToastsStore.success("Shipment has created")
                this.setState({ orders: this.props.ShipOrder.message })
            }
        }
    }
    handleShip = (orderID) => {
        var error = this.state.dimensionError;
        this.setState({ disableBtn: true })
        console.log(error[orderID]);
        if (error[orderID] !== undefined) {
            alert(error[orderID])
            this.setState({ disableBtn: false })
        }
        else {
            this.props.shipOrder(orderID);
        }
    }
    render() {
        var orders = this.state.orders;
        var shop = '';
        var displayLoader = '';
        var opts = {};
        if (this.state.disableBtn) {
            opts['disabled'] = 'disabled';
        }

        if (!$.fn.DataTable.isDataTable('#myStoreTable') && orders.length > 0) {
            displayLoader = 'd-none';
            shop = 'Store: ' + this.state.orders[0].store;
            $('#myStoreTable').DataTable({
                data: this.state.orders,
                columns: [
                    { title: "Order ID", data: "order_id" },
                    { title: "Order Number", data: "order_number" },
                    { title: "User Email", data: "user_email" },
                    { title: "Total Price", data: "total_price" },
                    { title: "Currency", data: "currency" },
                    { title: "Payment Status", data: "payment_status" },
                    { title: "Order Status", data: "order_status" },
                    //{ title: "Processed On", data: "processed_on" },
                    { title: "SS Order Status", data: "ss_order_status" },
                    // { title: "SS Order ID", data: "ss_orderid" },
                    {
                        data: null,
                        className: "center",
                        render: function (data) {
                            return `<a href="/orderDetails/${data.order_id}/${data.shop_name}/${data.access_token}" class="btn btn-info btn-sm link" style="color:white">Order Details</a>`;
                        }
                    }
                ],
                "order": [[1, "desc"]],
                ordering: true,
                createdRow: function (row, data, dataIndex) {
                    if (data['order_status'] == 'in_transit') {
                        $(row).addClass('successTableRow');
                    }
                    else if (data['order_status'] == 'failure') {
                        $(row).addClass('dangerTableRow');
                    }
                    else if (data['order_status'] == 'label_printed') {
                        $(row).addClass('warningTableRow');
                    }
                    else {
                        $(row).addClass('bg-light');
                    }
                }
            });
        }
        const table = {
            width: "100%",
        };
        return (
            <div className="storeOrderTable text-center" style={{ margin: "auto", height: "100%" }}>
                <img className={displayLoader} src="/media/loading.gif" style={{ maxWidth: '50px', position: "absolute", top: "50%" }} />
                <div className="bg-white p-2 mb-3 text-center"><h4>{shop}</h4></div>
                <table id="myStoreTable" responsive="true" hover="true" className="project-table table table-striped" style={table}>
                </table>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
            </div> )
    }
}
const mapStateToProps = state => (
    // console.log('here',state)      // getting response  of api and loading in props
    {
        orders: state.ManagerOrders,
        auth: state.auth,
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    managOrders: (store_id) => managOrders(store_id, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(managerOrders);