import axios from "axios";
export const UPDATE_APP_CHOSEN_SUCCESS = "UPDATE_APP_CHOSEN_SUCCESS";
export const UPDATE_APP_CHOSEN_FAILURE = "UPDATE_APP_CHOSEN_FAILURE";


// calling api to login
export const updateChosenApp = (id,dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/chosen-shop/' + id, {})
        .then(response => {
            if (response.isSuccess === false) {
                dispatch({
                    type: UPDATE_APP_CHOSEN_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: UPDATE_APP_CHOSEN_SUCCESS,
                    message: response.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

