import React, { Component } from "react";
//import $ from 'jquery';

class editsupplier extends Component {
    constructor(props) {

        super(props);

        this.state = {
            name: '',
            email: '',
            location: '',
            userid: '',
            supplier_type: '1',
            error: {}
        };
    }

    componentDidMount() {
        const url = process.env.REACT_APP_API_URL + 'api/getsupplier';
        const id = this.props.match.params.id;
        const input = {
            id: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(input)
        };

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then(booksList => {
                this.setState({ name: booksList.name, email: booksList.email, location: booksList.location, supplier_type: booksList.supplier_type });
            });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    validateForm = () => {
        var name = this.state.name,
            email = this.state.email,
            password = this.state.password,
            location = this.state.location,
            error = {},
            validated = true,
            mail_format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (name.trim() == "") {
            error['name'] = "Name cannot be empty.";
            validated = false;
        }
        if (location.trim() == "") {
            error['location'] = "Location cannot be empty.";
            validated = false;
        }
        if (email.trim() == "") {
            error['email'] = "Email cannot be empty.";
            validated = false;
        }
        else if (!mail_format.test(String(email).toLowerCase())) {
            error['email'] = "Email is not Valid.";
            validated = false;
        }
        this.setState({ error })
        return validated;
    }
    handleSubmit = (e) => {
        e.preventDefault();
        var validate = this.validateForm();
        if (!validate) {
            return;
        }
        const url = process.env.REACT_APP_API_URL + 'api/supplierupdate/' + this.props.match.params.id;
        const user = {
            name: this.state.name,
            email: this.state.email,
            location: this.state.location,
            supplier_type: this.state.supplier_type,
            sendEmail: e.target.send_email.checked
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        };

        fetch(url, requestOptions)
            .then(() => { this.props.history.push('/Suppliers') })
            .catch((e) => {
                console.log(e)
            });

    }



    render() {
        return (
            <form className="demoForm" onSubmit={this.handleSubmit}>
                <h2>Edit Supplier</h2>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control" name="name" required="required" defaultValue={this.state.name || ""} />
                    <span style={{ "color": "red" }}>{this.state.error.name || ""}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" readOnly onChange={this.handleInputChange} className="form-control" name="email" required="required" defaultValue={this.state.email || ""} />
                    <span style={{ "color": "red" }}>{this.state.error.email || ""}</span>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="1" id="send_email" name="send_email" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">Send login credentials via email?</label>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="password">Store</label>
                    <select className="form-control" name="supplier_type" value={this.state.supplier_type} onChange={this.handleInputChange}>
                        <option value="1" >Type 1</option>
                        <option value="2">Type 2</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control" name="location" required="required" defaultValue={this.state.location || ""} />
                    <span style={{ "color": "red" }}>{this.state.error.location || ""}</span>
                </div>
                <button type="submit" className="btn btn-primary">
                    Update Supplier
                </button>
            </form>
        )
    }

}

export default editsupplier;