import React, { Component } from "react";
class addSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            location: '',
            supplier_type: '1',
            error: {}
        };
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    validateForm = () => {
        var name = this.state.name,
            email = this.state.email,
            password = this.state.password,
            location = this.state.location,
            error = {},
            validated = true,
            mail_format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (name.trim() == "") {
            error['name'] = "Name cannot be empty.";
            validated = false;
        }
        if (location.trim() == "") {
            error['location'] = "Location cannot be empty.";
            validated = false;
        }
        if (email.trim() == "") {
            error['email'] = "Email cannot be empty.";
            validated = false;
        }
        else if (!mail_format.test(String(email).toLowerCase())) {
            error['email'] = "Email is not Valid.";
            validated = false;
        }
        if (password.trim() == "") {
            error['password'] = "Password cannot be empty.";
            validated = false;
        }
        else if (password.indexOf(" ") > -1) {
            error['password'] = "Password should not have any space";
            validated = false
        }
        else if (password.length < 6) {
            error['password'] = "Password should be at least 6 characters";
            validated = false
        }
        this.setState({ error })
        return validated;
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        var validate = await this.validateForm();
        if (!validate) {
            return;
        }
        const url = process.env.REACT_APP_API_URL + 'api/addsupplier';
        const user = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            location: this.state.location,
            supplier_type: this.state.supplier_type
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        };
        fetch(url, requestOptions)
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                throw res;
            })
            .then((res) => {
                var error = {};
                if (res.error) {
                    console.log(res.error)
                    error['email'] = res.error;
                }
                else{
                    this.props.history.push('/Suppliers')
                }
                this.setState({error});
            })
            .catch((e) => {
                console.log(e)
            });

    }
    render() {
        return (
            <form className="demoForm" onSubmit={this.handleSubmit}>
                <h2>Add Supplier</h2>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control" name="name" required="required" />
                    <span style={{ "color": "red" }}>{this.state.error.name || ""}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" onChange={this.handleInputChange} className="form-control" name="email" required="required" />
                    <span style={{ "color": "red" }}>{this.state.error.email || ""}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" onChange={this.handleInputChange} className="form-control" name="password" required="required" />
                    <span style={{ "color": "red" }}>{this.state.error.password || ""}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Store<span className="text-danger">*</span></label>
                    <select className="form-control" name="supplier_type" onChange={this.handleInputChange}>
                        <option value="1" >Type 1</option>
                        <option value="2">Type 2</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control" name="location" required="required" />
                    <span style={{ "color": "red" }}>{this.state.error.location || ""}</span>
                </div>

                <button type="submit" className="btn btn-primary">
                    Add Supplier
                </button>
            </form>
        )
    }
}
export default addSupplier;