import * as Actions from '../actions/runningCrons';
export const GET_RUNNING_CRON_SUCCESS = "GET_RUNNING_CRON_SUCCESS";
export const GET_RUNNING_CRON_FAILURE = "GET_RUNNING_CRON_FAILURE";

const initialState = {
    runningCronError: false,
    runningCron: "",
    runningCronSuccess: false
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_RUNNING_CRON_SUCCESS:
          return {
            ...state,
            runningCronSuccess: true,
            runningCronError: 'success',
            runningCron: action.message,
          };
      case Actions.GET_RUNNING_CRON_FAILURE:
          return {
              ...state,
              runningCronSuccess: false,
              runningCronError: 'error',
              runningCron: action.message
            };
      default:
        return state;
    }
  };
  
  export default reducer;
  