import React, { useEffect, useState } from "react";
import TopProductTable from '../../components/TopProductTable/index';
import { shallowEqual, useSelector } from "react-redux";
import BreadCrumb from '../../components/breadCrumb';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
// import './style.css';

export default function Stores() {
    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
    );
    if (user.role == undefined) {
        window.location.reload();
    }


    const { search } = useLocation()
    let store = search && search.split("=")[1] || 'all'
    
    // const [topProducts, setTopProducts] = useState(null);
    // const [selectDashboardStore, setSelectDashboardStore] = useState("all");
    // const [selectStoreForTopProducts, setSelectStoreForTopProducts] = useState( "all" ); 
    // const [filterDate, setFilterDate] = useState("");

    // useEffect(() => {
    //     if (filterDate || topProducts == null) {
    //       let url = `${process.env.REACT_APP_API_URL}api/client/topProducts/${selectStoreForTopProducts}/${filterDate || "all"}`;
    //       if(user.role == 6){
    //         url = `${process.env.REACT_APP_API_URL}api/client/topProducts/${user.store_name}/${filterDate || "all"}`;
    //       }
    //       fetch(url, {
    //         method: "get",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       })
    //         .then((response) => response.json())
    //         .then((resp) => {
    //           let finalData = [];
    //           if (selectStoreForTopProducts == "all") {
    //             const finalData1 = resp.flat();
    
    //             resp.map((itm) => {
    //               finalData.push(itm.products);
    //             });
    //           } else {
    //             resp.map((itm) => {
    //               if (itm.store == selectStoreForTopProducts) {
    //                 finalData.push(itm.products);
    //               }
    //             });
    //           }
    //           finalData = finalData.flat();
    //           finalData = finalData.sort((a, b) => b.total_sold - a.total_sold);
    //           setTopProducts(finalData.slice(0, 10));
    //         });
    //     }
    //   }, [selectDashboardStore]);
    
    //   console.log("topProductsClient >>>>", topProducts)
    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6 px-0'>
                            <BreadCrumb type="top-product"/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-all-str-tbl'>
                                <TopProductTable 
                                    store={store}
                                    roleId={user.role} 
                                    userInfo = {user}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
