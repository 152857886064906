import React from 'react'
import AccordionPage from '../../components/accordian/accordionPage';
import BreadCrumb from '../../components/breadCrumb';
import './style.css'

export default function Help() {

  return (
    <>
      <div className='row mx-0'>
        <div className='col-md-12 main-inr-all-data'>

          <div className='row mx-0 align-items-center'>
            <div className='col-md-12'>
              <BreadCrumb type="help"/>
            </div>
          </div>

          <div className='row mt-5 mx-0'>
            <div className='col-md-12'>
              <div className='main-nr-tbl wi-full'>
                <input type='text' placeholder='Type content to search help topics'></input>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.2501 12.25L9.63325 9.62849L12.2501 12.25ZM11.0834 6.12499C11.0834 7.44002 10.561 8.70119 9.63115 9.63106C8.70128 10.5609 7.44011 11.0833 6.12508 11.0833C4.81005 11.0833 3.54888 10.5609 2.61901 9.63106C1.68914 8.70119 1.16675 7.44002 1.16675 6.12499C1.16675 4.80996 1.68914 3.54879 2.61901 2.61892C3.54888 1.68905 4.81005 1.16666 6.12508 1.16666C7.44011 1.16666 8.70128 1.68905 9.63115 2.61892C10.561 3.54879 11.0834 4.80996 11.0834 6.12499V6.12499Z" stroke="#5A5A5A" strokeLinecap="square" strokeLinejoin="round" />
                </svg>
              </div>
              <div className='inr-heading-label'>
                <h2>Search Results</h2>
              </div>
            </div>
          </div>

          <div className='row mx-0'>
            <div className='col-md-12'>
              <div className='main-of-all-addordians'>
                <AccordionPage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

