import React, { useEffect, useState } from "react";
import GiftCards from '../../components/giftCard'
import { shallowEqual, useSelector } from "react-redux";
import BreadCrumb from '../../components/breadCrumb';

export default function Index() {
    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
    );
    const [giftCards, setGiftCards] = useState(null);
    const [selectStore, setSelectStore] = useState('all');

    useEffect(() => {
        if(giftCards == null){
            // let url = `${process.env.REACT_APP_API_URL}api/admin/giftcards/${selectStore}`
            // fetch(url, {
            //     method: 'get',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     }
            // })
            //     .then((response) => response.json())
            //     .then(resp => {
            //         let finalData = ''
            //         if(selectStore == 'all') {
            //             finalData = resp.gift_cards
            //         }
            //         finalData.flat()
            //         setGiftCards(finalData)
            //     });
            setGiftCards([])
        }
    }, []);
    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-12 px-0'>
                            <BreadCrumb type="giftcards"/>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='col-md-12'>
                            <div className='main-table-aal-data-orders'>
                                <GiftCards data={giftCards}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
