import React, { useEffect, useState } from "react";
import Date from '../../components/date/index';
import Card from '../../components/priceCard/index'
import Table2 from '../../components/dashboardTables/dueOrders'
import Toptable from '../../components/dashboardTables/topProducttable'
import Chart from '../../components/lineChart/index'
import Inventory from '../../components/inventoryTable/index'
import BreadCrumb from '../../components/breadCrumb';
import './style.css'

export default function Dashboard() {
    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6 px-0'>
                            <BreadCrumb type="swagstore"/>
                        </div>
                        <div className='col-md-6 justify-flex-end px-0'>
                            <Date />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-md-3'>
                            <Card />
                        </div>
                        <div className='col-md-3'>
                        </div>
                        <div className='col-md-6'>
                            <div className="icon-with-btn">
                                <button className='main-save-btn'>All Orders
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.27175 0.905015C7.49453 0.781293 7.74517 0.71637 8 0.71637C8.25483 0.71637 8.50547 0.781293 8.72825 0.905015L14.3645 4.03551C14.4814 4.1005 14.5787 4.19553 14.6465 4.31078C14.7143 4.42603 14.75 4.55731 14.75 4.69101V10.8673C14.7499 11.1347 14.6783 11.3974 14.5427 11.6279C14.407 11.8584 14.2121 12.0484 13.9783 12.1783L8.72825 15.0958C8.50547 15.2195 8.25483 15.2844 8 15.2844C7.74517 15.2844 7.49453 15.2195 7.27175 15.0958L2.02175 12.1783C1.78802 12.0485 1.59322 11.8585 1.45754 11.6282C1.32185 11.3978 1.2502 11.1354 1.25 10.868V4.69101C1.24999 4.55731 1.28573 4.42603 1.35351 4.31078C1.42129 4.19553 1.51865 4.1005 1.6355 4.03551L7.2725 0.905015H7.27175Z" stroke="#060640" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 8V15.5M1.25 4.25L8 8L1.25 4.25ZM8 8L14.75 4.25L8 8Z" stroke="#060640" strokeLinejoin="round" />
                                        <path d="M3.5 8.246L5.75 9.5M4.625 6.125L11.375 2.375L4.625 6.125Z" stroke="#060640" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <Chart />
                        </div>
                        <div className='col-md-6 col-12'>
                            <Table2 />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Toptable />
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className='col-md-12'>
                            <Inventory />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
