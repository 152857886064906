import axios from "axios";
export const SUPPLIER_ORDER_DETAIL_SUCCESS = "SUPPLIER_ORDER_DETAIL_SUCCESS";
export const SUPPLIER_ORDER_DETAIL_FAILURE = "SUPPLIER_ORDER_DETAIL_FAILURE";


// calling api to login
export const shippingDetails = (id,dispatch) => {
    var user = JSON.parse(localStorage.getItem('user'));
    axios.get(process.env.REACT_APP_API_URL + 'api/supplier/shipping/orders?id='+id)
        .then(response => {
            if (response.data.length == 0) {
                dispatch({
                    type: SUPPLIER_ORDER_DETAIL_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: SUPPLIER_ORDER_DETAIL_SUCCESS,
                    message: response.data.data,
                    token: response.token
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

