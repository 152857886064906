import React, { Component } from "react";
import { getGiftCard } from '../../../redux/actions/giftcards/getOne';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ViewGiftCard extends Component {
    state = {
        users: [],
        errorMsg: '',
        card: {}
    }
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getGiftCard(id);
    }
    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.update !== prevProps.update) {
            if (this.props.create.success) {
                const { history: { push } } = this.props;
                push('/gift-cards');
            }
        }
        if (this.props.card !== prevProps.card) {
            if (this.props.card.success) {
                this.setState({ card: this.props.card.message.data })
            }
        }
    }
    handleExpriryDate = date => {
        let { card } = this.state;
        card.expires_on = date;
        this.setState({ card })
    }
    handleInputFields = val => {
        let { card } = this.state;
        card[val.target.name] = val.target.value;
        this.setState({ card })
    }
    render() {
        const card = this.state.card;
        return (
            <div>
                <form onSubmit={this.handleUpdateGiftCard} className="createGiftCard">
                    <div className="row mt-3">
                        {card.code &&
                            <div className="col-8">
                                <div className={this.state.errorMsg !== "" ? "p-5 my-5 bg-danger text-white" : "d-none"}>{this.state.errorMsg}</div>
                                <div className="form-group">
                                    <label htmlFor="code">Giftcard Code<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" onChange={(e) => this.handleInputFields(e)} readOnly name="code" value={card.code} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="initial_value">Initial Value<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" onChange={(e) => this.handleInputFields(e)} readOnly name="initial_value" value={card.initial_value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="note">Note<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" onChange={(e) => this.handleInputFields(e)} readOnly name="note" value={card.note} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expires_on">Expires On: <span className="ml-3 text-danger"></span></label>
                                    {/* <input type="text" className="form-control" name="expires_on" value={card.expires_on} /> */}
                                    {card.expires_on}
                                </div>

                            </div>
                        }
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        auth: state.auth,
        card: state.getGiftCard
        
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getGiftCard: (id) => getGiftCard(id, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewGiftCard);

