import axios from "axios";
export const PRINTER_ORDER_SUCCESS = "PRINTER_ORDER_SUCCESS";
export const PRINTER_ORDER_FAILURE = "PRINTER_ORDER_FAILURE";

export const printOrders = ( dispatch) => {

    axios.get(process.env.REACT_APP_API_URL + 'api/all/orders')
        .then(response => {
            if (response.data.length == 0) {
                dispatch({
                    type: PRINTER_ORDER_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: PRINTER_ORDER_SUCCESS,
                    message: response.data,
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

