import React, { useState, useEffect } from 'react'
// import Sidebar from '../../components/sidebar';
import Sidebar from '../../components/topSidebar';
// import Header from '../../components/header';
import Footer from '../../components/footer';
import './style.css'
import { shallowEqual, useSelector } from "react-redux";


export default function AuthLayout({ children }) {
    const [stores, setStores] = useState(localStorage.getItem('stores'));

    const [openSidebar, setOpenSidebar] = useState(false)

    const { user } = useSelector(
        ({ auth }) => ({
            user: auth.user,
        }),
        shallowEqual
    );

    useEffect(() => {
        let url = process.env.REACT_APP_API_URL + 'api/stores/all'
        if(user.role == 6){
            url = process.env.REACT_APP_API_URL + 'api/stores/'+user.store_name
        }
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => response.json())
        .then(resp => {
            localStorage.setItem('stores', JSON.stringify(resp))
            setStores(JSON.stringify(resp))
        });
    }, [])

    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-side-bar'>
                    <Sidebar stores={stores} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} userRole = {user.role}/>
                </div>
                <div className='col-md-12 px-0'>
                    {/* <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}/> */}
                    {children}
                    <Footer />
                </div>
            </div>
        </>
    )
}
