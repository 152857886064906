import React, { useEffect, useState } from 'react'
import Storetable from '../../components/tablePagenation';
import MyVerticallyCenteredModal from '../../components/addStoreModal';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import BreadCrumb from '../../components/breadCrumb';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { shallowEqual, useSelector } from "react-redux";

export default function Stores() {
    const params = useParams()
    const { store } = params
    const [modalShow, setModalShow] = useState(false);
    const [stores, setStores] = useState(null);
    const [mode, setMode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState({
        id: '',
        data: ''
    });

    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
      );

    // useEffect(() => {
    //     // setLoading(true)

    //     let url = `${process.env.REACT_APP_API_URL}api/stores/all`
    //     if(user.role == 6){
    //         url = `${process.env.REACT_APP_API_URL}api/stores/${user.store_name}`
    //     }
    //     fetch(url, {
    //         method: 'get',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then(resp => {
    //             setStores(resp)
    //             // setLoading(false)
    //         });
    // }, [])

    const handleBtnResponse = (data, mode) => {
        if(mode == 'add'){
            console.log('add ::::', mode)
        }else {
            console.log('mode ::::', mode)
        }
        setModalShow(true)
        setMode(mode)
    }
    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6'>
                            <BreadCrumb type="stores"/>
                        </div>
                        <div className='col-md-6 justify-flex-end'>
                            <div className='ad-stor-btn'>
                                {/* <button onClick={() => handleBtnResponse(null, 'add')}>+ Add New Store</button> */}
                                {modalShow &&
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        mode={mode}
                                        onHide={() => setModalShow(false)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-all-str-tbl'>
                                {!loading ?
                                    <Storetable loading={loading} userInfo={user} handleBtnResponse={handleBtnResponse}/>
                                    :
                                    <div className='main-inner-left-table text-center'>
                                        <div className='row align-items-center'>
                                            <div className='col-12'>
                                            </div>
                                        </div>
                                        <table id="storesTable text-center" className="table">
                                            <Spinner animation="border" variant="primary" />
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
