import React from 'react'
import './style.css'

export default function index({ handleFilterDate, filterDate }) {

    return (
        <>
            <div className='main-inner-breadcrum-date'>
                <input type={'date'} value={filterDate} onChange={(e) => handleFilterDate(e)} />
            </div>
        </>
    )
}
