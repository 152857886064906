import * as Actions from '../actions/supplier/shipOrder';
export const SUPPLIER_SHIP_ORDER_SUCCESS = "SUPPLIER_SHIP_ORDER_ORDER_SUCCESS";
export const SUPPLIER_SHIP_ORDER_FAILURE = "SUPPLIER_SHIP_ORDER_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SUPPLIER_SHIP_ORDER_SUCCESS:
            return {
                ...state,
                success: true,
                error: 'success',
                message: action.message,
            };
        case Actions.SUPPLIER_SHIP_ORDER_FAILURE:

            return {
                ...state,
                success: false,
                error: 'error',
                message: action.message
            };
        default:
            return state;
    }
};

export default reducer;
