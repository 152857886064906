import * as Actions from '../actions/getShops';
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS";
export const GET_SHOPS_FAILURE = "GET_SHOPS_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_SHOPS_SUCCESS:
            return {
                ...state,
                success: true,
                error: 'success',
                message: action.message,
            };
        case Actions.GET_SHOPS_FAILURE:
            return {
                ...state,
                success: false,
                error: 'error',
                message: action.message
            };
        default:
            return state;
    }
};

export default reducer;
