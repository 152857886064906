import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import  { Redirect } from 'react-router-dom'
import './style.css'

export default function Header({ openSidebar, setOpenSidebar }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
    );
    const [notification, setNotification] = useState(false)
    const [notificationData, setNotificationData] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const wrapperRef = useRef(null);
    useEffect(() => {
      
      let url = `${process.env.REACT_APP_API_URL}api/notifications`;
      fetch(url, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((resp) => {
          setNotificationData(resp)
        });
    }, [window.location.href])
    function useOutsideAlerter(ref) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setNotification(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
  useOutsideAlerter(wrapperRef);

  
  const handleClick = (link, id) => {
    let url = `${process.env.REACT_APP_API_URL}api/notification/${id}`;
      fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((resp) => {
          setNotification(false)
          setRedirect(link)
          // setNotificationData(resp)
        });
  }
  return (
    <>
      
      {redirect && <Redirect to={redirect}  />}
      {/* <div className='row mx-0'> */}
        <div className='col-lg-3'>
          <div className='main-inner-header'>
            <div className='main-inner-heading'>
                <div className={`${openSidebar && 'show' } bars-icon`} onClick={() => setOpenSidebar(true)}>
                  <img src="/images/menu-white.png"></img>
                </div>
              </div>
              <div className='main-innr-right-log'>
              <h4>Welcome, {user.name}</h4>
              <svg onClick={() => setNotification(!notification)} width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.1846 22.7815C11.7457 23.4066 12.4662 23.75 13.2137 23.75H13.2147C13.9655 23.75 14.6892 23.4066 15.2514 22.7804C15.5526 22.4478 16.0661 22.4208 16.3987 22.7208C16.7323 23.0209 16.7594 23.5355 16.4593 23.8681C15.584 24.8398 14.4324 25.375 13.2147 25.375H13.2126C11.9982 25.3739 10.8487 24.8388 9.97666 23.867C9.67658 23.5344 9.70366 23.0198 10.0373 22.7208C10.371 22.4197 10.8845 22.4468 11.1846 22.7815ZM13.2676 2.08334C18.083 2.08334 21.3179 5.83384 21.3179 9.33626C21.3179 11.1378 21.7761 11.9016 22.2625 12.7119C22.7435 13.5114 23.2884 14.4193 23.2884 16.1353C22.9104 20.5195 18.3333 20.877 13.2676 20.877C8.20194 20.877 3.62378 20.5195 3.25001 16.2046C3.24678 14.4193 3.7917 13.5114 4.2727 12.7119L4.4425 12.4261C4.86059 11.7075 5.21736 10.9259 5.21736 9.33626C5.21736 5.83384 8.45219 2.08334 13.2676 2.08334ZM13.2676 3.70834C9.48136 3.70834 6.84236 6.67451 6.84236 9.33626C6.84236 11.5885 6.21728 12.6296 5.66478 13.5483C5.2217 14.286 4.87178 14.8688 4.87178 16.1353C5.0527 18.1784 6.40145 19.252 13.2676 19.252C20.0959 19.252 21.4869 18.1308 21.6667 16.0648C21.6634 14.8688 21.3135 14.286 20.8704 13.5483C20.3179 12.6296 19.6929 11.5885 19.6929 9.33626C19.6929 6.67451 17.0539 3.70834 13.2676 3.70834Z" fill="#fff" />
                <circle cx="18.5" cy="4.5" r="3.5" fill="#009EF7" stroke="white" strokeWidth="2" />
              </svg>
              <div  ref={wrapperRef} className={`notification ${notification && 'notificationBlock'}`}>
                <div className="inner-noti">
                  {notificationData ?
                    notificationData.length > 0 ?
                      <>
                        {notificationData.map(itm => {
                          return (
                            <div className="dropdown-item" onClick={() => handleClick(itm.link, itm.id)}>
                              <div className="noti">
                                <p className="notiMsg" >{itm.message}</p>
                                <span>{itm.time_ago}</span>
                              </div>
                            </div>
                          )
                        })}
                        <div className='col-12 text-center'>
                          <div className='view-all-tabl-btn'>
                            <Link to="/notifications">View All</Link>
                          </div>
                        </div>
                      </>
                    :
                      <div className="text-center mt-2">
                        <span className="noNotificationText"><b>No New Notification</b></span>
                      </div>
                    :
                    <div className='text-center'>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  }
                </div>
                
              </div> 
              <img src={toAbsoluteUrl('/images/ic-user.png')}></img>
              </div>
        
          </div>
        </div>
      {/* </div> */}
    </>
  )
}
