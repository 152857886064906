import axios from "axios";
export const GET_CRON_SUCCESS = "GET_CRON_SUCCESS";
export const GET_CRON_FAILURE = "GET_CRON_FAILURE";


// calling api to get cron logs
export const getCronLogs = ( dispatch, userRole) => {
    axios.get(process.env.REACT_APP_API_URL + `api/cronLogs/${userRole}`, {
        headers: {
            Authorization: 'Basic ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            console.log('response.data :::', response.data)
            if (!response.data.success) {
                dispatch({
                    type: GET_CRON_FAILURE,
                    data: response.data
                });
            }
            else {
                dispatch({
                    type: GET_CRON_SUCCESS,
                    data: response.data,
                });
            }

        }).catch(error => {
            console.log("error", error)
        });
};

