import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';

class StoresPage extends Component {
  state = {
    products: []
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + 'api/demanded-products?shop='+this.props.match.params.shop)
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ shops: booksList.data });
      });
  }

  render() {
    let columns = [
      // { title: "ID", data: "id" },
      {
        title: 'Image',
        data: 'image.src',
        className: "center",
        render: function (data, type, row) {
          if(data == null)
            return ''
            else
          return `<img width="40" src="${data}" />`;
          
        }
      },
      { title: "Product Id", data: "id" },
      { title: "Name", data: "title" },
      {
        title: 'Variants',
        data: 'variants',
        className: "center",
        render: function (data, type, row) {
          let html = ``;
          data.forEach(itm => {
            html += `<div><button class="btn m-1 btn-default text-dark">${itm.title}</button></div>`;
          })
          return html;
          
        }
      },
      {
        title: 'Quantity',
        data: 'variants',
        className: "center",
        render: function (data, type, row) {
          let html = ``;
          data.forEach(itm => {
            html += `<div><button class="btn m-1 btn-default text-dark">${itm.inventory_quantity}</button></div>`;
          })
          return html;
          
        }
      },
      {
        title: 'Price',
        data: 'variants',
        className: "center",
        render: function (data, type, row) {
          let html = ``;
          data.forEach(itm => {
            html += `<div><button class="btn m-1 btn-default text-dark">${itm.price}</button></div>`;
          })
          return html;
          
        }
      }
    ];
    if (!$.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable({
        data: this.state.shops,
        columns,
        ordering: true,
      });
    }
    const table = {
      width: "100%",
    };

    return (
      <div>
        <table id="myTable" responsive="true" hover="true" className="project-table table table-bordered" style={table}>

        </table>
      </div>
    )
  }

}

export default StoresPage;