import axios from "axios";
export const SUPPLIER_SHIP_ORDER_SUCCESS = "SUPPLIER_SHIP_ORDER_ORDER_SUCCESS";
export const SUPPLIER_SHIP_ORDER_FAILURE = "SUPPLIER_SHIP_ORDER_FAILURE";


// calling api to login
export const shipOrder = (id, dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/shippo/create', { orderId: id }, {
        headers: {
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/xml',
            'Authorization': "Bearer " + localStorage.getItem('token')
        },
    })
        .then(response => {
            if (response.data.status == false) {
                dispatch({
                    type: SUPPLIER_SHIP_ORDER_FAILURE,
                    message: "No Recordes"
                });
            }
            else {
                dispatch({
                    type: SUPPLIER_SHIP_ORDER_SUCCESS,
                    message: response.data.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

