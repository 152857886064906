import React, { Component } from "react";
import { connect } from 'react-redux';
import { getRunningCrons } from '../../../redux/actions/runningCrons'
import moment from "moment";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { getGiftCard } from '../../../redux/actions/giftcards/getAll';
import './index.css'

class RunningCrons extends Component {
    state = {
        cards: [],
        gotResp: false
    }
    componentDidMount() {
        const { auth } = this.props
        const { user } = auth
        console.log('user. ::::', user)
        console.log('user.role ::::', user.role)
        this.props.getRunningCrons(user.role);
        // this.props.getGiftCard();
    }
    componentDidUpdate(prevProps) {
        const { runningCron } = this.props

        if (prevProps.runningCron !== runningCron) {
            if (runningCron.success) {
                this.setState({ cards: runningCron.data })
            }
            this.setState({ gotResp: true })
        }
    }
    render() {
        const { runningCron, runningCronError, runningCronSuccess } = this.props
        console.log("this.state.cards", this.state.cards)

        const tableStyle = {
            width: "100%",
        };
        let columns = [
            // { title: "ID", data: "id" },
            { title: "Name", data: "cron_name" },
            { title: "Status", data: "status" },
            { title: "Start", data: "start" },
            { title: "End", data: "end" },
            { title: "No. of order fetch/placed", data: "no_of_order" },
            // {
            //     data: null,
            //     className: "center",
            //     render: function (data, type, row) {
            //         return `<a href="/running-cron/` + data.id + `" class=" btn btn-primary btn-sm link" style="color:white">View</a>
            //         <a onClick="" class=" btn btn-danger btn-sm link" style="color:white">Disable</a>`;
            //     }
            // }
        ];
        if (!$.fn.DataTable.isDataTable('#myTable') && this.state.gotResp) {
            $('#myTable').DataTable({
                data: this.state.cards,
                columns,
                ordering: true,
                order: [[2, 'desc']],
                createdRow: function (row, data, dataIndex) {
                    //SS
                    if (data['status'] == 'Completed') {
                      $(row).find('td:nth-child(2)').addClass('successTableRow');
                    }
                    else{
                      $(row).find('td:nth-child(2)').addClass('warningTableRow');
                    }
                  }
            });
        }
        return (
            <div>
                <div className="jumbotron jumbotron-fluid bg-warning">
                    <div className="container">
                        <div className="cron-heading">
                            <div className="danger-icon">
                                <svg width="18px" height="18px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512;"}} xmlSpace="preserve">
                                    <g> <g> <path d="M505.403,406.394L295.389,58.102c-8.274-13.721-23.367-22.245-39.39-22.245c-16.023,0-31.116,8.524-39.391,22.246 L6.595,406.394c-8.551,14.182-8.804,31.95-0.661,46.37c8.145,14.42,23.491,23.378,40.051,23.378h420.028 c16.56,0,31.907-8.958,40.052-23.379C514.208,438.342,513.955,420.574,505.403,406.394z M477.039,436.372 c-2.242,3.969-6.467,6.436-11.026,6.436H45.985c-4.559,0-8.784-2.466-11.025-6.435c-2.242-3.97-2.172-8.862,0.181-12.765 L245.156,75.316c2.278-3.777,6.433-6.124,10.844-6.124c4.41,0,8.565,2.347,10.843,6.124l210.013,348.292 C479.211,427.512,479.281,432.403,477.039,436.372z"/> </g> </g> <g> <g> <path d="M256.154,173.005c-12.68,0-22.576,6.804-22.576,18.866c0,36.802,4.329,89.686,4.329,126.489 c0.001,9.587,8.352,13.607,18.248,13.607c7.422,0,17.937-4.02,17.937-13.607c0-36.802,4.329-89.686,4.329-126.489 C278.421,179.81,268.216,173.005,256.154,173.005z"/> </g> </g> <g> <g> <path d="M256.465,353.306c-13.607,0-23.814,10.824-23.814,23.814c0,12.68,10.206,23.814,23.814,23.814 c12.68,0,23.505-11.134,23.505-23.814C279.97,364.13,269.144,353.306,256.465,353.306z"/> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                                </svg>
                                &nbsp;
                                &nbsp;
                            </div>
                            <h3 className="">Daily Cron</h3>
                        </div>
                        <p className="lead">Fetch order cron job run : Every half an hour </p>
                        <p className="lead">Place order cron job run : Every Six hours </p>
                        <p className="lead">Update order status cron job run : Every hour </p>
                    </div>
                </div>
                <table id="myTable"  responsive="true" hover="true" className="project-table table table-striped table-bordered" style={tableStyle}></table>
            </div>
        )
    }
}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        auth: state.auth,
        
        runningCron: state.getRunningCrons.runningCron,
        runningCronSuccess: state.getRunningCrons.runningCronSuccess,
        runningCronError: state.getRunningCrons.runningCronError
        // giftCards: state.getGiftCards

    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getRunningCrons: (body) => getRunningCrons(dispatch, body),
    getGiftCard: () => getGiftCard(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(RunningCrons);

