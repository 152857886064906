import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getShops } from "../../../redux/actions/getShops";
import CreateStoreModal from './CreateStoreModal';

class Stores extends Component {
  state = {
    shops: [],
    isShowing: false
  }

  componentDidMount() {
    this.props.getShops();

  }

  componentDidUpdate(prevProps) {
    if (this.props.shops !== prevProps.shops) {
      if (this.props.shops.success) {
        console.log('shops', this.props.shops.message.data)
        this.setState({ shops: this.props.shops.message.data })
      }
    }
  }
  openModalHandler = () => {
    this.setState({
      isShowing: true,
    });
  }
  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
    this.props.getShops();
  }
  render() {

    const table = {
      width: "100%",
    };

    return (
      <div>
        <div className="my-3 text-right">
          <button className="btn btn-lg btn-primary" onClick={() => this.openModalHandler()}>Add New Store</button>
        </div>
        <div className="container bg-white">
          <div className="row">
            <div className="col-12">

              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th>Shop Name</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.shops ?
                      this.state.shops.map((item, index) =>
                      (
                        <tr key={index} >
                          <td>{index + 1}</td>
                          <td>{item.shop_name}</td>
                          <td><Link to={'/store/view/' + item.id} className="btn btn-warning"><i className="fa fa-eye"></i> View</Link></td>

                        </tr>
                      )
                      ) :
                      <tr><td colSpan="3"><center> No Records Found</center></td></tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <CreateStoreModal
          className="modal"
          show={this.state.isShowing}
          close={this.closeModalHandler}
        />
      </div>
    )
  }

}

const mapStateToProps = state => (
  {
    shops: state.GetShops,
  }
);
const mapDispatchToProps = dispatch => ({
  getShops: () => getShops(dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Stores);