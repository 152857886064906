import axios from "axios";
export const SUPPLIER_SETTING_SUCCESS = "SUPPLIER_SETTING_SUCCESS";
export const SUPPLIER_SETTING_FAILURE = "SUPPLIER_SETTING_FAILURE";


// calling api to login
export const supplSettings = (dispatch) => {
    var user = JSON.parse(localStorage.getItem('user'));
    axios.get(process.env.REACT_APP_API_URL + 'api/supplier/settings', {
        headers: {
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/xml',
            'Authorization': "Bearer " + localStorage.getItem('token')
        },
    })
        .then(response => {
            if (response.data.data.length == 0) {
                dispatch({
                    type: SUPPLIER_SETTING_FAILURE,
                    message: "No Recordes"
                });
            }
            else {
                dispatch({
                    type: SUPPLIER_SETTING_SUCCESS,
                    message: response.data.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

