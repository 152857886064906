import React, { Component } from "react";
import { connect } from 'react-redux';
//import $ from 'jquery';
import { saveDimensions } from "../../../redux/actions/supplier/saveDimensions";
import DataTable from 'datatables.net';

class supplierOrderDetails extends Component {

    state = {
        order: [],
        billing: [],
        shipping: [],
        items: [],
        customer: [],
        artfiles: [],
        orderresponse: [],
        ssOrderDetail: {},
        shippo: {},
        editing: {},
        editingVal: {}
    }

    componentDidMount() {
        const param = {

            shop: this.props.match.params.shop,
            token: this.props.match.params.token,
            orderid: this.props.match.params.id
        }

        fetch(process.env.REACT_APP_API_URL + 'api/orderDetails', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'X-Shopify-Access-Token': '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'
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then(ordersDetails => {
                // Get the list of the order products
                var product = ordersDetails.order.line_items;
                var lines = [];
                product.forEach((product) => {
                    const inputs = {
                        shop: this.props.match.params.shop,
                        token: this.props.match.params.token,
                        productid: product.product_id,
                        orderId: this.props.match.params.id
                    }

                    const item = {
                        "identifier": product.product_id,
                        "qty": 1
                    }

                    lines.push(item);



                });

                this.setState({ order: ordersDetails.order, billing: ordersDetails.order.billing_address, shipping: ordersDetails.order.shipping_address, customer: ordersDetails.order.customer });



            });
        fetch(process.env.REACT_APP_API_URL + 'api/order/detail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then(orderresponse => {
                console.log("orderresponse", orderresponse)
                let ssOrderDetail = {
                    id: orderresponse[0].ss_orderid,
                    status: orderresponse[0].ss_order_status,
                    tracking_number: orderresponse[0].tracking_number,
                    tracking_status: orderresponse[0].tracking_status,
                    amount: orderresponse[0].amount,
                    currency: orderresponse[0].currency,
                    shipment_objectId: orderresponse[0].shipment_objectId,
                    tracking_status: orderresponse[0].tracking_status,
                    tracking_status_details: orderresponse[0].tracking_status_details,
                    tracking_url_provider: orderresponse[0].tracking_url_provider,
                    label_url: orderresponse[0].label_url,
                    ssError: orderresponse[0].ss_error,
                }
                this.setState({ ssOrderDetail: ssOrderDetail, items: JSON.parse(orderresponse[0].line_items) });
            });
        fetch(process.env.REACT_APP_API_URL + 'api/artfile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param)
        }).then((response) => response.json())
            .then(artfiles => {

                this.setState({ artfiles: artfiles })
            });
    }
    handleEdit = (id, hi, wi, le) => {
        var editing = this.state.editing;
        editing[id] = true;
        var editingVal = this.state.editingVal;
        if (editingVal[id] == undefined) {
            editingVal[id] = {}
        };
        editingVal[id]['height'] = hi || 0;
        editingVal[id]['width'] = wi || 0
        editingVal[id]['length'] = le || 0
        this.setState({ editing, editingVal })
    }
    handleChange = e => {
        let editingVal = this.state.editingVal;
        if (editingVal[e.target.getAttribute('product_id')] == undefined) {
            editingVal[e.target.getAttribute('product_id')] = {};
        }
        editingVal[e.target.getAttribute('product_id')][e.target.name] = e.target.value;
        this.setState({
            editingVal
        });
    }
    handleSave = (product_id, order_id) => {
        var editing = this.state.editing;
        var values = this.state.editingVal[product_id];
        this.props.saveDimensions(product_id, order_id, values.height, values.width, values.length)
        editing[product_id] = false;
        const param = {

            shop: this.props.match.params.shop,
            token: this.props.match.params.token,
            orderid: this.props.match.params.id
        }
        var _this = this;
        setTimeout(function () {
            fetch(process.env.REACT_APP_API_URL + 'api/order/detail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param)
            })
                .then((response) => response.json())
                .then(orderresponse => {
                    console.log("orderresponse", orderresponse)
                    console.log("orderresponse[0].shipment_objectId",orderresponse[0].shipment_objectId)
                    let ssOrderDetail = {
                        id: orderresponse[0].ss_orderid,
                        status: orderresponse[0].ss_order_status,
                        tracking_number: orderresponse[0].tracking_number,
                        tracking_status: orderresponse[0].tracking_status,
                        amount: orderresponse[0].amount,
                        currency: orderresponse[0].currency,
                        shipment_objectId: orderresponse[0].shipment_objectId,
                        tracking_status: orderresponse[0].tracking_status,
                        tracking_status_details: orderresponse[0].tracking_status_details,
                        tracking_url_provider: orderresponse[0].tracking_url_provider,
                        label_url: orderresponse[0].label_url,
                        ssError: orderresponse[0].ss_error,
                    }
                    _this.setState({ ssOrderDetail: ssOrderDetail, items: JSON.parse(orderresponse[0].line_items) });
                });
        }, 500)
        this.setState({ editing })
    }
    render() {
        var data = this.state.order;
        var billing = this.state.billing;
        var shipping = this.state.shipping;
        var customer = this.state.customer;
        var artfiles = this.state.artfiles;
        var ssOrderDetail = this.state.ssOrderDetail;
        var editing = this.state.editing
        const orderid = {
            margin: '10px 0 20px 0',
            display: 'inline-block'
        };
        return (
            <div className="orderdetails-wrapper">
                <h3>Order Details</h3>
                <div className="order-meta">
                    <span style={orderid}><b>Order ID: {data.id}</b></span>
                    <div className="row">
                        <div className="col-md-3"><b>Order number:</b></div>
                        <div className="col-md-3">#{data.order_number}</div>
                        <div className="col-md-3"><b>Checkout ID:</b></div>
                        <div className="col-md-3">{data.checkout_id}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><b>Customer Email:</b></div>
                        <div className="col-md-3">{data.contact_email}</div>
                        <div className="col-md-3"><b>Created at:</b></div>
                        <div className="col-md-3">{data.created_at}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><b>Currency:</b></div>
                        <div className="col-md-3">{data.currency}</div>
                        <div className="col-md-3"><b>Total Price:</b></div>
                        <div className="col-md-3">{data.current_total_price}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><b>Payment Status:</b></div>
                        <div className="col-md-3">{data.financial_status}</div>
                        <div className="col-md-3"><b>Payment Gateway:</b></div>
                        <div className="col-md-3">{data.gateway}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"><b>Billing Address:</b></div>
                        <div className="col-md-3">{billing.first_name} {billing.last_name}, {billing.address1}, {billing.city}, {billing.province}, {billing.country} </div>
                        <div className="col-md-3"><b>Default Address:</b></div>
                        <div className="col-md-3">{shipping.first_name} {shipping.last_name}, {shipping.address1}, {shipping.city}, {shipping.province}, {shipping.country} </div>
                    </div>
                </div>

                <h3 style={orderid}>Customer Details</h3>
                <div className="row">
                    <div className="col-md-3"><b>Name:</b></div>
                    <div className="col-md-3">{customer.first_name} {customer.last_name}</div>
                    <div className="col-md-3"><b>Email:</b></div>
                    <div className="col-md-3">{customer.email}</div>
                </div>
                <div className="row">
                    <div className="col-md-3"><b>Phone:</b></div>
                    <div className="col-md-3">{customer.phone}</div>
                </div>

                <h3 style={orderid}>Order Items</h3>

                {this.state.items.map((items, i) => (
                    <div key={items} >
                        {i > 0 ? <br /> : ""}
                        <div className="row" key={items.id}>
                            <div className="col-md-10"><h5>{"Product " + (i + 1)} </h5></div>
                            <div className="col-md-2">
                                {editing[items.product_id] ?
                                    <button onClick={() => this.handleSave(items.product_id, data.id)} className="btn btn-sm btn-info" /* onClick={() => this.handleEdit(itm.id)} */>Save</button>
                                    : <button onClick={() => this.handleEdit(items.product_id, items.height, items.width, items.length)} className="btn btn-sm btn-warning" /* onClick={() => this.handleEdit(itm.id)} */>Edit Dimensions</button>
                                }
                            </div>
                            <div className="col-md-3"><b>Product ID:</b></div>
                            <div className="col-md-3">{items.product_id}</div>
                            <div className="col-md-3"><b>Product Name:</b></div>
                            <div className="col-md-3">{items.title}</div>
                            <div className="col-md-3"><b>Quantity:</b></div>
                            <div className="col-md-3">{items.quantity}</div>
                            <div className="col-md-3"><b>SKU:</b></div>
                            <div className="col-md-3">{items.sku}</div>
                            <div className="col-md-3"><b>Price:</b></div>
                            <div className="col-md-3">{items.price}</div>
                            <div className="col-md-3"><b>Discount:</b></div>
                            <div className="col-md-3">{items.total_discount}</div>
                            <div className="col-md-3"><b>Vendor:</b></div>
                            <div className="col-md-3">{items.vendor}</div>

                            <div className="col-md-3"><b>Height:</b></div>
                            <div className="col-md-3">{editing[items.product_id] ? <input product_id={items.product_id} onChange={this.handleChange} step=".01" type="number" name={"height"} value={this.state.editingVal[items.product_id].height || ""} /> : items.height !== undefined ? items.height : ""} inches</div>
                            <div className="col-md-3"><b>Width:</b></div>
                            <div className="col-md-3">{editing[items.product_id] ? <input product_id={items.product_id} onChange={this.handleChange} step=".01" type="number" name={"width"} value={this.state.editingVal[items.product_id].width || ""} /> : items.width !== undefined ? items.width : ""} inches</div>
                            <div className="col-md-3"><b>Length:</b></div>
                            <div className="col-md-3">{editing[items.product_id] ? <input product_id={items.product_id} onChange={this.handleChange} step=".01" type="number" name={"length"} value={this.state.editingVal[items.product_id].length || ""} /> : items.length !== undefined ? items.length : ""} inches</div>

                        </div>
                    </div>

                ))}

                <h3 style={orderid}>Art files</h3>
                {artfiles.map((artfile, i) => (

                    <div className="row" key={artfile.id}>
                        <div className="col-md-3"><b>{"Art File " + (i + 1)}:</b></div>
                        <div className="col-md-3"><a href={artfile.artfile} target="_blank">{artfile.artfile}</a></div>
                    </div>


                ))}
                <br /><br />
                <h3>SS Order Details</h3>
                {ssOrderDetail.id ?
                    <div className="row">
                        <div className="col-md-3"><b>Order ID</b></div>
                        <div className="col-md-3">{ssOrderDetail.id}</div>
                        <div className="col-md-3"><b>Order Status</b></div>
                        <div className="col-md-3">{ssOrderDetail.status}</div>
                    </div> : <div className="col-md-12"><div>Order is not placed yet.</div> {ssOrderDetail.ssError ? ssOrderDetail.ssError : ""} </div>}
                <br /><br />
                <h3>Shipment Details (Shippo)</h3>
                {(ssOrderDetail.tracking_number != undefined && ssOrderDetail.tracking_number != null) ?
                    <div>
                        <div className="row">
                            <div className="col-md-3"><b>Tracking Number</b></div>
                            <div className="col-md-3">{ssOrderDetail.tracking_number || ""}</div>
                            <div className="col-md-3"><b>Shipment ID:</b></div>
                            <div className="col-md-3">{ssOrderDetail.shipment_objectId || ""}</div>
                            <div className="col-md-3"><b>Tracking Status</b></div>
                            <div className="col-md-3">{ssOrderDetail.tracking_status || ""}</div>
                            <div className="col-md-3"><b>Tracking Status Details:</b></div>
                            <div className="col-md-3">{ssOrderDetail.tracking_status_details || ""}</div>
                            <div className="col-md-3"><b>Shipping Amount</b></div>
                            <div className="col-md-3">{ssOrderDetail.currency || ""} {ssOrderDetail.amount || ""}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3"><b>Tracking URL:</b></div>
                            <div className="col-md-3" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} ><a href={ssOrderDetail.tracking_url_provider || ""} target="_blank" >{ssOrderDetail.tracking_url_provider || ""}</a></div>
                            <div className="col-md-3"><b>Label URL:</b></div>
                            <div className="col-md-3" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} ><a href={ssOrderDetail.label_url || ""} target="_blank">{ssOrderDetail.label_url || ""}</a></div>
                        </div>
                    </div> : <div className="col-md-3">Order is not placed yet.</div>}

            </div >



        )
    }

}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        res: state.SaveDimensions,
        auth: state.auth
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    saveDimensions: (product_id, order_id, height, width, length) => saveDimensions(product_id, order_id, height, width, length, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(supplierOrderDetails);