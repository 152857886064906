import React, { Component } from "react";
import { connect } from 'react-redux';
//import $ from 'jquery';
import DataTable from 'datatables.net';
import { shippingDetails } from "../../../redux/actions/supplier/shippingDetails";
import { data } from "jquery";

class SupplierShippingDetails extends Component {

    state = {
        order: {},
    }

    componentDidMount() {
        this.props.shippingDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.res !== prevProps.res) {
            if (this.props.res.success) {
                console.log("message", this.props.res.message)
                this.setState({ order: this.props.res.message })
            }
        }
    }
    render() {
        var data = this.state.order;
        return (
            <div>
                <div className="container">
                    <div className="card">
                        <div className="card-header bg-light py-4">
                            <h4 className="mb-0">Order #{data.order_id}</h4>
                        </div>
                        <div className="card-body"  >
                            <div className="row mb-5">
                                <div className="col-12 bg-secondary py-2 px-5">
                                    <h5>Shipping Details</h5>
                                    <div>
                                        Shipment ID:  <b>{data.shipment_objectId}</b>
                                    </div>
                                    <div>
                                        Tracking Number: <b>{data.tracking_number}</b>
                                    </div>
                                    <div>
                                        Tracking Status: <b>{data.tracking_status}</b>
                                    </div>
                                    <div>
                                        Tracking Status Details: <b>{data.tracking_status_details}</b>
                                    </div>
                                    <div>
                                        Tracking URL: <b><a href={data.tracking_url_provider || ""} target="_blank" >{data.tracking_url_provider || ""}</a></b>
                                    </div>
                                    <div>
                                        Label URL: <b><a href={data.label_url || ""} target="_blank">{data.label_url || ""}</a></b>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        )
    }

}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        res: state.ShippingDetails,
        auth: state.auth
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    shippingDetails: (id) => shippingDetails(id, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierShippingDetails);