import axios from "axios";
export const GET_CHOSEN_SHOP_SUCCESS = "GET_CHOSEN_SHOP_SUCCESS";
export const GET_CHOSEN_SHOP_FAILURE = "GET_CHOSEN_SHOP_FAILURE";


// calling api to login
export const getChosenShop = (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/chosen-shop')
        .then(response => {
            if (response.isSuccess === false) {
                dispatch({
                    type: GET_CHOSEN_SHOP_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: GET_CHOSEN_SHOP_SUCCESS,
                    message: response.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

