import * as Actions from '../../actions/giftcards/create';
export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CREATE_GIFT_FAILURE = "CREATE_GIFT_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case Actions.CREATE_GIFT_SUCCESS:
          return {
            ...state,
            success: true,
            error: 'success',
            message:action.message,
          };
      case Actions.CREATE_GIFT_FAILURE:
            
          return {
              ...state,
              success: false,
              error: 'error',
              message:action.message
            };
      default:
        return state;
    }
  };
  
  export default reducer;
  