import React, { useState } from 'react'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link, useLocation, useParams } from 'react-router-dom';
import Header from '../../components/headerTopbar';
import './style.css'

export default function Sidebar({ openSidebar, setOpenSidebar, stores, userRole }) {
  const location = useLocation();
  const [dropDownShow, setDropDownShow] = useState(false)
  const [dropDownOrderShow, setDropDownOrderShow] = useState(false)
  const data = JSON.parse(stores)

  const handleStoreName = (value) => {
    return value.split('.myshopify.com')[0]
  };

  const onClickPrevent = (e) => {
    e.preventDefault()
    console.log('onclick..')
  }

  return (
    <>
      <div className='row mx-0'>
        <div className='col-lg-9 newTopbar'>
          <div className='only-for-mob'>
            <div className={`main-inner-sidebar ${openSidebar ? 'show' : 'hide'}`}>
              
              <div className='main-inner-navbar'>
                <ul>
                  <li className='logoImg'>
                    <Link to='/latest-dashboard'>
                      <img src={toAbsoluteUrl('/media/image1.svg')}></img>
                    </Link>
                  </li>
                  <li onClick={() => {setDropDownOrderShow(false);setDropDownShow(false);}} className={location.pathname == '/latest-dashboard' ? 'active' : ''}>
                    <Link to='/latest-dashboard'>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99998 0.664312C8.25149 0.662394 6.53895 1.13978 5.0646 2.0401C3.59026 2.94042 2.4157 4.22607 1.67965 5.74519C0.943598 7.26431 0.676797 8.95347 0.910756 10.6132C1.14472 12.2728 1.86966 13.8337 2.99998 15.1115L3.16665 15.2977H16.8333L17 15.1115C18.1303 13.8337 18.8552 12.2728 19.0892 10.6132C19.3232 8.95347 19.0564 7.26431 18.3203 5.74519C17.5843 4.22607 16.4097 2.94042 14.9354 2.0401C13.461 1.13978 11.7485 0.662394 9.99998 0.664312ZM16.3 14.2335H3.69998C2.70314 13.0387 2.10151 11.586 1.9722 10.0616H3.88887V8.99738H1.9722C2.06524 7.30774 2.73913 5.69502 3.88887 4.41047L5.24998 5.71417L6.03331 4.96388L4.68331 3.65485C6.00735 2.53395 7.68138 1.86414 9.44443 1.74985V3.61228H10.5555V1.75517C12.503 1.89044 14.3325 2.70002 15.7025 4.03272C17.0724 5.36543 17.889 7.13015 18 8.99738H16.0666V10.0616H18.0278C17.8985 11.586 17.2968 13.0387 16.3 14.2335Z" stroke="#060640" strokeWidth="0.5"  fill={location.pathname == '/latest-dashboard' ? '#060640' : '#D0D0FF' } />
                      </svg>
                      Dashboard
                    </Link>
                  </li>

                  { userRole == 6  ||  userRole == 4
                    ? ""
                    :
                      <li onClick={() => {setDropDownShow(!dropDownShow);setDropDownOrderShow(false);}} className={`${location.pathname == '/stores/all' ? 'active' : ''} storedrop `} >
                        <a href="" onClick={onClickPrevent}>
                          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5523 1.60855C16.389 1.34912 16.1586 1.13439 15.8834 0.985058C15.6082 0.835728 15.2974 0.756843 14.9811 0.756012H5.01881C4.37898 0.756012 3.77673 1.08263 3.44765 1.60855L0.964397 5.57187C0.878464 5.70832 0.833134 5.86469 0.833314 6.02404C0.836974 6.87151 1.16285 7.68866 1.74998 8.32266V15.6821C1.74998 16.6505 2.57223 17.4381 3.58331 17.4381H16.4166C17.4277 17.4381 18.25 16.6505 18.25 15.6821V8.32266C18.8371 7.68866 19.163 6.87151 19.1666 6.02404C19.1668 5.86469 19.1215 5.70832 19.0356 5.57187L16.5523 1.60855ZM17.3186 6.24442C17.5 6.66258 17.0463 7.05802 16.7116 7.34063C16.3769 7.62324 15.9462 7.77945 15.5 7.78005C14.4889 7.78005 13.6666 6.99248 13.6666 6.02404C13.6666 5.96434 13.6437 5.91166 13.6309 5.85546L13.6492 5.85195L12.9516 2.51202H14.9811L17.3186 6.24442ZM8.9174 2.51202H11.0816L11.8269 6.08111C11.7948 7.02233 10.99 7.78005 9.99998 7.78005C9.00998 7.78005 8.20515 7.02233 8.17306 6.08111L8.9174 2.51202ZM5.01881 2.51202H7.04831L6.35165 5.85195L6.36998 5.85546C6.35623 5.91166 6.33331 5.96434 6.33331 6.02404C6.33331 6.99248 5.51106 7.78005 4.49998 7.78005C4.05379 7.77945 3.6231 7.62324 3.2884 7.34063C2.9537 7.05802 2.73789 6.66834 2.68131 6.24442L5.01881 2.51202ZM8.16665 15.6821V13.0481H11.8333V15.6821H8.16665ZM13.6666 15.6821V13.0481C13.6666 12.0796 12.8444 11.2921 11.8333 11.2921H8.16665C7.15556 11.2921 6.33331 12.0796 6.33331 13.0481V15.6821H3.58331V9.41138C3.87756 9.48426 4.18098 9.53606 4.49998 9.53606C5.02048 9.53632 5.53504 9.43017 6.00928 9.22471C6.48351 9.01924 6.9065 8.71919 7.24998 8.34461C7.9219 9.07511 8.9064 9.53606 9.99998 9.53606C11.0936 9.53606 12.0781 9.07511 12.75 8.34461C13.0935 8.71919 13.5164 9.01924 13.9907 9.22471C14.4649 9.43017 14.9795 9.53632 15.5 9.53606C15.819 9.53606 16.1224 9.48426 16.4166 9.41138V15.6821H13.6666Z" fill="#D0D0FF" stroke="#060640" strokeWidth="0.5" />
                          </svg>
                          <span className='storedrop' >Stores</span>
                        </a>
                        <div className={dropDownShow ? 'sub-menu show' : 'sub-menu hide-subnav'}>
                            <ul className='inner-list'>
                            <li className={location.pathname == '/stores/all' ? 'active-subnav' : ''}>
                                <Link to='/stores/all' >All Stores</Link>
                            </li>
                            {data && data.length > 0 ? 
                                data.map((itm, idx) => {
                                {/* <li className={location.pathname == `/stores-detail/${itm.store_name}` ? 'active-subnav' : ''} key={idx}><Link to={`/stores-detail/${itm.store_name}`} >{itm.store_name}</Link></li> */}
                                return <li key={idx} ><Link to={`/all-orders?store=${itm.store_name}`}>{handleStoreName(itm.store_name)}</Link></li>
                                })
                                :
                                null
                            }
                            {data && data !== null && data.length > 29 &&
                                <li>+ 30 Stores</li>
                            }
                            </ul>
                        </div>
                      </li>
                    }

                    { userRole == 6 
                    ? 
                    <li onClick={() => {setDropDownShow(false);setDropDownOrderShow(false);}} className={`${location.pathname == '/top-products' ? 'active' : ''}`} >
                      <Link to='/top-products' >
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.029 1.02757C9.32605 0.869565 9.66022 0.786652 10 0.786652C10.3398 0.786652 10.674 0.869565 10.971 1.02757L18.486 5.02553C18.6418 5.10851 18.7716 5.22988 18.862 5.37707C18.9524 5.52426 19 5.69191 19 5.86266V13.7503C18.9999 14.0919 18.9045 14.4273 18.7235 14.7217C18.5426 15.0161 18.2828 15.2588 17.971 15.4246L10.971 19.1506C10.674 19.3086 10.3398 19.3915 10 19.3915C9.66022 19.3915 9.32605 19.3086 9.029 19.1506L2.029 15.4246C1.71736 15.2588 1.45763 15.0163 1.27671 14.7221C1.0958 14.4279 1.00026 14.0927 1 13.7513V5.86266C0.999993 5.69191 1.04764 5.52426 1.13802 5.37707C1.22839 5.22988 1.3582 5.10851 1.514 5.02553L9.03 1.02757H9.029Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 10.0886V19.6668M1 5.29947L10 10.0886L1 5.29947ZM10 10.0886L19 5.29947L10 10.0886Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinejoin="round" />
                        <path d="M4 10.4028L7 12.0042M5.5 7.69403L14.5 2.90491L5.5 7.69403Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                        Top Products
                      </Link>
                    </li>
                    : ""
                    }

                    { userRole == 6 
                    ? 
                    <li onClick={() => {setDropDownShow(false);setDropDownOrderShow(false);}} className={`${location.pathname == '/inventory' ? 'active' : ''}`} >
                      <Link to='/inventory' >
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.5523 1.60855C16.389 1.34912 16.1586 1.13439 15.8834 0.985058C15.6082 0.835728 15.2974 0.756843 14.9811 0.756012H5.01881C4.37898 0.756012 3.77673 1.08263 3.44765 1.60855L0.964397 5.57187C0.878464 5.70832 0.833134 5.86469 0.833314 6.02404C0.836974 6.87151 1.16285 7.68866 1.74998 8.32266V15.6821C1.74998 16.6505 2.57223 17.4381 3.58331 17.4381H16.4166C17.4277 17.4381 18.25 16.6505 18.25 15.6821V8.32266C18.8371 7.68866 19.163 6.87151 19.1666 6.02404C19.1668 5.86469 19.1215 5.70832 19.0356 5.57187L16.5523 1.60855ZM17.3186 6.24442C17.5 6.66258 17.0463 7.05802 16.7116 7.34063C16.3769 7.62324 15.9462 7.77945 15.5 7.78005C14.4889 7.78005 13.6666 6.99248 13.6666 6.02404C13.6666 5.96434 13.6437 5.91166 13.6309 5.85546L13.6492 5.85195L12.9516 2.51202H14.9811L17.3186 6.24442ZM8.9174 2.51202H11.0816L11.8269 6.08111C11.7948 7.02233 10.99 7.78005 9.99998 7.78005C9.00998 7.78005 8.20515 7.02233 8.17306 6.08111L8.9174 2.51202ZM5.01881 2.51202H7.04831L6.35165 5.85195L6.36998 5.85546C6.35623 5.91166 6.33331 5.96434 6.33331 6.02404C6.33331 6.99248 5.51106 7.78005 4.49998 7.78005C4.05379 7.77945 3.6231 7.62324 3.2884 7.34063C2.9537 7.05802 2.73789 6.66834 2.68131 6.24442L5.01881 2.51202ZM8.16665 15.6821V13.0481H11.8333V15.6821H8.16665ZM13.6666 15.6821V13.0481C13.6666 12.0796 12.8444 11.2921 11.8333 11.2921H8.16665C7.15556 11.2921 6.33331 12.0796 6.33331 13.0481V15.6821H3.58331V9.41138C3.87756 9.48426 4.18098 9.53606 4.49998 9.53606C5.02048 9.53632 5.53504 9.43017 6.00928 9.22471C6.48351 9.01924 6.9065 8.71919 7.24998 8.34461C7.9219 9.07511 8.9064 9.53606 9.99998 9.53606C11.0936 9.53606 12.0781 9.07511 12.75 8.34461C13.0935 8.71919 13.5164 9.01924 13.9907 9.22471C14.4649 9.43017 14.9795 9.53632 15.5 9.53606C15.819 9.53606 16.1224 9.48426 16.4166 9.41138V15.6821H13.6666Z" fill="#D0D0FF" stroke="#060640" strokeWidth="0.5" />
                        </svg>
                        Inventory
                      </Link>
                    </li>
                    : ""
                    }

                  <li onClick={() => {setDropDownOrderShow(!dropDownOrderShow);setDropDownShow(false);}} className={`${location.pathname == '/all-orders' ? 'active' : ''} storedrop ${location.pathname == '/non-apparel-orders' ? 'active' : ''}`} >
                    <a href="" onClick={onClickPrevent}>
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.029 1.02757C9.32605 0.869565 9.66022 0.786652 10 0.786652C10.3398 0.786652 10.674 0.869565 10.971 1.02757L18.486 5.02553C18.6418 5.10851 18.7716 5.22988 18.862 5.37707C18.9524 5.52426 19 5.69191 19 5.86266V13.7503C18.9999 14.0919 18.9045 14.4273 18.7235 14.7217C18.5426 15.0161 18.2828 15.2588 17.971 15.4246L10.971 19.1506C10.674 19.3086 10.3398 19.3915 10 19.3915C9.66022 19.3915 9.32605 19.3086 9.029 19.1506L2.029 15.4246C1.71736 15.2588 1.45763 15.0163 1.27671 14.7221C1.0958 14.4279 1.00026 14.0927 1 13.7513V5.86266C0.999993 5.69191 1.04764 5.52426 1.13802 5.37707C1.22839 5.22988 1.3582 5.10851 1.514 5.02553L9.03 1.02757H9.029Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 10.0886V19.6668M1 5.29947L10 10.0886L1 5.29947ZM10 10.0886L19 5.29947L10 10.0886Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinejoin="round" />
                        <path d="M4 10.4028L7 12.0042M5.5 7.69403L14.5 2.90491L5.5 7.69403Z" stroke="#D0D0FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <span className='storedrop' >Orders</span>
                    </a>
                    <div className={dropDownOrderShow ? 'sub-menu show' : 'sub-menu hide-subnav'}>
                        <ul className='inner-list'>
                        <li className={location.pathname == '/all-orders' ? 'active-subnav' : ''}>
                            <Link to='/all-orders' >All Orders</Link>
                        </li>
                        <li className={location.pathname == '/non-apparel-orders' ? 'active-subnav' : ''}>
                            <Link to='/non-apparel-orders' >Non Apparel Orders</Link>
                        </li>
                        </ul>
                    </div>
                  </li>
                  
                  {/* <li onClick={() => {setDropDownOrderShow(false);setDropDownShow(false);}} className={location.pathname == '/latest-gift-cards' ? 'active' : ''}>
                    <Link to='/latest-gift-cards'>
                      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.625 5.10765H15.1656C15.4844 4.62724 15.6719 4.05704 15.6719 3.44643C15.6719 1.73806 14.2211 0.348465 12.4375 0.348465C11.4672 0.348465 10.593 0.761526 10 1.41255C9.40703 0.761526 8.53281 0.348465 7.5625 0.348465C5.77891 0.348465 4.32812 1.73806 4.32812 3.44643C4.32812 4.05704 4.51328 4.62724 4.83438 5.10765H1.375C0.960156 5.10765 0.625 5.42867 0.625 5.82602V10.3158C0.625 10.4146 0.709375 10.4954 0.8125 10.4954H1.75V18.2179C1.75 18.6152 2.08516 18.9362 2.5 18.9362H17.5C17.9148 18.9362 18.25 18.6152 18.25 18.2179V10.4954H19.1875C19.2906 10.4954 19.375 10.4146 19.375 10.3158V5.82602C19.375 5.42867 19.0398 5.10765 18.625 5.10765ZM10.7969 3.44643C10.7969 2.57989 11.5328 1.875 12.4375 1.875C13.3422 1.875 14.0781 2.57989 14.0781 3.44643C14.0781 4.31296 13.3422 5.01786 12.4375 5.01786H10.7969V3.44643ZM7.5625 1.875C8.46719 1.875 9.20312 2.57989 9.20312 3.44643V5.01786H7.5625C6.65781 5.01786 5.92188 4.31296 5.92188 3.44643C5.92188 2.57989 6.65781 1.875 7.5625 1.875ZM2.21875 8.96888V6.63418H9.20312V8.96888H2.21875ZM3.34375 10.4954H9.20312V17.4097H3.34375V10.4954ZM16.6562 17.4097H10.7969V10.4954H16.6562V17.4097ZM17.7812 8.96888H10.7969V6.63418H17.7812V8.96888Z" fill="#D0D0FF" />
                      </svg>
                      Manage Gift Cards
                    </Link>
                  </li> */}
                  <li onClick={() => {setDropDownOrderShow(false);setDropDownShow(false);}} className={location.pathname == '/help' ? 'active' : ''}>
                    <Link to='/help'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 4.78912C9.13088 4.79018 8.29767 5.12135 7.6831 5.70999C7.06853 6.29864 6.72278 7.09672 6.72168 7.92919H8.38835C8.38835 7.07752 9.11168 6.3855 10 6.3855C10.8883 6.3855 11.6117 7.07752 11.6117 7.92919C11.6117 8.4065 11.2108 8.75292 10.5983 9.22704C10.3983 9.37718 10.2061 9.53675 10.0225 9.70515C9.19085 10.5009 9.16668 11.3462 9.16668 11.4404V11.9728H10.8333L10.8325 11.4675C10.8333 11.4548 10.86 11.1594 11.2 10.8346C11.325 10.7149 11.4825 10.5951 11.6458 10.469C12.295 9.96536 13.2775 9.20469 13.2775 7.92919C13.2769 7.09672 12.9314 6.29853 12.3169 5.70981C11.7024 5.12109 10.8691 4.78997 10 4.78912ZM9.16668 12.771H10.8333V14.3674H9.16668V12.771Z" fill="#D0D0FF" />
                        <path d="M10 0.957825C5.0374 0.957825 1 4.82494 1 9.57824C1 14.3315 5.0374 18.1987 10 18.1987C14.9626 18.1987 19 14.3315 19 9.57824C19 4.82494 14.9626 0.957825 10 0.957825ZM10 16.4746C6.0301 16.4746 2.8 13.3807 2.8 9.57824C2.8 5.77577 6.0301 2.68191 10 2.68191C13.9699 2.68191 17.2 5.77577 17.2 9.57824C17.2 13.3807 13.9699 16.4746 10 16.4746Z" fill="#D0D0FF" />
                      </svg>
                      Help
                    </Link>
                  </li>
                  <li className={location.pathname == '/logout' ? 'active' : ''}>
                    <Link to='/logout'>
                      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.9531 12.8234H14.5801C14.4863 12.8234 14.3984 12.8627 14.3399 12.9319C14.2031 13.0909 14.0566 13.2443 13.9024 13.3902C13.2713 13.9953 12.5238 14.4775 11.7012 14.8101C10.8489 15.1549 9.93295 15.3318 9.00782 15.3302C8.07227 15.3302 7.16602 15.1543 6.31446 14.8101C5.49186 14.4775 4.74436 13.9953 4.11329 13.3902C3.48109 12.7872 2.97699 12.0725 2.62891 11.2856C2.26759 10.47 2.08594 9.60382 2.08594 8.70773C2.08594 7.81164 2.26954 6.94548 2.62891 6.12983C2.97657 5.34225 3.47657 4.63323 4.11329 4.02524C4.75001 3.41725 5.49024 2.93833 6.31446 2.60534C7.16602 2.26112 8.07227 2.08527 9.00782 2.08527C9.94337 2.08527 10.8496 2.25925 11.7012 2.60534C12.5254 2.93833 13.2656 3.41725 13.9024 4.02524C14.0566 4.17303 14.2012 4.32643 14.3399 4.48357C14.3984 4.55279 14.4883 4.59208 14.5801 4.59208H15.9531C16.0762 4.59208 16.1524 4.46112 16.084 4.36197C14.5859 2.13204 11.9649 0.65602 8.98633 0.663503C4.30665 0.674728 0.554695 4.31334 0.60157 8.79004C0.648445 13.1957 4.39454 16.7519 9.00782 16.7519C11.9785 16.7519 14.5879 15.2778 16.084 13.0535C16.1504 12.9543 16.0762 12.8234 15.9531 12.8234ZM17.6895 8.58987L14.918 6.49463C14.8145 6.41606 14.6641 6.48715 14.6641 6.61249V8.03426H8.53126C8.44532 8.03426 8.37501 8.1016 8.37501 8.18392V9.23154C8.37501 9.31385 8.44532 9.3812 8.53126 9.3812H14.6641V10.803C14.6641 10.9283 14.8164 10.9994 14.918 10.9208L17.6895 8.82558C17.7081 8.81158 17.7232 8.7937 17.7336 8.77328C17.744 8.75286 17.7494 8.73044 17.7494 8.70773C17.7494 8.68501 17.744 8.66259 17.7336 8.64217C17.7232 8.62176 17.7081 8.60387 17.6895 8.58987Z" fill="#D0D0FF" />
                      </svg>
                      Logout
                    </Link></li>
                </ul>
              </div>
              <div className='close-icon-sidebar' onClick={() => setOpenSidebar(false)}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3125 1.3125L12.6875 12.6875M12.6875 1.3125L1.3125 12.6875L12.6875 1.3125Z" stroke="#C5C5C5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}/>
      </div>
    </>
  )
}
