import axios from "axios";
export const CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS";
export const CREATE_MANAGER_FAILURE = "CREATE_MANAGER_FAILURE";


// calling api to login
export const addManager = (name, email, password, store, location, dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/create/manager', { name, email, password, store, location })
        .then(response => {
            console.log("response",response)
            if (response.data.error) {
                dispatch({
                    type: CREATE_MANAGER_FAILURE,
                    message: response.data.error
                });
            }
            else {
                dispatch({
                    type: CREATE_MANAGER_SUCCESS,
                    message: response.data,
                    token: response.token
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

