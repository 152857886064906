import * as Actions from '../actions/manager/orders';
export const MANAGER_ORDER_SUCCESS = "MANAGER_ORDER_SUCCESS";
export const MANAGER_ORDER_FAILURE = "MANAGER_ORDER_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case Actions.MANAGER_ORDER_SUCCESS:
          return {
            ...state,
            success: true,
            error: 'success',
            message:action.message,
          };
      case Actions.MANAGER_ORDER_FAILURE:
            
          return {
              ...state,
              success: false,
              error: 'error',
              message:action.message
            };
      default:
        return state;
    }
  };
  
  export default reducer;
  