import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import NewDashboard from "./pages/Dashboard/Dashboard";
import StoresPage from "./pages/StoresPage";
import AllNotifications from "./pages/AllNotifications";
import StoreOrders from "./pages/StoreOrders";
import StoreOrdersSwag from "./pages/StoreOrdersSwag";
import Suppliers from "./pages/Suppliers";
import managers from "./pages/managers";
import DataTables from "./pages/DataTables";
import addSupplier from "./pages/addSupplier";
import artFiles from "./pages/artFiles";
import editsupplier from "./pages/editsupplier";
import editmanager from "./pages/editmanager";
import orderDetails from "./pages/orderDetails";
import supplierOrders from "./pages/supplier/supplierOrders";
import supplierSettings from "./pages/supplier/supplierSettings";
import supplierShipmentOrders from "./pages/supplier/supplierShipmentOrders";
import SupplierOrderDetails from "./pages/supplier/orderDetails";
import SupplierShippingDetails from "./pages/supplier/SupplierShippingDetails";
import createManager from "./pages/createManager";
import managerOrders from "./pages/manager/managerOrders";
import printerOrders from "./pages/printer/printerOrders";
import printers from "./pages/printers";
import addPrinter from "./pages/addPrinter";
import printerOrdersDetails from "./pages/printer/printerOrdersDetails";
import GiftCards from "./pages/giftCards/index";
import CreateGiftCard from "./pages/giftCards/create";
import EditGiftCard from "./pages/giftCards/edit";
import ViewGiftCard from "./pages/giftCards/view";
import DemandedProducts from "./pages/demandedProducts";
import Stores from "./pages/stores/Stores";
import StoreView from "./pages/stores/StoreView";
import AppSetting from "./pages/appSettings/AppSetting";
import Allstores from './pages/allStores/index';
import Storedetail from './pages/storedetail/index';
import SwagStore from './pages/swagStore/index'
import AllOrder from './pages/allOrder/index'
import Orderdetails from './pages/ordersDetails/index'
import Help from './pages/newHelp'
import Notification from "./pages/notification/notification";
import GiftCard from './pages/allGiftCards'
import Nonapprelorderdetails from './pages/nonApparelOrderDetails/index'
import NonApparelOrder from './pages/nonApparelOrder/index'
import TopProducts from "./pages/topProducts/index";
import Inventory from "./pages/inventory/index";


import RunningCrons from "./pages/RunningCrons";
import CronLogs from "./pages/CronLogs";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/latest-dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
         {/* new routes */}
        <Route path="/latest-dashboard" component={NewDashboard} />
        <Route path="/top-products" component={TopProducts} />
        <Route path="/inventory" component={Inventory} />
        

        {/* new all page router */}
        <Route path="/stores/:store" component={Allstores} />
        <Route path="/stores-detail/:store" component={Storedetail} />
        <Route path="/swag-stores" component={SwagStore} />
        <Route path="/all-orders" component={AllOrder} />
        <Route path="/order-details/:store/:orderId" component={Orderdetails} />
        <Route path="/non-apparel-orders" component={NonApparelOrder} />
        <Route path="/non-apparel/order-details/:store/:orderId" component={Nonapprelorderdetails} />
        <Route path="/help" component={Help} />
        <Route path="/notifications" component={Notification} />
        <Route path="/latest-gift-cards" component={GiftCard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/StoresPage" component={StoresPage} />
        <Route path="/demanded-products/:shop" component={DemandedProducts} />
        <Route path="/StoreOrders/:shop/:token" component={StoreOrders} />
        <Route path="/StoreOrdersSwag/:shop/:token" component={StoreOrdersSwag} />
        <Route path="/Suppliers" component={Suppliers} />
        <Route path="/managers" component={managers} />
        <Route path="/DataTables" component={DataTables} />
        <Route path="/addSupplier" component={addSupplier} />
        <Route path="/artFiles" component={artFiles} />
        <Route path="/addManager" component={createManager} />
        <Route path="/editsupplier/:id" component={editsupplier} />
        <Route path="/editmanager/:id" component={editmanager} />
        <Route path="/orderDetails/:id/:shop/:token" component={orderDetails} />
        <Route path="/printers" component={printers} />
        <Route path="/addPrinter" component={addPrinter} />
        <Route path="/all-notifications" component={AllNotifications} />
        <Route path="/gift-cards" component={GiftCards} />
        <Route path="/create-giftcards" component={CreateGiftCard} />
        <Route path="/edit-giftcard/:id" component={EditGiftCard} />
        <Route path="/giftcard/:id" component={ViewGiftCard} />

        {/* supplier routes*/}
        <Route path="/supplier/orders" component={supplierOrders} />
        <Route path="/supplier/settings" component={supplierSettings} />
        <Route path="/supplier/shipment/orders" component={supplierShipmentOrders} />
        <Route path="/supplier/orderDetails/:id/:shop/:token" component={SupplierOrderDetails} />
        <Route path="/supplier/shipping/details/:id" component={SupplierShippingDetails} />

        {/* manager routes */}
        <Route path="/manager/orders" component={managerOrders} />

        {/* printer routes */}
        <Route path="/printer/orders" component={printerOrders} />
        <Route path="/printer/orderDetails/:id/:shop/:token" component={printerOrdersDetails} />

        {/* running crons */}
        <Route path="/running-crons" component={RunningCrons} />
        <Route path="/cron-logs" component={CronLogs} />

        {/* <Route path="/stores" component={Stores} /> */}
        <Route path="/store/view/:id" component={StoreView} />
        <Route path="/app-settings" component={AppSetting} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
