import React from 'react'
import './topProducttable.css'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function index() {
    
    return (
        <>
            <div className='main-inner-top-pro-tbl'>
                <div className='main-inner-left-table'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='main-nr-tbl'>
                                <h4>Top Products</h4>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='main-nr-tbl2'>
                                <p>Sort By</p>
                                <select>
                                    <option>All Stores</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='main-fill-scroll'>
                        <div className='main-inr-tbl-data main-ursor-scrol'>
                            <table>
                                <tr className='none-bodr'>
                                    <th>Product SKU</th>
                                    <th>Product</th>
                                    <th>Revenue</th>
                                    <th>Sold</th>
                                    <th>Visits</th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td>INV-047</td>
                                        <td><img src={toAbsoluteUrl('media/rectangle80.svg')}></img>Stark Banner Shirt</td>
                                        <td>$9,281</td>
                                        <td>371</td>
                                        <td> 1290<p>18.5% converted</p> <ProgressBar now={60} /></td>
                                    </tr>
                                    <tr>
                                        <td>INV-047</td>
                                        <td><img src={toAbsoluteUrl('media/rectangle80.svg')}></img>Stark Banner Shirt</td>
                                        <td>$9,281</td>
                                        <td>371</td>
                                        <td> 1290<p>18.5% converted</p> <ProgressBar now={90} /></td>
                                    </tr>
                                    <tr>
                                        <td>INV-047</td>
                                        <td><img src={toAbsoluteUrl('media/rectangle80.svg')}></img>Stark Banner Shirt</td>
                                        <td>$9,281</td>
                                        <td>371</td>
                                        <td> 1290<p>18.5% converted</p> <ProgressBar now={40} /></td>
                                    </tr>
                                    <tr>
                                        <td>INV-047</td>
                                        <td><img src={toAbsoluteUrl('media/rectangle80.svg')}></img>Stark Banner Shirt</td>
                                        <td>$9,281</td>
                                        <td>371</td>
                                        <td> 1290<p>18.5% converted</p> <ProgressBar now={30} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='view-all-tabl-btn'>
                                <button>View All</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
