import React, { Component } from "react";
import { connect } from 'react-redux';
import { getCronLogs } from '../../../redux/actions/cronLogs'
import moment from "moment";
import $ from 'jquery';
import { Link } from "react-router-dom";
import './index.css'

class CronLogs extends Component {
    state = {
        cards: [],
        gotResp: false
    }
    componentDidMount() {
        const { auth } = this.props
        const { user } = auth
        this.props.getCronLogs(user.role);
        // this.props.getGiftCard();
    }
    componentDidUpdate(prevProps) {
        const { cronLog } = this.props
        console.log('cronLog :::', cronLog)
        if (prevProps.cronLog !== cronLog) {
            if (cronLog.success) {
                this.setState({ cards: cronLog.data })
            }
            this.setState({ gotResp: true })
        }
    }
    render() {
        const { runningCron, runningCronError, runningCronSuccess } = this.props
        console.log("this.state.cards", this.state.cards)

        const tableStyle = {
            width: "100%",
        };
        let columns = [
            // { title: "ID", data: "id" },
            { title: "Shopify_order_id", data: "shopify_order_id" },
            { title: "Order_type", data: "order_type" },
            { title: "Order_id", data: "order_id" },
            { title: "Status", data: "status" },
            { title: "Start", data: "start" },
            { title: "End", data: "end" },
            { title: "Error_message", data: "error_message" },

        ];
        if (!$.fn.DataTable.isDataTable('#myTable') && this.state.gotResp) {
            $('#myTable').DataTable({
                data: this.state.cards,
                columns,
                ordering: true,
                order: [[4, 'desc']],
                createdRow: function (row, data, dataIndex) {
                    //SS
                    if (data['status'] == 'order_placed') {
                      $(row).find('td:nth-child(4)').addClass('successTableRow');
                    }
                    else{
                      $(row).find('td:nth-child(4)').addClass('dangerTableRow');
                    }
                  }
            });
        }
        return (
            <div>
                <table id="myTable"  responsive="true" hover="true" className="project-table table table-striped table-bordered" style={tableStyle}></table>
            </div>
        )
    }
}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        auth: state.auth,
        cronLog: state.getCronLogs.cronLog,
        cronLogSuccess: state.getCronLogscronLogSuccess,
        cronLogError: state.getCronLogs.cronLogError
        // giftCards: state.getGiftCards

    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getCronLogs: (body) => getCronLogs(dispatch, body),
});
export default connect(mapStateToProps, mapDispatchToProps)(CronLogs);

