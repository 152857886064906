import React, { Component } from "react";
import { connect } from 'react-redux';
import { getShops } from '../../redux/actions/getShops';

class editmanager extends Component {
    constructor(props) {

        super(props);

        this.state = {

            name: '',
            email: '',
            location: '',
            userid: '',
            store: '',
            shops: []
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.res !== prevProps.res) {
            if (this.props.res.success) {
                this.setState({ shops: this.props.res.message });
            }
        }
    }
    componentDidMount() {
        this.props.getShops();
        const url = process.env.REACT_APP_API_URL + 'api/getsupplier';

        const id = this.props.match.params.id;
        const input = {

            id: id
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(input)
        };

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then(booksList => {
                this.setState({ name: booksList.name, email: booksList.email, location: booksList.location, store: booksList.store_id });

            });
    }

    handleInputChange = (e) => {

        this.setState({

            [e.target.name]: e.target.value

        });
    }

    handleSubmit = (e) => {

        e.preventDefault();

        const url = process.env.REACT_APP_API_URL + 'api/supplierupdate/' + this.props.match.params.id;
        const user = {

            name: this.state.name,
            email: this.state.email,
            location: this.state.location,
            store_id: this.state.store
        }
        console.log("this.store", this.state.store)
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        };

        fetch(url, requestOptions)
            .then(() => { this.props.history.push('/managers') })
            .catch((e) => {
                console.log(e)
            });

    }



    render() {
        console.log("this.state.store",this.state.store)
        return (
            <form className="demoForm" onSubmit={this.handleSubmit}>
                <h2>Edit Manager</h2>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control"
                        name="name" required="required" defaultValue={this.state.name || ""} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" onChange={this.handleInputChange} className="form-control"
                        name="email" required="required" defaultValue={this.state.email || ""} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Store<span className="text-danger">*</span></label>
                    <select onChange={this.handleInputChange} className="form-control" value={this.state.store} name="store" >
                        {this.state.shops.length > 0 && this.state.shops.map((itm, i) => (
                            <option key={i} value={itm.id}  >{itm.shop_name}</option>
                        ))
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input type="text" onChange={this.handleInputChange} className="form-control"
                        name="location" required="required" defaultValue={this.state.location || ""} />
                </div>
                <button type="submit" className="btn btn-primary">
                    Update Manager
                </button>
            </form>
        )
    }

}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        res: state.GetShops,
        auth: state.auth
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getShops: () => getShops(dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(editmanager);