import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChosenShop } from "../../../redux/actions/getChosenShop";
import Modal from './Modal';

class AppSetting extends Component {
    state = {
        shop: [],
        isShowing: false
    }
    componentDidMount() {
        let id = this.props.match.params.id
        this.props.getChosenShop(id);

    }

    componentDidUpdate(prevProps) {
        if (this.props.shop !== prevProps.shop) {
            if (this.props.shop.success) {
                this.setState({ shop: this.props.shop.message.data })
            }
        }
    }
    openModalHandler = () => {
        this.setState({
            isShowing: true,
        });
    }
    closeModalHandler = () => {
        this.setState({
            isShowing: false
        });

        let id = this.props.match.params.id
        this.props.getChosenShop(id);
    }
    render() {
        const shop = this.state.shop;
        console.log("shop", shop)
        const table = {
            width: "100%",
        };

        return (
            <div>
                <div className="mb-5">
                    <h4>Selected store for App installation</h4>
                    {shop ?
                        <div className="container bg-white">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Store Name</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shop ?
                                                    <tr>
                                                        <td>{shop.shop_name}</td>
                                                        <td><button onClick={() => this.openModalHandler()} className="btn btn-warning"><i className="fa fa-pen"></i> Edit</button></td>
                                                    </tr>
                                                    :
                                                    <tr><td colSpan="2"><center> No Records Found</center></td></tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <button onClick={() => this.openModalHandler()} className="btn btn-warning"><i className="fa fa-pen"></i> Add</button>
                    }
                </div>
                <Modal
                    className="modal"
                    show={this.state.isShowing}
                    close={this.closeModalHandler}
                    id={shop.id}
                />
            </div>
        )
    }

}

const mapStateToProps = state => (
    {
        shop: state.GetChosenShop,
    }
);
const mapDispatchToProps = dispatch => ({
    getChosenShop: () => getChosenShop( dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AppSetting);