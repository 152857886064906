import axios from "axios";
export const SUPPLIER_ORDER_SUCCESS = "SUPPLIER_ORDER_SUCCESS";
export const SUPPLIER_ORDER_FAILURE = "SUPPLIER_ORDER_FAILURE";


// calling api to login
export const supplOrders = (type,dispatch) => {
    
    axios.get(process.env.REACT_APP_API_URL + 'api/supplier/orders?type=' + type)
        .then(response => {
            if (response.data.length == 0) {
                dispatch({
                    type: SUPPLIER_ORDER_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: SUPPLIER_ORDER_SUCCESS,
                    message: response.data.data,
                    token: response.token
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

