import React from 'react';
import { connect } from 'react-redux';
import '../../css/Modal.css';
import loading from '../../images/loading.gif';
import { createShop } from "../../../redux/actions/createShop";
class CreateStoreModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            submitted: false,
        }
    }
    componentDidMount() {
    }
    handleSubmitForm = async e => {
        e.preventDefault();
        var form = e.target;
        var error = [];
        var valid = true;
        if (form.shop_name.value === "") {
            error['shop_name'] = '*Please Enter Shop Name';
            valid = false;
        }
        if (form.api_key.value === "") {
            error['api_key'] = '*Please Enter Api Key';
            valid = false;
        }
        if (form.api_secret.value === "") {
            error['api_secret'] = '*Please Enter Api Secret';
            valid = false;
        }
        if (valid) {
            this.setState({ submitted: true })
            this.props.createShop(form.shop_name.value, form.api_key.value, form.api_secret.value);
        }
        this.setState({ error: error });
    }
    componentDidUpdate(prevProps) {
        if (this.props.res !== prevProps.res) {
            if (this.props.res.success) {
                this.setState({ submitted: false })
                document.getElementById('closeBtn').click();
            }
            else {
                this.setState({ submitted: false })
                console.log("thjd.ptopd", this.props.res)
                let error = [];
                error['api_error'] = this.props.res.message;
                this.setState({ error: error });
            }
        }
        if (prevProps.api_key !== this.props.api_key) {
            console.log("api_key here", this.props.api_key)
            this.setState({ key: this.props.api_key, secret: this.props.api_secret })
        }
    }
    render() {
        return (
            <div className="main-model" >
                <div className="modal-wrapper"
                    style={{
                        transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0',
                    }}>
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="modal-header">

                            <h4>App credentials</h4>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="shop_name" placeholder="Shop name" name="shop_name" />
                                        <div className="text-danger">{this.state.error.shop_name}</div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="api_key" placeholder="Api key" name="api_key" />
                                        <div className="text-danger">{this.state.error.api_key}</div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="api_secret" placeholder="Api secret" name="api_secret" />
                                        <div className="text-danger">{this.state.error.api_secret}</div>
                                    </div>
                                <div className="text-danger text-center">{this.state.error.api_error}</div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="closeBtn" className="btn btn-warning" onClick={this.props.close}>CLOSE</button>
                            <button disabled={this.state.submitted} id="continueBtn" className="btn btn-success">SAVE</button>
                            <img src={loading} id="loading" style={{ display: this.state.submitted ? "inline-block" : "none" }} width='90px' alt="loading" />
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}
const mapStateToProps = state => (
    {
        res: state.CreateShop,
    }
)
const mapDispatchToProps = dispatch => ({
    createShop: (shop_name, api_key, api_secret) => createShop(shop_name, api_key, api_secret, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateStoreModal);