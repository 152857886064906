import axios from "axios";
export const GET_RUNNING_CRON_SUCCESS = "GET_RUNNING_CRON_SUCCESS";
export const GET_RUNNING_CRON_FAILURE = "GET_RUNNING_CRON_FAILURE";


// calling api to get running crons
export const getRunningCrons = ( dispatch, userRole) => {
    axios.get(process.env.REACT_APP_API_URL + `api/runningCrons/${userRole}`, {
        headers: {
            Authorization: 'Basic ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            if (!response.data.success) {
                dispatch({
                    type: GET_RUNNING_CRON_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: GET_RUNNING_CRON_SUCCESS,
                    message: response.data,
                });
            }

        }).catch(error => {
            console.log("error", error)
        });
};

