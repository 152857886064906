import React, { useEffect, useState } from 'react'
import './style.css'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Spinner } from 'react-bootstrap';

export default function Products({ orderDetail, type }) {
    const [lineItems, setLineItems] = useState([])
    const [loading, setLoading] = useState(true)
    
    const orderType = {
        "alpha": "alphabroder",
        "smar": "sanmar",
        "ss": "ss",
    }
    useEffect(() => {
        if(loading && orderDetail && orderDetail.length > 0) {
            const lineItems = JSON.parse(orderDetail[0].line_items)

            let trackingNumbers = []
            if(orderDetail[0].tracking_numbers && orderDetail[0].tracking_numbers.length > 0){
                trackingNumbers = JSON.parse(orderDetail[0].tracking_numbers)
            }
           

            // console.log("trackingNumbers >>>", trackingNumbers)
            let data = []
            lineItems.map((itm, idx) => {
                const { sku } = itm
                orderDetail.map(order => {
                    if(type == 'apparel'){
                        let getSku = sku.split('-')[1];
                        if (sku.includes('INV')) {
                            getSku = sku.split('-')[2];
                        }
                        
                        if(orderType[getSku.toLowerCase().replace(' ', '')] == order.order_type.toLowerCase().replace(' ', '')){
                            
                            itm.order_type = order.order_type

                            let staticTrackingExists = trackingNumbers.find(o => o.line_item_id == itm.id);
                            // console.log("itm >>>", itm)
                            // console.log("staticTrackingExists >>>", staticTrackingExists)
                            if(staticTrackingExists){
                                itm.order_tracking_number = staticTrackingExists.tracking_number
                                itm.order_status = staticTrackingExists.order_status
                            }else{
                                itm.order_tracking_number = order.order_tracking_number
                                itm.order_status = order.order_status
                            }
                            
                            if(!data.includes(itm.id)) data.push(itm)
                        }
                    }else{
                        let getSku = sku.split('-')[1];
                        if (sku.includes('INV')) {
                            getSku = sku.split('-')[2];
                        }
                        if(typeof getSku == 'undefined' || getSku == null || getSku == ''){
                            itm.order_type = sku
                        }
                        if(getSku){
                            if(orderType[getSku.toLowerCase().replace(' ', '')]){
                                itm.order_type = getSku
                            }else{
                                itm.order_type = sku
                            }
                        }
                        
                        itm.order_status = "Non Processed"
                        
                        itm.order_tracking_number = order.order_tracking_number
                        if(!data.includes(itm.id)) data.push(itm)
                    }
                    
                })

            })
            setLineItems(data)
            setLoading(false)
        }
    })

    const handleStatusClass = (status) => {
        let classes = ''
        switch(status){
            case 'In Progress':
                classes ='in-progress';
                break;
            case 'Shipped':
                classes ='warning';
                break;
            case 'Delivered':
                classes ='Shipped';
                break;
            default: 
                classes = status;
                break;
        }
        return classes
    }
    return (
        <>
            <div className='main-inner-product-sammery'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='title-head'>
                            <h4>Products</h4>
                        </div>
                    </div>
                </div>
                {lineItems && lineItems.length > 0 ?
                    lineItems.map((line, idx) => {
                        return (
                            <div className='bottm-dashed' key={idx}>
                                <div className='row align-items-center'>
                                    <div className='col-md-6'>
                                        <div className='inner-dynimite'>
                                            <div className='product-image'>
                                                <div className='right-pro-img'>
                                                    <img src={toAbsoluteUrl('/images/Rectangle80.png')}></img>
                                                </div>
                                                <div className='inner-right-samry'>
                                                    <h4>{line.name}</h4>
                                                    <p>Product ID: <span>{line.product_id}</span></p>
                                                    <p>Tracking No.: <span><a href={`https://wwwapps.ups.com/WebTracking/processInputRequest?loc=en_US&Requester=UPSHome&AgreeToTermsAndConditions=yes&tracknum=${line.order_tracking_number}/trackdetail`} target="_blank">{line.order_tracking_number}</a></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='progress-or-not'>
                                            <button className={handleStatusClass(line.order_status)}>{line.order_status}</button>
                                            <p>Order Assigned To :<span> {line.order_type}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className='text-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
            </div>
        </>
    )
}
