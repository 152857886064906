import axios from "axios";
export const CREATE_SHOP_SUCCESS = "CREATE_SHOP_SUCCESS";
export const CREATE_SHOP_FAILURE = "CREATE_SHOP_FAILURE";


export const createShop = (shop_name, api_key, api_secret, dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/shop/create/', { shop_name, api_key, api_secret })
        .then(response => {
            if (response.isSuccess === false) {
                dispatch({
                    type: CREATE_SHOP_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: CREATE_SHOP_SUCCESS,
                    message: response.data
                });
            }
        }).catch(error => {
            console.log("error", error.response.data);
            if (error.response.data.isSuccess === false) {
                dispatch({
                    type: CREATE_SHOP_FAILURE,
                    message: error.response.data.msg
                });
            }
        });
};

