import React, { Component, useEffect } from 'react'
import './style.css'
import { Spinner } from 'react-bootstrap'
//jQuery libraries
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

export default class StoreTable extends Component {
    state = {
        stores: [],
        loading : true
    }

    componentDidMount() {
        const { userInfo } = this.props
        // console.log('store :::::::::::::::::::::::::', store);

        
        let url = `${process.env.REACT_APP_API_URL}api/stores/all`
        if(userInfo?.role == 6){
            url = `${process.env.REACT_APP_API_URL}api/stores/${userInfo.store_name}`
        }
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then(resp => {
                this.setState({
                    stores: resp,
                    loading: false
                })
            });
    }

    handleModel = (data, mode) => {
        const { handleBtnResponse } = this.props

        handleBtnResponse(data, 'edit')
    }
    render() {
        const { stores, loading } = this.state
        const { handleModel } = this

        let columns = [
            { title: "Store Name", data: "store_name" },
            { title: "Store Owner", data: "store_owner" },
            { title: "Added on", data: "added_on" },
            // {
            //     data: null,
            //     className: "center",
            //     render: function (data, type, row) {

            //         // return `<a href="/stores/${data.store_name}/${data.store_owner}/${data.added_on}" class="btn btn-info btn-sm link" style="color:white">
            //         //     <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg" >
            //         //         <rect x="0.5" width="39" height="39" rx="6" fill="#ECF9FF" />
            //         //         <path fillRule="evenodd" clipRule="evenodd" d="M25.0281 10.8147C25.076 10.8521 26.6793 12.0996 26.6793 12.0996C27.262 12.4469 27.7172 13.0678 27.8897 13.8028C28.0612 14.5302 27.9366 15.2805 27.537 15.9148C27.5343 15.919 27.5317 15.9232 27.5224 15.9357L27.5152 15.9453C27.4504 16.0309 27.1684 16.3879 25.7452 18.1732C25.732 18.1966 25.717 18.2185 25.7007 18.2398C25.6767 18.2712 25.6505 18.3001 25.6227 18.3264C25.5256 18.4487 25.4231 18.5773 25.3153 18.7125L25.0968 18.9864C24.6461 19.5516 24.1115 20.2218 23.4773 21.0166L23.1518 21.4246C21.9274 22.9591 20.3593 24.9238 18.3504 27.4402C17.9106 27.989 17.2512 28.3086 16.543 28.3172L13.0556 28.3613H13.0461C12.7135 28.3613 12.4241 28.133 12.3465 27.8077L11.5616 24.477C11.3996 23.7871 11.5606 23.0751 12.0024 22.5224L21.0299 11.2168C21.0338 11.2129 21.0366 11.2081 21.0405 11.2043C22.0304 10.0192 23.8206 9.84459 25.0281 10.8147ZM20.0234 14.7809L13.1246 23.4215C12.9608 23.6268 12.9004 23.8917 12.9608 24.146L13.6134 26.9143L16.5258 26.8779C16.8027 26.875 17.0586 26.7512 17.2282 26.5401C18.1017 25.4458 19.1996 24.0704 20.3283 22.6561L20.7276 22.1558L21.1276 21.6545C22.1862 20.3281 23.232 19.0175 24.107 17.9205L20.0234 14.7809ZM22.1473 12.1226L20.9214 13.6563L25.0046 16.795C25.7906 15.8093 26.3076 15.1601 26.3554 15.0982C26.5126 14.843 26.5739 14.4822 26.4915 14.1348C26.4071 13.7788 26.1858 13.4766 25.8666 13.2837C25.7986 13.2367 24.1838 11.9815 24.134 11.9422C23.5264 11.4547 22.6399 11.5392 22.1473 12.1226Z" fill="#012C9F" />
            //         //     </svg>
            //         // </a>`;

            //        return (`
            //             <div className='icons-edit'>
            //                 <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg" >
            //                     <rect x="0.5" width="39" height="39" rx="6" fill="#ECF9FF" />
            //                     <path d="M28.9856 18.236C29.4201 18.8043 29.4201 19.5716 28.9856 20.139C27.617 21.9256 24.3335 25.6042 20.5 25.6042C16.6665 25.6042 13.383 21.9256 12.0144 20.139C11.803 19.8669 11.6882 19.5321 11.6882 19.1875C11.6882 18.8429 11.803 18.5081 12.0144 18.236C13.383 16.4494 16.6665 12.7708 20.5 12.7708C24.3335 12.7708 27.617 16.4494 28.9856 18.236V18.236Z" stroke="#012C9F" strokeWidth="1.54" strokeLinecap="round" strokeLinejoin="round" />
            //                     <path d="M20.5 21.9375C22.0188 21.9375 23.25 20.7063 23.25 19.1875C23.25 17.6687 22.0188 16.4375 20.5 16.4375C18.9812 16.4375 17.75 17.6687 17.75 19.1875C17.75 20.7063 18.9812 21.9375 20.5 21.9375Z" stroke="#012C9F" strokeWidth="1.54" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //             </div>`
            //        )
            //     }
            // }
        ];
        if (!$.fn.DataTable.isDataTable('#storesTable') && !loading) {
            $('#storesTable').DataTable({
                data: stores,
                columns,
                sDom: 'Rfrtlip',
                processing: true,
                responsive: true,
                ordering: false,
                language: {
                    paginate: {
                      next: `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="26" height="26" rx="13" fill="#E6E6E6" />
                        <path d="M10.639 8.62475C10.5101 8.75368 10.4377 8.92851 10.4377 9.11082C10.4377 9.29312 10.5101 9.46795 10.639 9.59688L14.0422 13L10.639 16.4031C10.5138 16.5328 10.4445 16.7065 10.4461 16.8867C10.4476 17.067 10.5199 17.2394 10.6474 17.3669C10.7749 17.4943 10.9473 17.5666 11.1276 17.5682C11.3078 17.5698 11.4815 17.5005 11.6112 17.3753L15.5003 13.4861C15.6292 13.3571 15.7016 13.1823 15.7016 13C15.7016 12.8177 15.6292 12.6429 15.5003 12.5139L11.6112 8.62475C11.4822 8.49587 11.3074 8.42346 11.1251 8.42346C10.9428 8.42346 10.768 8.49587 10.639 8.62475Z" fill="#4C4C4C" />
                      </svg>`, // or '>'
                      previous: `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="26" height="26" rx="13" fill="#E6E6E6" />
                        <path d="M14.361 8.62475C14.4899 8.75368 14.5623 8.92851 14.5623 9.11082C14.5623 9.29312 14.4899 9.46795 14.361 9.59688L10.9578 13L14.361 16.4031C14.4862 16.5328 14.5555 16.7065 14.5539 16.8867C14.5524 17.067 14.4801 17.2394 14.3526 17.3669C14.2251 17.4943 14.0527 17.5666 13.8724 17.5682C13.6922 17.5698 13.5185 17.5005 13.3888 17.3753L9.49965 13.4861C9.37077 13.3571 9.29836 13.1823 9.29836 13C9.29836 12.8177 9.37077 12.6429 9.49965 12.5139L13.3888 8.62475C13.5178 8.49587 13.6926 8.42346 13.8749 8.42346C14.0572 8.42346 14.232 8.49587 14.361 8.62475Z" fill="#4C4C4C" />
                      </svg>` // or '<' 
                    }
                },
                fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $(nRow).click(function() {
                        // redirect
                        document.location.href = `/all-orders?store=${aData.store_name}`;
                    });
                },
            });
        }
        return (
            <>
                <div className='main-inner-left-table'>
                    <div className='row align-items-center'>
                        <div className='col-12'>
                        </div>
                    </div>
                    <table id="storesTable" className="table"></table>
                </div>
            </>
        )
    }
}