import { element } from "prop-types";
import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import $ from 'jquery';
import Modal from './shippoModal';

class printerOrdersDetails extends Component {

  state = {
    order: [],
    billing: [],
    shipping: [],
    items: [],
    customer: [],
    artfiles: [],
    orderresponse: [],
    ssOrderDetail: {
      printer_status: 0
    },
    error: "",
    photoIndex: 0,
    isOpen: false,
    lightboxSrc: "",
    isShowing: false,
    chosenObj: {},
    dimensions: []
  }
  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
    const param = {
      shop: this.props.match.params.shop,
      token: this.props.match.params.token,
      orderid: this.props.match.params.id
    }
    this.orderDimensions(param);
  }
  handleChangeDimensions = (event) => {
    console.log("event", event.target.name, event.target.value)
    let { chosenObj } = this.state;
    chosenObj[event.target.name] = event.target.value;
    this.setState({ chosenObj })
  }
  openModalHandler = (order_id, height, length, width, weight) => {
    if (height === undefined)
      height = 0;
    if (length === undefined)
      length = 0;
    if (width === undefined)
      width = 0;
    if (weight === undefined)
      weight = 0;
    console.log()
    this.setState({
      isShowing: true,
      chosenObj: { order_id, height, length, width, weight }
    });
  }
  componentDidMount() {
    const param = {
      shop: this.props.match.params.shop,
      token: this.props.match.params.token,
      orderid: this.props.match.params.id
    }
    fetch(process.env.REACT_APP_API_URL + 'api/orderDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'X-Shopify-Access-Token': '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'
      },
      body: JSON.stringify(param)
    })
      .then((response) => response.json())
      .then(ordersDetails => {
        // Get the list of the order products
        if (ordersDetails.order) {
          this.setState({ order: ordersDetails.order, billing: ordersDetails.order.billing_address, shipping: ordersDetails.order.shipping_address, /* items: ordersDetails.order.line_items,*/ customer: ordersDetails.order.customer });
        }
        else {
          this.setState({ error: "Order Not found" })
        }
      });
    this.orderDetails(param);
    this.orderDimensions(param);
    fetch(process.env.REACT_APP_API_URL + 'api/artfile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(param)
    }).then((response) => response.json())
      .then(artfiles => {
        let artfilesArr = [];
        artfiles.forEach(element => {
          artfilesArr[element.product_id] = element;
        });
        this.setState({ artfiles: artfilesArr })
      });
  }
  orderDimensions = (param) => {
    fetch(process.env.REACT_APP_API_URL + 'api/order/dimensions?order_id=' + param.orderid, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(param)
    }).then((response) => response.json())
      .then(dimensions => {
        this.setState({ dimensions })
      });
  }
  orderDetails = (param) => {
    fetch(process.env.REACT_APP_API_URL + 'api/order/detail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(param)
    })
      .then((response) => response.json())
      .then(orderresponse => {
        let ssOrderDetail = {
          id: orderresponse[0].ss_orderid,
          status: orderresponse[0].ss_order_status,
          ss_order_tracking_number: orderresponse[0].ss_order_tracking_number,
          tracking_number: orderresponse[0].tracking_number,
          tracking_status: orderresponse[0].tracking_status,
          amount: orderresponse[0].amount,
          currency: orderresponse[0].currency,
          shipment_objectId: orderresponse[0].shipment_objectId,
          tracking_status: orderresponse[0].tracking_status,
          tracking_status_details: orderresponse[0].tracking_status_details,
          tracking_url_provider: orderresponse[0].tracking_url_provider,
          label_url: orderresponse[0].label_url,
          ssError: orderresponse[0].ss_error,
          printer_status: orderresponse[0].printer_status
        }
        this.setState({ ssOrderDetail: ssOrderDetail, items: JSON.parse(orderresponse[0].line_items) });
      });
  }
  handleChange(event) {
    console.log("sytats", event.target.value);
    let status = event.target.value;
    fetch(process.env.REACT_APP_API_URL + 'api/printer/status?order_id=' + this.state.order.id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status })
    }).then((response) => response.json())
      .then(resp => {
        var { ssOrderDetail } = this.state
        ssOrderDetail['printer_status'] = status;
        this.setState({ ssOrderDetail })
      });
  }
  handleBlankStatus(event) {
    let status = event.target.value;
    let dataId = event.target.getAttribute('data-id');
    let issue = '';
    if (dataId === "") {
      console.log("no artfile row available")
      return;
    }
    let artfiles = this.state.artfiles;
    for (const itm in artfiles) {
      console.log("aa", itm);
      if (artfiles[itm].id == dataId) {
        artfiles[itm]['blanks_received'] = status
      }
    }
    this.setState({ artfiles })
    if (status === "3") {
      console.log("insert issue")
      return;
    }
    fetch(process.env.REACT_APP_API_URL + 'api/printer/blank-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: dataId, status, issue: issue })
    }).then((response) => response.json())
      .then(resp => {
        console.log("resp", resp)
      });
  }
  handleBlankIssue(event) {
    let issue = event.target.value;
    let dataId = event.target.getAttribute('data-id');
    let status = 3;
    let artfiles = this.state.artfiles;
    for (const itm in artfiles) {
      if (artfiles[itm].id == dataId) {
        artfiles[itm]['blanks_received'] = status
        artfiles[itm]['blanks_issue'] = issue
      }
    }
    this.setState({ artfiles });
  }
  handleCreateLabel(orderId) {
    fetch(process.env.REACT_APP_API_URL + 'api/shippo/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orderId })
    }).then((response) => response.json())
      .then(resp => {
        console.log("resp", resp)
        // this.closeModalHandler();
      });
  }
  handleBlankIssueUpdate(event) {
    let issue = event.target.value;
    let dataId = event.target.getAttribute('data-id');
    let status = 3;
    if (dataId === "" || issue === "") {
      console.log("no artfile row available or no issue entered")
      return;
    }
    let artfiles = this.state.artfiles;
    for (const itm in artfiles) {
      if (artfiles[itm].id == dataId) {
        artfiles[itm]['blanks_received'] = status
        artfiles[itm]['blanks_issue'] = issue
      }
    }
    this.setState({ artfiles });
    fetch(process.env.REACT_APP_API_URL + 'api/printer/blank-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: dataId, status, issue: issue })
    }).then((response) => response.json())
      .then(resp => {
        console.log("resp", resp)
      });
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var dimensions = this.state.dimensions.data;
    var data = this.state.order;
    var billing = this.state.billing;
    var shipping = this.state.shipping;
    var customer = this.state.customer;
    var items = this.state.items;
    var artfiles = this.state.artfiles;
    var ssOrderDetail = this.state.ssOrderDetail;
    const orderid = {
      margin: '10px 0 20px 0',
      display: 'inline-block'
    };
    console.log("ssOrderDetail",ssOrderDetail)
    return (
      <>
        {this.state.error === "" ?
          <div>
            <div className="printerOrderStatus text-center form-group row">
              <div className="col-6 m-auto">
                <label className="form-label px-3">Order status: </label>
                <select className="form-control d-inline-block" style={{ width: "auto" }} value={ssOrderDetail.printer_status.toString()} onChange={(event) => this.handleChange(event)}>
                  <option value="0" disabled={ssOrderDetail.printer_status != 0}>Order awaiting</option>
                  <option value="1" disabled={ssOrderDetail.printer_status != 1 && ssOrderDetail.printer_status != 0} >Blanks received</option>
                  <option value="2" disabled={ssOrderDetail.printer_status != 2 && ssOrderDetail.printer_status != 1} >In production</option>
                  <option value="3" disabled={true} >Shipped</option>
                </select>
              </div>
            </div>
            <div className="card">
              <div className="card-header bg-light py-4">
                <h4 className="mb-0">Order #{data.id}</h4>
              </div>
              <div className="card-body"  >
                <div className="row mb-5">
                  <div className="col-5 bg-secondary py-2 px-5 mx-5">
                    <h5>Order Details</h5>
                    <div>
                      <i className="fab fa-shopify"></i> Order Number:  <b>{data.order_number}</b>
                    </div>
                    <div>
                      <i className="fas fa-dollar-sign"></i> Total Price: <b>{data.current_total_price}</b>
                    </div>
                    <div>
                      <i className="fas fa-dollar-sign"></i> Financial Status: <b>{data.financial_status}</b>
                    </div>
                    <div>
                      <i className="fas fa-calendar-day"></i> Created At: <b>{data.created_at}</b>
                    </div>

                  </div>
                  <div className="col-6 bg-secondary py-2 px-5 border-left border-white ml-5">
                    <h5>Billing and Shipping Details</h5>
                    <div>
                      <i className="fas fa-map-marked"></i> Billing Address: <b>{billing.first_name} {billing.last_name}, {billing.address1}, {billing.city}, {billing.province}, {billing.country}, {billing.zip}</b>
                    </div>
                    <div>
                      <i className="fas fa-map-marked"></i> Shipping Address: <b>{shipping.first_name} {shipping.last_name}, {shipping.address1}, {shipping.city}, {shipping.province}, {shipping.country}, {shipping.zip}</b>
                    </div>
                  </div>
                  <div className="col-5 bg-secondary py-2 px-5 mx-5 my-5">
                    <h5>Customer Details</h5>
                    <div>
                      Name:  <b>{customer.first_name} {customer.last_name}</b>
                    </div>
                    <div>
                      Email: <b>{data.contact_email}</b>
                    </div>
                    <div>
                      Phone: <b>{customer.phone}</b>
                    </div>

                  </div>
                  <div className="col-6 bg-secondary py-2 px-5 border-left border-white ml-5 my-5">
                    <h5>S&S order details</h5>

                    {ssOrderDetail.id ?
                      <div>
                        <div>
                          Order Id: <b>{ssOrderDetail.id}</b>
                        </div>
                        <div>
                          Order Status: <b>{ssOrderDetail.status}</b>
                        </div>
                        <div>
                          Tracking Number:  <b>{ssOrderDetail.ss_order_tracking_number || ""}</b>
                        </div>
                      </div>
                      : <div>Order is not placed yet. {ssOrderDetail.ssError ? ssOrderDetail.ssError : ""} </div>
                    }
                  </div>
                  <div className="bg-secondary py-2 px-5 mx-5 mb-5 shippoDetailSection">
                    <b className="h5">Shippo Details</b> {!(ssOrderDetail.shipment_objectId != undefined) ? <button className="btn btn-sm btn-primary float-right" onClick={() => this.openModalHandler(data.id, dimensions.height, dimensions.length, dimensions.width, dimensions.weight)}>Create Label</button> : ""}
                    {(ssOrderDetail.shipment_objectId != undefined ) ?
                      <div>
                        <div>
                          Tracking Number:  <b>{ssOrderDetail.tracking_number || ""}</b>
                        </div>
                        <div>
                          Shipment ID: <b>{ssOrderDetail.shipment_objectId || ""}</b>
                        </div>
                        <div>
                          Tracking Status: <b>{ssOrderDetail.tracking_status || ""}</b>
                        </div>
                        <div>
                          Tracking Status Details: <b>{ssOrderDetail.tracking_status_details || ""}</b>
                        </div>
                        <div>
                          Shipping Amount: <b>{ssOrderDetail.currency || ""} {ssOrderDetail.amount || ""}</b>
                        </div>
                        <div>
                          Tracking URL: <b><a href={ssOrderDetail.tracking_url_provider || ""} target="_blank" >{ssOrderDetail.tracking_url_provider || ""}</a></b>
                        </div>
                        <div>
                          Label URL: <b><a href={ssOrderDetail.label_url || ""} target="_blank">{ssOrderDetail.label_url || ""}</a></b>
                        </div>
                      </div>
                      : <div>Order is not placed yet.</div>
                    }

                  </div>
                </div>
                <div className="row mt-5 table-responsive border">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">Product ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Size</th>
                        <th scope="col">Color</th>
                        <th scope="col">Supplier</th>
                        <th scope="col">Tracking Numbers</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Blank received</th>
                        <th scope="col">Decoration</th>
                        <th scope="col">Location</th>
                        <th scope="col">Art Size</th>
                        <th scope="col">Artfile</th>
                        {/* <th scope="col">Dimesions</th> */}
                      </tr>
                    </thead>
                    <tbody>

                      {this.state.items.map((items, i) => (
                        <tr key={i}>
                          <td >{this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].product_image == null ? "" : <img onClick={() => this.setState({ isOpen: true, lightboxSrc: this.state.artfiles[items.product_id].product_image })} src={this.state.artfiles[items.product_id].product_image} width="50" height="50" /> : ""}</td>
                          <td>{items.product_id}</td>
                          <td>{items.title}</td>
                          <td>{items.quantity}</td>
                          <td>{items.variant_title.split('/').length > 1 ? items.variant_title.split('/')[1] : items.variant_title}</td>
                          <td>{items.variant_title.split('/').length > 1 ? items.variant_title.split('/')[0] : ""}</td>
                          <td></td>
                          <td>{this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].ss_tracking_number || 'Not shipped yet.' : ""}</td>
                          <td>{this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].ss_order_status || '' : ""}</td>
                          <td className="form-group">
                            <select data-id={this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].id : ""} onChange={(event) => this.handleBlankStatus(event)} value={this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].blanks_received : "0"} className="form-control p-0 black_received">
                              <option disabled={this.state.artfiles[items.product_id] && this.state.artfiles[items.product_id].blanks_received > 0} value="0">Select status</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                              <option value="3">Issue</option>
                            </select>
                            {this.state.artfiles[items.product_id] && this.state.artfiles[items.product_id].blanks_received == 3 ?
                              <input data-id={this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].id : ""} value={this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].blanks_issue || "" : ""} className="form-control" type="text" name="blank_issue" id="blankIssue" placeholder="issue.." onChange={(event) => this.handleBlankIssue(event)} onBlur={(event) => this.handleBlankIssueUpdate(event)} />
                              : ""
                            }
                          </td>
                          <td></td>
                          <td>{this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].location : ""}</td>
                          <td></td>
                          <td><a href={this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].artfile : ""} target="_blank">{this.state.artfiles[items.product_id] ? this.state.artfiles[items.product_id].artfile : ""}</a></td>
                          {/* <td><button className="btn btn-sm btn-primary" onClick={() => this.openModalHandler(items.product_id, data.id, items.height, items.length, items.width)}>Create / Edit</button></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Modal
              className="modal"
              show={this.state.isShowing}
              close={this.closeModalHandler}
              obj={this.state.chosenObj}
              handleChange={this.handleChangeDimensions}
              createLabel={this.handleCreateLabel}
            >
            </Modal>
          </div>
          : <h3 className="text-center">{this.state.error}</h3>
        }
        {
          isOpen && (
            <Lightbox
              mainSrc={this.state.lightboxSrc}
              // nextSrc={images[(photoIndex + 1) % images.length]}
              // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
            // onMovePrevRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + images.length - 1) % images.length,
            //   })
            // }
            // onMoveNextRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + 1) % images.length,
            //   })
            // }
            />
          )
        }
      </>
    )
  }

}

export default printerOrdersDetails;