import React, { useEffect, useState } from "react";
import DateComponent from "../../components/date/index";
import Card from "../../components/priceCard/index";
import TopStores from "../../components/dashboardTables/TopStores";
import DueOrders from "../../components/dashboardTables/dueOrders";
import TopProducts from "../../components/dashboardTables/topProducts";
import LineChart from "../../components/lineChart";
import Chart2 from "../../components/lineChart/lineChart2/index";
import { shallowEqual, useSelector } from "react-redux";
import Inventory from '../../components/inventoryTable/index'
import BreadCrumb from '../../components/breadCrumb';
import "./style.css";

export default function Dashboard() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [stores, setStores] = useState(0);
  const [orders, setOrders] = useState([]);
  const [shippedOrders, setShippedOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [topStores, setTopStores] = useState(null);
  const [dueDateOrders, setDueDateOrders] = useState(null);
  const [topProducts, setTopProducts] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [selectDashboardStore, setSelectDashboardStore] = useState("all");
  const [selectStore, setSelectStore] = useState("all");
  const [filterDate, setFilterDate] = useState("");
  const [selectStoreForDueOrders, setSelectStoreForDueOrders] = useState("all");
  const [selectStoreForTopProducts, setSelectStoreForTopProducts] = useState( "all" ); 
  const [selectStoreForInventory, setSelectStoreForInventory] = useState( "all" );
  const [salesForMonth, setSalesForMonth] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [giftCards, setGiftCards] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const getDays = (year, month) => new Date(year, month, 0).getDate();

  useEffect(() => {
    setTimeout(function() {
      if (user.role == undefined) {
        window.location.reload();
      }
      if (user.role != undefined) {
        // console.log("store_name", user)
        let url =
          user.role == 3
            ? process.env.REACT_APP_API_URL +
              "api/dashboard?supplier_type=" +
              user.supplier_type
            : user.role == 2
            ? process.env.REACT_APP_API_URL +
              "api/dashboard?store_id=" +
              user.store_id
            : user.role == 4
            ? 
            // process.env.REACT_APP_API_URL + "api/printer/dashboard"
            process.env.REACT_APP_API_URL +
              `api/printer/dashboard/${user.role}/${filterDate || "all"}/${selectDashboardStore || "all"}?store_name=${user.store_name}`
            : process.env.REACT_APP_API_URL +
              `api/admin/dashboard/${user.role}/${filterDate || "all"}/${selectDashboardStore || "all"}?store_name=${user.store_name}`;
        // Update the document title using the browser API
        fetch(url, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((resp) => {
            setOrders(resp.orders);
            // setDeliveredOrders(resp.deliveredOrders[0].count);
            // setNewOrders(resp.newOrders[0].count);
            // setShippedOrders(resp.shippedOrders[0].count);
            setDeliveredOrders(resp.deliveredOrders);
            setNewOrders(resp.newOrders);
            setShippedOrders(resp.shippedOrders);
            setCancelledOrders(resp.cancelledOrders[0].count);
            setStores(resp.stores);
          });
      }
    }, 50);
    if (filterDate || selectDashboardStore || giftCards.loading == false) {
      const date = new Date();

      const month = date.getMonth();
      const year = date.getFullYear();

      const totalDays = getDays(year, month);

      switch (totalDays) {
        case 28:
          setGiftCards({
            data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
            total: 0,
            loading: true,
          });
          break;
        case 30:
          setGiftCards({
            data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
            total: 0,
            loading: true,
          });
          break;

        default:
          setGiftCards({
            data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
            total: 0,
            loading: true,
          });
          break;
      }
    }
    if (filterDate || topStores == null) {
      if(user.role != 6){
        let url = `${process.env.REACT_APP_API_URL}api/admin/stores/${selectStore}/${filterDate || "all"}`;
        // if(user.role == 6){
        //   url = `${process.env.REACT_APP_API_URL}api/admin/stores/${user.store_name}/${filterDate || "all"}`;
        // }
        
        // Update the document title using the browser API
        fetch(url, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((resp) => {
            resp.sort((a, b) => {
              return b.sales_by_volume - a.sales_by_volume;
            });
            setTopStores(resp);
          });
      }
    }
    if (filterDate || selectDashboardStore || salesForMonth.loading == false) {

      let url = `${process.env.REACT_APP_API_URL}api/admin/salesForMonth/${filterDate || "all"}/${selectDashboardStore || "all"}`;
      if(user.role == 6){
        url = `${process.env.REACT_APP_API_URL}api/admin/salesForMonth/${filterDate || "all"}/${user.store_name || "all"}`;
      }
      fetch(url, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((resp) => {
          // console.log("resp ::::::::::::::", resp);
          setSalesForMonth({
            data: resp.days,
            total: resp.sales_by_revenue,
            loading: true,
          });
        });
    }
    if (filterDate || dueDateOrders == null) {
      if(user.role != 6){
        let url = `${process.env.REACT_APP_API_URL}api/admin/orderDue/${selectStoreForDueOrders}/${filterDate || "all"}`;
        // if(user.role == 6){
        //   url = `${process.env.REACT_APP_API_URL}api/admin/orderDue/${user.store_name}/${filterDate || "all"}`;
        // }
        fetch(url, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((resp) => {
            let finalData = [];
            if (selectStoreForDueOrders == "all") {
              const finalData1 = resp.flat();
              resp.map((itm) => {
                finalData.push(itm.ordersArr);
              });
            } else {
              resp.map((itm) => {
                if (itm.store == selectStoreForDueOrders) {
                  finalData.push(itm.ordersArr);
                }
              });
            }
            finalData = finalData.flat();
            finalData = finalData.sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at)
            });
            setDueDateOrders(finalData.slice(0, 10));
          });
      }
    }
    if (filterDate || topProducts == null) {
      if(user.role != 6){
        let url = `${process.env.REACT_APP_API_URL}api/admin/topProducts/${selectStoreForTopProducts}/${filterDate || "all"}`;
        // if(user.role == 6){
        //   url = `${process.env.REACT_APP_API_URL}api/admin/topProducts/${user.store_name}/${filterDate || "all"}`;
        // }
        if(user.role == 4){
          url = `${process.env.REACT_APP_API_URL}api/printer/topProducts/${selectStoreForTopProducts}/${filterDate || "all"}`;
        }
        fetch(url, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.json())
        .then((resp) => {
          let finalData = [];
          if (selectStoreForTopProducts == "all") {
            const finalData1 = resp.flat();

            resp.map((itm) => {
              finalData.push(itm.products);
            });
          } else {
            resp.map((itm) => {
              if (itm.store == selectStoreForTopProducts) {
                finalData.push(itm.products);
              }
            });
          }
          finalData = finalData.flat();
          finalData = finalData.sort((a, b) => b.total_sold - a.total_sold);
          setTopProducts(finalData.slice(0, 10));
        });
      }
    }
    if (filterDate || inventory == null) {
      if(user.role != 6){
        let url = `${process.env.REACT_APP_API_URL}api/demanded-products/${selectStoreForInventory}`;
        // if(user.role == 6){
        //   url = `${process.env.REACT_APP_API_URL}api/demanded-products/${user.store_name}`;
        // }
        if(user.role == 4) {
          url = `${process.env.REACT_APP_API_URL}api/printer/demanded-products/all`;
        }

        fetch(url, { method: "get", headers: { "Content-Type": "application/json", }, })
          .then((response) => response.json())
          .then((resp) => {
            let finalData = resp;
            let flatData = finalData.flat();
            setInventory(flatData.flat())
          });
      }
    }
  }, [filterDate, selectDashboardStore]);
  
  const handleDashboardStore = (value) => {
    handleSelectStore(value);
    handleSelectStoreForDueOrders(value);
    handleSelectStoreForTopProducts(value);
    setSelectDashboardStore(value);
  };

  const handleSelectStore = (value) => {
    let url = `${process.env.REACT_APP_API_URL}api/admin/stores/${value}/${filterDate || "all"}`;
    if(user.role == 6){
      url = `${process.env.REACT_APP_API_URL}api/admin/stores/${user.store_name}/${filterDate || "all"}`;
    }
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        resp.sort((a, b) => {
          return b.sales_by_volume - a.sales_by_volume;
        });
        setTopStores(resp);
      });

    setSelectStore(value);
  };
  const handleSelectStoreForDueOrders = (value) => {
    let url = `${process.env.REACT_APP_API_URL}api/admin/orderDue/${value}/${filterDate || "all"}`;
    if(user.role == 6){
      url = `${process.env.REACT_APP_API_URL}api/admin/orderDue/${user.store_name}/${filterDate || "all"}`;
    }
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        let finalData = [];
        if (value == "all") {
          const finalData1 = resp.flat();

          resp.map((itm) => {
            finalData.push(itm.ordersArr);
          });
        } else {
          resp.map((itm) => {
            if (itm.store == value) {
              finalData.push(itm.ordersArr);
            }
          });
        }
        finalData = finalData.flat();
        finalData = finalData.sort((a, b) => a.date - b.date);
        setDueDateOrders(finalData.slice(0, 5));
      });

    setSelectStoreForDueOrders(value);
  };
  const handleSelectStoreForTopProducts = (value) => {
    let url = `${process.env.REACT_APP_API_URL}api/admin/topProducts/${value}/${filterDate || "all"}`;
    if(user.role == 6){
      url = `${process.env.REACT_APP_API_URL}api/admin/topProducts/${user.store_name}/${filterDate || "all"}`;
    }

    if(user.role == 4){
      url = `${process.env.REACT_APP_API_URL}api/printer/topProducts/${user.store_name}/${filterDate || "all"}`;
    }

    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        let finalData = [];
        if (value == "all") {
          const finalData1 = resp.flat();

          resp.map((itm) => {
            finalData.push(itm.products);
          });
        } else {
          resp.map((itm) => {
            if (itm.store == value) {
              finalData.push(itm.products);
            }
          });
        }

        finalData = finalData.flat();
        finalData = finalData.sort((a, b) => b.sold - a.sold);
        setTopProducts(finalData.slice(0, 5));
      });

    setSelectStoreForTopProducts(value);
  };
  const handleSelectStoreForInventory = (value) => {
    let url = `${process.env.REACT_APP_API_URL}api/demanded-products/${value}`;
    if(user.role == 6){
      url = `${process.env.REACT_APP_API_URL}api/demanded-products/${user.store_name}`;
    }
      fetch(url, { method: "get", headers: { "Content-Type": "application/json", } })
      .then((response) => response.json())
      .then((resp) => {
        let finalData = resp;
        let flatData = finalData.flat();
        setInventory(flatData.flat())
      });

      setSelectStoreForInventory(value);
  };

  const handleStoreName = (value) => {
    console.log("value >>>", value)
    return typeof value == 'undefined' ? '' : value.split('.myshopify.com')[0]
  };

  const handleFilterDate = (e) => {
    if (e.target.value) {
      setFilterDate(e.target.value);
    } else {
      setFilterDate("all");
    }
  };
  return (
    <>
      <div className="row mx-0">
        <div className="col-md-12 main-inr-all-data">
          <div className="row mx-0 align-items-center">
            <div className="col-md-6">
              <BreadCrumb type="dashboard"/>
            </div>
            <div className="col-md-6 justify-flex-end">
              {/* <div className="row align-items-center px-0"> */}
              <div className="main-inr-slect-ad text-right">
                {/* <div className="col-xl-10 col-lg-8 col-md-7 col-6 main-inr-slect-ad text-right"> */}
                  { user.role == 6 || user.role == 4
                      ? ""
                      : <select onChange={(e) => handleDashboardStore(e.target.value)} >
                          <option className={`${selectStore == "all" && "active"}`} name="all" value="all" > All Stores </option>
                          {orders &&
                            orders.length > 0 &&
                            orders.map((itm, idx) => {
                              return (
                                <option key={idx} className={`${selectStore == itm.store && "active"}`} name={itm.store} value={itm.store}> {handleStoreName(itm.store)} </option>
                              );
                            })}
                        </select>
                  }
                  
                {/* </div> */}
                {/* <div className="col-xl-2 col-lg-3 col-md-5 col-6"> */}
                  <DateComponent
                    handleFilterDate={handleFilterDate}
                    filterDate={filterDate}
                  />
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Card total={newOrders} title={"New Orders"} />
            </div>
            <div className="col-md-3">
              <Card total={shippedOrders} title={"Shipped Orders"} />
            </div>
            <div className="col-md-3">
              <Card total={cancelledOrders} title={"Cancelled Orders"} />
            </div>
            <div className="col-md-3">
              <Card total={deliveredOrders} title={"Delivered Orders"} />
            </div>
          </div>
          { user.role == 4  ? "" : 

            <div className="row">
              <div className="col-md-12 col-12">
                <LineChart salesForMonth={salesForMonth} />
              </div>
              {/* <div className="col-md-6 col-12"> */}
                {/* <Chart2 /> */}
                {/* <LineChart gift={true} salesForMonth={giftCards} /> */}
              {/* </div> */}
            </div>
          }

          { user.role == 6 || user.role == 4 
                      ? ""
                      :
                      <div className="row">
                        <div className="col-md-6">
                          <TopStores
                            selectStore={selectStore}
                            orders={orders}
                            data={topStores}
                            handleClick={handleSelectStore}
                            handleStoreName={handleStoreName}
                          />
                        </div>
                        <div className="col-md-6">
                          <DueOrders
                            data={dueDateOrders}
                            selectStore={selectStoreForDueOrders}
                            orders={orders}
                            handleClick={handleSelectStoreForDueOrders}
                            handleStoreName={handleStoreName}
                          />
                        </div>
                      </div>
          }
          
          { user.role == 6 
                      ? ""
                      :
                      <div className="row">
                        <div className="col-md-12">
                          <TopProducts
                            data={topProducts}
                            selectStore={selectStoreForTopProducts}
                            orders={orders}
                            handleClick={handleSelectStoreForTopProducts}
                            handleStoreName={handleStoreName}
                          />
                        </div>
                      </div>
          }

          { user.role == 6 
                      ? ""
                      :
                      <div className="row">
                        <div className="col-md-12">
                          <Inventory 
                            data={inventory}
                            selectStore={selectStoreForInventory}
                            orders={orders}
                            handleClick={handleSelectStoreForInventory}
                            handleStoreName={handleStoreName}
                          />
                        </div>
                      </div>
          }
          
          
        </div>
      </div>
    </>
  );
}
