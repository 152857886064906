import React from 'react'
import './style.css'

export default function TotalOrderCard({ total, title }) {
    return (
        <>
            <div className='main-inner-price-card'>
                <h2>{new Intl.NumberFormat('en-IN').format(total)}</h2>
                <p>{title}</p>
            </div>
        </>
    )
}
