import React from 'react'
import './style.css'

export default function Footer() {
  return (
    <>
        <div className='row mx-0'>
          <div className='col-12 px-0'>
            <div className='main-inner-footer'>
              <p>2023© Imprint Genius</p>
            </div>
          </div>
        </div>
    </>
  )
}
