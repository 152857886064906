import React, { Component } from "react";
import { getGiftCard } from '../../../redux/actions/giftcards/getAll';
import { connect } from 'react-redux';
import moment from "moment";
import $ from 'jquery';
import { Link } from "react-router-dom";

class GiftCards extends Component {
    state = {
        cards: [],
        gotResp: false
    }
    componentDidMount() {
        this.props.getGiftCard();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.giftCards !== this.props.giftCards) {
            if (this.props.giftCards.success) {
                console.log("this.props.giftCards", this.props.giftCards.message.data)
                this.setState({ cards: this.props.giftCards.message.data })
            }
            this.setState({ gotResp: true })
        }
    }
    handleDisable = id => {
        let disable = window.confirm("Are you sure? You won't be able to enable it again.");
        console.log("disable", disable)
    }
    render() {
        console.log("this.state.cards", this.state.cards)
        const tableStyle = {
            width: "100%",
        };
        let columns = [
            // { title: "ID", data: "id" },
            { title: "Giftcard code", data: "code" },
            { title: "Value", data: "initial_value" },
            { title: "note", data: "note" },
            { title: "Created On", data: "created_at" },
            {
                data: null,
                className: "center",
                render: function (data, type, row) {
                    return `<a href="/giftcard/` + data.id + `" class=" btn btn-primary btn-sm link" style="color:white">View</a>
                    <a href="/edit-giftcard/`+ data.id + `" class=" btn btn-warning btn-sm link" style="color:white">Edit</a>
                    <a onClick="" class=" btn btn-danger btn-sm link" style="color:white">Disable</a>`;
                }
            }
        ];
        if (!$.fn.DataTable.isDataTable('#myTable') && this.state.gotResp) {
            $('#myTable').DataTable({
                data: this.state.cards,
                columns,
                ordering: true,
            });
        }
        const addSupplierStyle = {
            background: '#48b4dd',
            color: '#fff',
            padding: '10px 10px',
            borderRadius: '3px',
            margin: '0 0 25px 0',
            display: 'inline-block',
        };
        return (
            <div>
                <Link style={addSupplierStyle} to="/create-giftcards">Create Giftcard</Link>
                <table id="myTable"  responsive="true" hover="true" className="project-table table table-striped table-bordered" style={tableStyle}>
                </table>
            </div>
        )
    }
}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        auth: state.auth,
        giftCards: state.getGiftCards
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    getGiftCard: () => getGiftCard(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);

