import React, { Component } from "react";
//import $ from 'jquery';
import DataTable from 'datatables.net';

class StoreOrders extends Component {

  state = {
    order: [],
    billing: [],
    shipping: [],
    items: [],
    customer: [],
    artfiles: [],
    orderresponse: [],
    OrderDetail: [],
    OrderDetailData: [],
    OrderDetailTypes: [],
    error: '',
    shop: ''
  }

  componentDidMount() {
    const param = {

      shop: this.props.match.params.shop,
      token: this.props.match.params.token,
      orderid: this.props.match.params.id
    }
    this.setState({ shop: param.shop })
    fetch(process.env.REACT_APP_API_URL + 'api/orderDetails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'X-Shopify-Access-Token': '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'
      },
      body: JSON.stringify(param)
    })
      .then((response) => response.json())
      .then(ordersDetails => {
        this.setState({ order: ordersDetails.order, billing: ordersDetails.order.billing_address, shipping: ordersDetails.order.shipping_address, items: ordersDetails.order.line_items, customer: ordersDetails.order.customer });
      })

    fetch(process.env.REACT_APP_API_URL + 'api/orderDetails/db', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'X-Shopify-Access-Token': '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'
      },
      body: JSON.stringify(param)
    })
      .then((response) => response.json())
      .then(ordersDetails => {
        console.log('ordersDetails aaa ::::::::::::', ordersDetails)
        // Get the list of the order products
        if (ordersDetails) {
          this.setState({
            OrderDetailData: ordersDetails[0]
          })
        }
        else {
          this.setState({ error: "Order Not found" })
        }

      });
    fetch(process.env.REACT_APP_API_URL + 'api/order/detail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(param)
    })
      .then((response) => response.json())
      .then(orderresponse => {
        let OrderDetail = [], OrderDetailTypes = [];
        // console.log("orderresponse", orderresponse)
        orderresponse.forEach(element => {
          let typeName = '';
          if (element.order_type == 'Alphabroder') {
            typeName = 'ALPHA';
          }
          else if (element.order_type == 'SanMar') {
            typeName = 'SMAR';
          }
          else if (element.order_type == 'SS') {
            typeName = 'SS';
          }
          // console.log("element.type", element.order_type, element.orderId)
          if (!OrderDetailTypes[typeName]) {
            OrderDetailTypes[typeName] = {
              type: element.order_type,
              id: element.orderId,
              status: element.order_status,
              order_tracking_number: element.order_tracking_number,
              tracking_number: element.tracking_number,
              tracking_status: element.tracking_status,
              amount: element.amount,
              currency: element.currency,
              shipment_objectId: element.shipment_objectId,
              tracking_status: element.tracking_status,
              tracking_status_details: element.tracking_status_details,
              tracking_url_provider: element.tracking_url_provider,
              label_url: element.label_url,
              error: orderresponse[0].error,
            };
          }
          OrderDetail.push({
            type: element.type,
            id: element.ss_orderid,
            status: element.ss_order_status,
            order_tracking_number: element.ss_order_tracking_number,
            tracking_number: element.tracking_number,
            tracking_status: element.tracking_status,
            amount: element.amount,
            currency: element.currency,
            shipment_objectId: element.shipment_objectId,
            tracking_status: element.tracking_status,
            tracking_status_details: element.tracking_status_details,
            tracking_url_provider: element.tracking_url_provider,
            label_url: element.label_url,
            error: orderresponse[0].error,
          })
        });
        console.log("OrderDetail", OrderDetail)

        this.setState({ OrderDetail });
        // console.log("OrderDetailTypes", OrderDetailTypes)
        this.setState({ OrderDetailTypes });
      });
    fetch(process.env.REACT_APP_API_URL + 'api/artfile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(param)
    }).then((response) => response.json())
      .then(artfiles => {

        this.setState({ artfiles: artfiles })
      });
  }

  render() {
    const { OrderDetailData } = this.state
    var data = this.state.order,
      billing = this.state.billing,
      shipping = this.state.shipping,
      customer = this.state.customer,
      items = this.state.items,
      artfiles = this.state.artfiles,
      OrderDetail = this.state.OrderDetail,
      OrderDetailTypes = this.state.OrderDetailTypes;
      
    const orderid = {
      margin: '10px 0 20px 0',
      display: 'inline-block'
    };
    return (
      <>
        {this.state.error === "" ?
          <div className="container">
            <div className="card">
              <div className="card-header bg-light py-4">
                <h4 className="mb-0">Order #{OrderDetailData.order_id}</h4>
              </div>
              <div className="card-body"  >
                <div className="row mb-5">
                  <div className="col-5 bg-secondary py-2 px-5 mx-5">
                    <h5>Order Details</h5>
                    <div>
                      <i className="fab fa-shopify"></i> Order Number:  <b>{OrderDetailData.order_number}</b>
                    </div>
                    <div>
                      <i className="fas fa-dollar-sign"></i> Total Price: <b>{OrderDetailData.total_price}</b>
                    </div>
                    <div>
                      <i className="fas fa-dollar-sign"></i> Financial Status: <b>{OrderDetailData.payment_status}</b>
                    </div>
                    <div>
                      <i className="fas fa-calendar-day"></i> Created At: <b>{OrderDetailData.created_at}</b>
                    </div>

                  </div>
                  <div className="col-6 bg-secondary py-2 px-5 border-left border-white ml-5">
                    <h5>Billing and Shipping Details</h5>
                    <div>
                      <i className="fas fa-map-marked"></i> Billing Address: <b>{billing.first_name} {billing.last_name}, {billing.address1}, {billing.city}, {billing.province}, {billing.country}, {billing.zip}</b>
                    </div>
                    <div>
                      <i className="fas fa-map-marked"></i> Shipping Address: <b>{shipping.first_name} {shipping.last_name}, {shipping.address1}, {shipping.city}, {shipping.province}, {shipping.country}, {shipping.zip}</b>
                    </div>
                  </div>
                  <div className="col-5 bg-secondary py-2 px-5 mx-5 my-5">
                    <h5>Customer Details</h5>
                    <div>
                      Name:  <b>{customer.first_name} {customer.last_name}</b>
                    </div>
                    <div>
                      Email: <b>{data.contact_email}</b>
                    </div>
                    <div>
                      Phone: <b>{customer.phone}</b>
                    </div>

                  </div>

                  {this.state.shop == "flockandfeathers.myshopify.com" ?
                    <div className="col-6 bg-secondary py-2 px-5 border-left border-white ml-5 my-5">
                      <h5>{this.state.shop != "flockandfeathers.myshopify.com" ? "S&S/SanMar/Alphabroder order details" : "S&S order details"}</h5>
                      {OrderDetail ?
                        OrderDetail.map((itm, key) => (
                          itm.id ?
                            <div key={key} className="mt-3">
                              <div>
                                {itm.type} Order Id: <b>{itm.id}</b>
                              </div>
                              <div>
                                {itm.type} Order Status: <b>{itm.status}</b>
                              </div>
                              <div>
                                {itm.type} Tracking Number:  <b>{itm.order_tracking_number || ""}</b>
                              </div>
                            </div>
                            : <div key={key}>Order is not placed yet. {OrderDetail.error ? OrderDetail.error : ""} </div>
                        ))
                        :
                        "Order is not placed yet. "
                      }
                    </div>
                    : ""}
                  {this.state.shop == "flockandfeathers.myshopify.com" ?
                    <div className="col-5 bg-secondary py-2 px-5 mx-5 mb-5">
                      <h5>Shippo Details</h5>
                      {(OrderDetail.tracking_number != undefined && OrderDetail.tracking_number != "") ?
                        <div>
                          <div>
                            Tracking Number:  <b>{OrderDetail.tracking_number || ""}</b>
                          </div>
                          <div>
                            Shipment ID: <b>{OrderDetail.shipment_objectId || ""}</b>
                          </div>
                          <div>
                            Tracking Status: <b>{OrderDetail.tracking_status || ""}</b>
                          </div>
                          <div>
                            Tracking Status Details: <b>{OrderDetail.tracking_status_details || ""}</b>
                          </div>
                          <div>
                            Shipping Amount: <b>{OrderDetail.currency || ""} {OrderDetail.amount || ""}</b>
                          </div>
                          <div>
                            Tracking URL: <b><a href={OrderDetail.tracking_url_provider || ""} target="_blank" >{OrderDetail.tracking_url_provider || ""}</a></b>
                          </div>
                          <div>
                            Label URL: <b><a href={OrderDetail.label_url || ""} target="_blank">{OrderDetail.label_url || ""}</a></b>
                          </div>
                        </div>
                        : <div>Order is not placed yet.</div>
                      }

                    </div>
                    : ""}
                    {OrderDetailData.error && OrderDetailData.error !== '' && OrderDetailData.error !== null &&
                      <div className="col-6 bg-danger py-2 px-5 mx-5 my-5">
                        <h5>Order error</h5>
                        <div>
                          Error:  <b>{OrderDetailData.error}</b>
                        </div>
                      </div>
                    }
                </div>
                <div className="row mt-5 table-responsive border">
                  <table className="table">
                    <thead>
                      {this.state.shop == "flockandfeathers.myshopify.com" ?
                        <tr>
                          <th scope="col">Product ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                        </tr>
                        :
                        <tr>
                          <th scope="col">Product ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Order Number</th>
                          <th scope="col">Order Status</th>
                          <th scope="col">Tracking Number</th>
                          <th scope="col">Type</th>
                        </tr>
                      }
                    </thead>
                    <tbody>

                      {items.map((item, i) => (
                        this.state.shop == "flockandfeathers.myshopify.com" ?
                          <tr key={i}>
                            <td>{item.product_id}</td>
                            <td>{item.title}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                          </tr>
                          :
                          <tr key={i}>
                            <td>{item.product_id}</td>
                            <td>{item.title}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{OrderDetailTypes[item.sku.split('-')[1]] ? OrderDetailTypes[item.sku.split('-')[1]].id : ""}</td>
                            <td>{OrderDetailTypes[item.sku.split('-')[1]] ? OrderDetailTypes[item.sku.split('-')[1]].status : ""}</td>
                            <td>{OrderDetailTypes[item.sku.split('-')[1]] ? OrderDetailTypes[item.sku.split('-')[1]].order_tracking_number : ""}</td>
                            <td>{OrderDetailTypes[item.sku.split('-')[1]] ? OrderDetailTypes[item.sku.split('-')[1]].type : ""}</td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          : <h3 className="text-center">{this.state.error}</h3>}
      </>
    )
  }

}

export default StoreOrders;