import React from 'react'
import { months } from '../utils'
import { Line } from 'react-chartjs-2';
import './style.css'
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);


export default function data() {
  const labels = months({ count: 10 });
  const data = {
    labels: labels,
    datasets: [{
      label: '',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 , 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 , 0, 0, 0 ],
      fill: true,
      borderColor: '#11A6EE',
      backgroundColor: ['rgba(17, 166, 238, 0.22)' , 'rgba(17, 166, 238, 0)'],
      tension: 0.1,
    }]
  };
  let options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        }
      },
      y: {
        grid: {
          color: "#DEEAF2",
          borderDash: [5, 5],
          borderDashOffset: 2,
          // display: false
        },
        ticks: {
          callback: function(label) {
            return `\$${this.getLabelForValue(label)}`
          },
          stepSize: 20
        }
      }
    }
  }
  return (
    <div className='main-inner-chart'>
      <h2>Sales Using Gift Cards</h2>
      <h3>$0</h3>
      <p>Total Sales using Gift cards this month</p>
      <div className="App">
        <Line data={data} options={options} />
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          <div className='view-all-tabl-btn'>
            <button>View All</button>
          </div>
        </div>
      </div>
    </div>
  )

}
