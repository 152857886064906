import React from 'react'
import './style.css'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Spinner } from 'react-bootstrap'
import { shallowEqual, useSelector } from "react-redux";

export default function Inventory({ data, handleClick, orders, selectStore,handleStoreName }) {
    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
      );
    return (
        <>
            <div className='main-inner-top-pro-tbl'>
                <div className='main-inner-left-table'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='main-nr-tbl'>
                                <h4>Inventory</h4>
                            </div>
                        </div>
                        {user.role == 4 ? '' :
                            <div className='col-md-6'>
                                <div className='main-nr-tbl2'>
                                    <p>Sort By</p>
                                    <select onChange={(e) => handleClick(e.target.value)}>
                                        <option className={`${selectStore == 'all' && 'active' }`} name="all" value="all" >All Stores</option>
                                        {orders && orders.length > 0 && orders.map((itm, idx) => {
                                            return <option key={idx} className={`${selectStore == itm.store && 'active' }`} name={itm.store} value={itm.store}>{handleStoreName(itm.store)}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='main-fill-scroll'>
                        <div className='main-inr-tbl-data main-ursor-scrol'>
                            <table>
                                <tr className='none-bodr'>
                                    <th>Product SKU</th>
                                    <th>Product</th>
                                    <th>Date Added</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>Quantity</th>
                                </tr>
                                <tbody>
                                    {data !== null ?
                                        data.length > 0 ?
                                            data.map((itm, idx) => {
                                                // console.log('itm :::', itm)
                                                return (
                                                    <tr key={idx}>
                                                        <td>{itm.sku}</td>
                                                        <td><img src={toAbsoluteUrl(itm.image_src != null &&  itm.image_src != '' ? itm.image_src : 'media/rectangle80.svg')}></img>{itm.title}</td>
                                                        <td>{itm.created_at}</td>
                                                        <td>{itm.price}</td>
                                                        <td>
                                                            {itm.inventory_quantity > 50 ?                                         
                                                                <button className='shipped'>In Stock</button>
                                                                :
                                                                  itm.inventory_quantity > 0 ?
                                                                    <button className='warning'>Low Stock</button>
                                                                  :
                                                                    <button className='danger'>Out of Stock</button>
                                                            }
                                                        </td>
                                                        <td>{itm.inventory_quantity}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="6" rowSpan="4" className='text-center'>No Data Found</td>
                                            </tr>
                                        :
                                        <tr>
                                            <td colSpan="6" rowSpan="4" className='text-center'>
                                                <Spinner animation="border" variant="primary" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='view-all-tabl-btn'>
                                <button>View All</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}
