import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';

class DataTables extends Component {
  state = {
    shops: []
  }

  componentDidMount() {
    fetch('http://157.245.80.15:3000/api/shops')
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ shops: booksList });
      });

  }

  render() {
    $('#myTable').DataTable({
      data: this.state.shops,
      columns: [
        { title: "ID", data: "id" },
        { title: "Shop Name", data: "shop_name" },
        { title: "Access Token", data: "access_token" },
        { title: "Status", data: "status" },
        { title: "Created On", data: "created_at" }
      ],
      ordering: false
    });
    const table = {
      width: "100%",
    };

    return (
      <table id="myTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>

      </table>

    )
  }

}

export default DataTables;