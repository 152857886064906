import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';


class Suppliers extends Component {
  state = {
        files: []
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL+'api/artfiles')
        .then((response) => response.json())
        .then(booksList => {
            this.setState({ files: booksList });
        });
    }
    
    render() {
      $('#myTable').DataTable({
           data: this.state.files,
           columns: [
                      { title: "ID", data: "id" },
                      { title: "Order ID", data: "order_id" },
                      { title: "Artfile Name", data: "artfile_name" },
                      { title: "Artfile", data: "artfile" },
                      { title: "Status", data: "status" },
                      { title: "Created On", data: "created_at" }
                    ],
           ordering: true
        });
      const table ={
         width: "100%",
      };    
      const addSupplier = {
               background: '#48b4dd',
               color: '#fff',
               padding: '10px 10px',
               borderRadius: '3px',
               margin: '0 0 25px 0',
               display: 'inline-block',
          };
        return (
          

         <div>
         <table id="myTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>
           
          </table> 
         </div>
        )
    }

}

export default Suppliers;