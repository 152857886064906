import * as Actions from '../actions/addManager';
export const CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS";
export const CREATE_MANAGER_FAILURE = "CREATE_MANAGER_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CREATE_MANAGER_SUCCESS:
            return {
                ...state,
                success: true,
                error: 'success',
                message: action.message,
            };
        case Actions.CREATE_MANAGER_FAILURE:
            return {
                ...state,
                success: false,
                error: 'error',
                message: action.message
            };
        default:
            return state;
    }
};

export default reducer;
