import React from 'react';
import './Modal.css';
class shippoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleSubmit = () => {
        var height = document.getElementById('heightProduct').value;
        var width = document.getElementById('widthProduct').value;
        var length = document.getElementById('lengthProduct').value;
        var weight = document.getElementById('weightProduct').value;
        var param = { order_id: this.props.obj.order_id, height, width, length, weight };
        if (height === "") {
            document.getElementById('heightProduct').style.borderColor = "red";
        }
        if (width === "") {
            document.getElementById('widthProduct').style.borderColor = "red";
        }
        if (length === "") {
            document.getElementById('lengthProduct').style.borderColor = "red";
        }
        if (weight === "") {
            document.getElementById('lengthProduct').style.borderColor = "red";
        }
        if (height !== "" && width !== "" && length !== "" && weight !== "") {
            fetch(process.env.REACT_APP_API_URL + 'api/supplier/dimensions/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-Shopify-Access-Token': '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'
                },
                body: JSON.stringify(param)
            })
                .then((response) => response.json())
                .then(resp => {
                    console.log(resp);
                    this.props.createLabel(this.props.obj.order_id);

                });
        }
    }
    handleChange = (event) => {
        console.log("event", event.target.name, event.target.value)
        this.props.obj[event.target.name] = event.target.value;
        console.log("ehght", this.props.obj.height)
    }
    render() {
        console.log("this.props.obj.width", this.props.obj.width)
        var height = this.props.obj.height !== undefined && this.props.obj.height !== "" ? this.props.obj.height : ''
        var width = this.props.obj.width !== undefined && this.props.obj.width !== "" ? this.props.obj.width : ''
        var length = this.props.obj.length !== undefined && this.props.obj.length !== "" ? this.props.obj.length : ''
        var weight = this.props.obj.weight !== undefined && this.props.obj.weight !== "" ? this.props.obj.weight : ''
        return (
            <div className="main-model" >
                <div className="modal-wrapper"
                    style={{
                        transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0',
                    }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Create Label</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.close}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                            <label>Length</label>
                                            <input type="number" id="lengthProduct" className="form-control" onChange={this.props.handleChange} name="length" value={length} />
                                        </div>
                                        <div className="col-12">
                                            <label>Width</label>
                                            <input type="number" id="widthProduct" className="form-control" onChange={this.props.handleChange} name="width" value={width} />
                                        </div>
                                        <div className="col-12">
                                            <label>Height</label>
                                            <input step=".01" type="number" id="heightProduct" onChange={this.props.handleChange} className="form-control" name="height" value={height} />
                                        </div>
                                        <div className="col-12">
                                            <label>Weight</label>
                                            <input step=".01" type="number" id="weightProduct" onChange={this.props.handleChange} className="form-control" name="weight" value={weight} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="closeBtn" className="btn btn-warning" onClick={this.props.close}>CLOSE</button>
                                <button onClick={this.handleSubmit} disabled={this.state.submitted} id="continueBtn" className="btn btn-success">Create</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default shippoModal;