import React, { Component } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import DataTable from 'datatables.net';

class managers extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    users: []
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + 'api/managers')
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ users: booksList });
      });
  }


  render() {
    $('#btnDelete').unbind('click');
    $(document).on('click', '#btnDelete', function (e) {
      e.preventDefault();
      var id = $(this).attr('data-id');
      // let r = window.confirm("Are you sure want to delete this manager?");
      // if (r == true) {
      fetch(process.env.REACT_APP_API_URL + 'api/deletesupplier/' + id, { method: 'DELETE' }).then(() => {
        window.location.reload(false);
      });
      // }
    });
    if (!$.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable({
        data: this.state.users,
        columns: [
          { title: "ID", data: "id" },
          { title: "Store Manager Name", data: "name" },
          { title: "Email", data: "email" },
          { title: "Location", data: "location" },
          { title: "Status", data: "status" },
          { title: "Created On", data: "created_at" },
          {
            data: null,
            className: "center",
            render: function (data, type, row) {
              return `<a href="/editmanager/${data.id}" id="gotToThisPage" class="btn btn-info btn-sm link" style="color:white"><i class="fas fa-edit"></i></a>
                            <span class=" btn btn-info btn-sm link deletesupplier" style="color:white" data-id="`+ data.id + `" id='btnDelete'><i class="fas fa-trash-alt"></i></span>`;
            }
          }
        ],
        ordering: true,
        "order": [[ 0, "desc" ]]
      });
    }
    const table = {
      width: "100%",
    };
    const addSupplier = {
      background: '#48b4dd',
      color: '#fff',
      padding: '10px 10px',
      borderRadius: '3px',
      margin: '0 0 25px 0',
      display: 'inline-block',
    };
    return (


      <div>
        <Link style={addSupplier} to="/addManager">Add Manager</Link>
        <table id="myTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>

        </table>
      </div>
    )
  }

}

export default managers;

