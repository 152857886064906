import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import Login from "./reducers/Login";
import SupplierOrder from "./reducers/SupplierOrders";
import SupplierSetting from "./reducers/SupplierSetting";
import ShipOrder from "./reducers/ShipOrder";
import ShipStationOrders from "./reducers/ShipStationOrders";
import ShippingDetails from "./reducers/ShippingDetails";
import GetShops from "./reducers/GetShops";
import AddManager from "./reducers/AddManager";
import ManagerOrders from "./reducers/ManagerOrders";
import PrinterOrders from "./reducers/PrinterOrders";
import CreateGiftCard from "./reducers/gift-cards/CreateGiftCard";
import getGiftCards from "./reducers/gift-cards/getGiftCards";
import getGiftCard from "./reducers/gift-cards/getGiftCard";
import EditGiftCard from "./reducers/gift-cards/EditGiftCard";

import getRunningCrons from "./reducers/runningCrons";
import getCronLogs from "./reducers/cronLogs";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  login: Login,
  SupplierOrder,
  SupplierSetting,
  ShipOrder,
  ShipStationOrders,
  ShippingDetails,
  GetShops,
  AddManager,
  ManagerOrders,
  PrinterOrders,
  CreateGiftCard, getGiftCards, getGiftCard, EditGiftCard,
  getRunningCrons,
  getCronLogs
})

export function* rootSaga() {
  yield all([auth.saga()]);
}
