import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login, getUserByToken } from "../_redux/authCrud";
import './style.css'
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data }) => {
            // localStorage.setItem('token', authToken);
            // disableLoading();
            const authToken = data.authToken;
            console.log(props);
            props.login(authToken);
          })
          .catch((err) => {
            console.log("err", err);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  return (
    <>
      <div className="main-inner-login-page">
        <div className="main-left-side-logo">
          <img src={toAbsoluteUrl('/media/image1.svg')}></img>
          <h2>Welcome To Imprint Genius!</h2>
          <div className="inr-footer-login">
            <p>© 2023 ImprintGenius</p>
          </div>
        </div>
        <div className="main-right-side-fields">
          <div className="main-inner-form-login">
            <form
              onSubmit={formik.handleSubmit}
              // className="form fv-plugins-bootstrap fv-plugins-framework"
            > 
              {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              ) : (
                ""
              )}
              <h2>Sign In to your Account</h2>
              <div className="main-usr">
                {/* <input type="text" placeholder={"Enter Username"}></input> */}
                <input
                  placeholder="Enter Username"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="main-usr">
                {/* <input type="text" placeholder={"Enter Password"}></input> */}
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                ) : null}
                <div className="forget">
                  <Link
                    to="/auth/forgot-password"
                    className="text-dark-50 text-hover-primary my-3 mr-2"
                    id="kt_login_forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>
              </div>
              <div className="main-usr-submit text-center">
                <button>Sign In</button>
                <div className="my-2">
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
