import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';

class StoresPage extends Component {
  state = {
    shops: []
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + 'api/shops')
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ shops: booksList });
      });
  }

  render() {
    console.log("this.state.shops",this.state.shops)
    let columns = [
      // { title: "ID", data: "id" },
      { title: "Shop Name", data: "shop_name" },
      { title: "Access Token", data: "access_token" },
      { title: "Status", data: "status" },
      { title: "Created On", data: "created_at" },
      {
        data: null,
        className: "center",
        render: function (data, type, row) {
          if(data.shop_name == "flockandfeathers.myshopify.com"){
          return `<a href="/StoreOrders/${data.shop_name}/${data.access_token}" class=" btn btn-info btn-sm link" style="color:white">Shop Orders</a>`;
          }
          else{
            return `<a href="/StoreOrdersSwag/${data.shop_name}/${data.access_token}" class="m-1 btn btn-info btn-sm link" style="color:white">Shop Orders</a>
            <a href="/demanded-products/${data.shop_name}/" class="m-1 btn btn-primary btn-sm link" style="color:white">Products in Demand</a>`;
          }
        }
      }
    ];
    if (!$.fn.DataTable.isDataTable('#myTable')) {
      $('#myTable').DataTable({
        data: this.state.shops,
        columns,
        ordering: true,
      });
    }
    const table = {
      width: "100%",
    };

    return (
      <div>
        <table id="myTable" responsive="true" hover="true" className="project-table table table-striped table-bordered" style={table}>

        </table>
      </div>
    )
  }

}

export default StoresPage;