import React from 'react'
import './table-style2.css'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function DueOrders({ data, handleClick, orders, selectStore, handleStoreName }) {
    return (
        <>
            <div className='main-inner-left-table'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <div className='main-nr-tbl'>
                            <h4>Order Reaching Due Date</h4>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='main-nr-tbl2'>
                            <p>Sort By</p>
                            <select onChange={(e) => handleClick(e.target.value)}>
                                <option className={`${selectStore == 'all' && 'active' }`} name="all" value="all" >All Stores</option>
                                {orders && orders.length > 0 && orders.map((itm, idx) => {
                                    return <option key={idx} className={`${selectStore == itm.store && 'active' }`} name={itm.store} value={itm.store}>{handleStoreName(itm.store)}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='main-fill-scroll'>
                <div className='main-inr-tbl-data main-ursor-scrol'>
                    <table>
                        <tr className='none-bodr'>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Due Date</th>
                        </tr>
                        {data !== null ?
                            data.length > 0 ?
                                data.map((itm, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{itm.order_no}</td>
                                            <td>{itm.date}</td>
                                            <td>{itm.name}</td>
                                            <td>{itm.due_date}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="4" rowSpan="4" className='text-center'>No Data Found</td>
                                </tr>
                            :
                            <tr>
                                <td colSpan="4" rowSpan="4" className='text-center'>
                                    <Spinner animation="border" variant="primary" />
                                </td>
                            </tr>
                        }
                    </table>
                </div>
                </div>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <div className='view-all-tabl-btn'>
                            <Link to="/all-orders">View All</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
