import React, { useEffect, useState } from "react";
import InventoryClientTable from '../../components/InventoryClientTable/index';
import { shallowEqual, useSelector } from "react-redux";
import BreadCrumb from '../../components/breadCrumb';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
// import './style.css';

export default function Stores() {
    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
    );
    if (user.role == undefined) {
        window.location.reload();
    }


    const { search } = useLocation()
    let store = search && search.split("=")[1] || 'all'
    
    // const [inventory, setInventory] = useState(null);
    // const [selectDashboardStore, setSelectDashboardStore] = useState("all");
    // const [filterDate, setFilterDate] = useState("");

    // useEffect(() => {
    //     if (filterDate || inventory == null) {
    //         let url = `${process.env.REACT_APP_API_URL}api/client/demanded-products/${user.store_name}`;
    //         fetch(url, { method: "get", headers: { "Content-Type": "application/json", }, })
    //           .then((response) => response.json())
    //           .then((resp) => {
    //             let finalData = resp;
    //             let flatData = finalData.flat();
    //             setInventory(flatData.flat())
    //           });
    //       }
    //   }, [selectDashboardStore]);
    
    //   console.log("inventory >>>>", inventory)
    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6 px-0'>
                            <BreadCrumb type="inventory"/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-all-str-tbl'>
                                <InventoryClientTable 
                                    store={store}
                                    roleId={user.role} 
                                    userInfo = {user}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
