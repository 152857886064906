import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Paymentcard from '../../components/paymentDetail/index'
import Steps from '../../components/steps/index'
import Shipingdetail from '../../components/shippingDetails/index'
import Product from '../../components/Products/index'
import BreadCrumb from '../../components/breadCrumb';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import {Link} from "react-router-dom";


export default function Stores({}) {
    const [orderDetail, setOrderDetail] = useState([])
    const [loading, setLoading] = useState(true)
    const [lineItems, setLineItems] = useState([])
    const params = useParams()
    const  { orderId, store } = params
    const backBtnUrl = `/all-orders?store=${store}`
    useEffect(() => {
        let url = `${process.env.REACT_APP_API_URL}api/orderDetail/${store}/${orderId}`
        fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then(resp => {
                const lineItems = JSON.parse(resp[0].line_items)
                setLineItems(lineItems)
                setOrderDetail(resp)
                setLoading(false)
            });
    }, [])

    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-8 px-0'>
                            <BreadCrumb type="order_details"/>
                        </div>
                        <div className='col-md-4 px-0'>
                            <div class="ad-stor-btn">
                                <Link to={backBtnUrl} class="btn" style={{background: "#FBCC64"}}> Back </Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <Steps orderDetail={orderDetail} lineItems={lineItems} />
                    </div>
                    <div className='row'>
                        <div className='col-md-6 '>
                            <div className='main-all-str-tbl'>
                                <Paymentcard orderDetail={orderDetail}/>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='shiping-details'>
                                <Shipingdetail orderDetail={orderDetail}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Product orderDetail={orderDetail} type="apparel"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
