import React, { Component } from "react";
import { createGiftCard } from '../../../redux/actions/giftcards/create';
import { connect } from 'react-redux';

class CreateGiftCard extends Component {
    state = {
        users: [],
        errorMsg: ''
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.create !== prevProps.create) {
            if (this.props.create.success) {
                const { history: { push } } = this.props;
                push('/gift-cards');
            }
        }
    }
    handleCreateGiftCard = e => {
        e.preventDefault();
        let code = e.target.code.value,
            initial_value = e.target.initial_value.value,
            note = e.target.note.value,
            errorMsg = '';
        if (code === "" || initial_value === "" || note === "") {
            errorMsg = "Please fill all required fields";
        }
        else {
            this.props.createGiftCard(code, initial_value, note);
        }
        this.setState({ errorMsg });
    }
    render() {
        return (
            <div>
                <form onSubmit={this.handleCreateGiftCard} className="createGiftCard">
                    <h2>Create Giftcard</h2>
                    <div className="row mt-3">
                        <div className="col-8">
                            <div className={this.state.errorMsg !== "" ? "p-5 my-5 bg-danger text-white" : "d-none"}>{this.state.errorMsg}</div>
                            <div className="form-group">
                                <label htmlFor="code">Giftcard Code<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="code" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="initial_value">Initial Value<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="initial_value" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="note">Note<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="note" />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Create
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => ( // getting response  of api and loading in props
    {
        auth: state.auth,
        create: state.CreateGiftCard
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    createGiftCard: (code, initial_value, note) => createGiftCard(code, initial_value, note, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateGiftCard);

