import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import './style.css'

export default function PaymentDetail({ orderDetail }) {
    const [order, setOrder] = useState({
        name: '',
        price: '',
        discount: '',
        phone: '',
        order_id: '',
        total_price: '',
        payment_status: '',
        created_at: '',
        date: '',
        user_email: ''
    })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(loading && orderDetail && orderDetail.length > 0 ){
            orderDetail.map((itm, idx) => {
                const { payment_status, order_id, date, total_price, user_email, created_at, processed_on, order_number } = itm
                const shippingAddress = JSON.parse(itm.shipping_address)
                const lineItems = JSON.parse(itm.line_items)
                let discount = 0
                let price = 0
                lineItems.map(line => {
                    discount = parseFloat(discount) + parseFloat(line.total_discount)
                    price = parseFloat(price) + parseFloat(line.price)
                })
                discount = discount.toFixed(2)
                price = price.toFixed(2)

                setOrder({
                    discount,
                    price,
                    name: shippingAddress.name,
                    phone: shippingAddress.phone,
                    order_number,
                    total_price,
                    order_id,
                    payment_status,
                    created_at,
                    date,
                    user_email
                })
                setLoading(false)
            })
        }
    })
    return (
        <>
            <div className='main-inner-left-table'>
                <div className='main-fill-scroll'>
                    <div className='payment-detail main-ursor-scrol'>
                        {!loading ?
                            <table>
                                <tr className='none-bodr'>
                                    <th>{order.name}</th>
                                    <th><p className='decsress'>Payment Details</p></th>
                                    <th><span className='green'>{order.payment_status}</span></th>
                                </tr>
                                <tr>
                                    <td>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.43281 4.04648L4.30488 2.17617C4.43694 2.04353 4.5939 1.93829 4.76675 1.8665C4.93961 1.7947 5.12494 1.75777 5.31211 1.75781C5.69355 1.75781 6.05215 1.90723 6.32109 2.17617L8.33555 4.19062C8.46818 4.32268 8.57342 4.47965 8.64522 4.6525C8.71701 4.82535 8.75395 5.01068 8.75391 5.19785C8.75391 5.5793 8.60449 5.93789 8.33555 6.20684L6.8625 7.67988C7.20731 8.43989 7.6867 9.13129 8.27754 9.7207C8.8669 10.313 9.55823 10.7941 10.3184 11.141L11.7914 9.66797C11.9235 9.53533 12.0804 9.43009 12.2533 9.3583C12.4261 9.2865 12.6115 9.24956 12.7986 9.24961C13.1801 9.24961 13.5387 9.39902 13.8076 9.66797L15.8238 11.6807C15.9566 11.813 16.062 11.9702 16.1338 12.1434C16.2056 12.3165 16.2424 12.5022 16.2422 12.6896C16.2422 13.0711 16.0928 13.4297 15.8238 13.6986L13.9553 15.5672C13.5264 15.9979 12.934 16.2422 12.3258 16.2422C12.1975 16.2422 12.0744 16.2316 11.9531 16.2105C9.58359 15.8203 7.2334 14.56 5.33672 12.665C3.4418 10.7719 2.1832 8.42344 1.78769 6.04688C1.66816 5.3209 1.90898 4.57383 2.43281 4.04648Z" fill="#012C9F" />
                                        </svg>
                                        {/* +1 */}
                                        {order.phone}</td>
                                    <td>Credit Card</td>
                                    <td>${order.price}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 0H2C1.60218 0 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V10.5C0.5 10.8978 0.658035 11.2794 0.93934 11.5607C1.22064 11.842 1.60218 12 2 12H14C14.3978 12 14.7794 11.842 15.0607 11.5607C15.342 11.2794 15.5 10.8978 15.5 10.5V1.5C15.5 1.10218 15.342 0.720644 15.0607 0.43934C14.7794 0.158035 14.3978 0 14 0ZM14 3.525L8 7.5255L2 3.525V1.72275L8 5.7225L14 1.72275V3.525Z" fill="#012C9F" />
                                        </svg>
                                        {order.user_email}</td>
                                    <td>Gift Card</td>
                                    <td>$0</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.41699 2.125H15.5837V4.95833H1.41699V2.125ZM13.4587 5.66667H2.12533V13.4583C2.12533 13.8341 2.27458 14.1944 2.54026 14.4601C2.80593 14.7257 3.16627 14.875 3.54199 14.875H13.4587C13.8344 14.875 14.1947 14.7257 14.4604 14.4601C14.7261 14.1944 14.8753 13.8341 14.8753 13.4583V5.66667H13.4587ZM11.3337 9.91667H5.66699V8.5H11.3337V9.91667Z" fill="#012C9F" />
                                        </svg>
                                        OrderID: {order.order_id}</td>
                                    <td>Discount Code</td>
                                    <td>${order.discount}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.41699 13.4583C1.41699 14.6625 2.33783 15.5833 3.54199 15.5833H13.4587C14.6628 15.5833 15.5837 14.6625 15.5837 13.4583V7.79166H1.41699V13.4583ZM13.4587 2.83333H12.042V2.125C12.042 1.7 11.7587 1.41666 11.3337 1.41666C10.9087 1.41666 10.6253 1.7 10.6253 2.125V2.83333H6.37533V2.125C6.37533 1.7 6.09199 1.41666 5.66699 1.41666C5.24199 1.41666 4.95866 1.7 4.95866 2.125V2.83333H3.54199C2.33783 2.83333 1.41699 3.75416 1.41699 4.95833V6.375H15.5837V4.95833C15.5837 3.75416 14.6628 2.83333 13.4587 2.83333Z" fill="#012C9F" />
                                        </svg>
                                        {order.date}
                                    </td>
                                    <td>Sub Total</td>
                                    <td>${order.total_price}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.41699 2.125H15.5837V4.95833H1.41699V2.125ZM13.4587 5.66667H2.12533V13.4583C2.12533 13.8341 2.27458 14.1944 2.54026 14.4601C2.80593 14.7257 3.16627 14.875 3.54199 14.875H13.4587C13.8344 14.875 14.1947 14.7257 14.4604 14.4601C14.7261 14.1944 14.8753 13.8341 14.8753 13.4583V5.66667H13.4587ZM11.3337 9.91667H5.66699V8.5H11.3337V9.91667Z" fill="#012C9F" />
                                        </svg>
                                        Order No: {order.order_number}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    {/* <td>Sub Total</td>
                                    <td>${order.total_price}</td> */}
                                </tr>
                            </table>
                            :
                            <div className='text-center'>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
