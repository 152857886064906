import * as Actions from '../actions/supplier/shippingDetails';
export const SUPPLIER_ORDER_DETAIL_SUCCESS = "SUPPLIER_ORDER_DETAIL_SUCCESS";
export const SUPPLIER_ORDER_DETAIL_FAILURE = "SUPPLIER_ORDER_DETAIL_FAILURE";

const initialState = {
    error: false,
    message: "",
    success: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SUPPLIER_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                success: true,
                error: 'success',
                message: action.message,
            };
        case Actions.SUPPLIER_ORDER_DETAIL_FAILURE:

            return {
                ...state,
                success: false,
                error: 'error',
                message: action.message
            };
        default:
            return state;
    }
};

export default reducer;
