import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';
import './style.css'

export default class OrderTable extends Component {
    state = {
        orders: [],
        orders_duplicate: [],
        loading : true,
        sortBy: 'all'
    }   
    componentDidMount() {
        const { selectedTab, roleId } = this.props 
        const the = this
        setTimeout(function () {
            the.setState({
                loading: false
            })
        },50)
    }

    handleClick = (id) => {
        window.location.href = '/order-details' ;
    }
    handleSortBy = (val) => {
        this.setState({
            sortBy: val
        })
    }
    render() {
        const { orders, loading, sortBy } = this.state
        const { selectedTab, roleId, filterDate, pastOrder, store, userInfo } = this.props

        let columns = [
            { title: "Orders", data: "order_number" },
            { title: "Store", data: "store" },
            { title: "Date", data: "date" },
            { title: "Customer", data: "customer" },
            { title: "Decorator", data: "decorator" },
            { title: "Total", data: "line_total" },
            { title: "Order Assigned To", data: "order_type" },
            { title: "Payment Status", data: "payment_status" },
            { title: "Fulfillment Status", data: "order_status" },
            { title: "Items", data: "items" }
        ];
        if (!$.fn.DataTable.isDataTable('#order-table')) {
            $('#order-table').DataTable({
                processing: true,
                serverSide: true,
                // data: orders,
                ajax: `${process.env.REACT_APP_API_URL}api/orders/${store}/${selectedTab}/${roleId}/${sortBy}/${filterDate || 'all'}/${pastOrder}?store_name=${userInfo.store_name}`,
                columns,
                sDom: 'Rfrtlip',
                order:  [ 0, 'desc' ],
                paging: true,
                // ordering: false,
                language: {
                    paginate: {
                      next: `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="26" height="26" rx="13" fill="#E6E6E6" />
                        <path d="M10.639 8.62475C10.5101 8.75368 10.4377 8.92851 10.4377 9.11082C10.4377 9.29312 10.5101 9.46795 10.639 9.59688L14.0422 13L10.639 16.4031C10.5138 16.5328 10.4445 16.7065 10.4461 16.8867C10.4476 17.067 10.5199 17.2394 10.6474 17.3669C10.7749 17.4943 10.9473 17.5666 11.1276 17.5682C11.3078 17.5698 11.4815 17.5005 11.6112 17.3753L15.5003 13.4861C15.6292 13.3571 15.7016 13.1823 15.7016 13C15.7016 12.8177 15.6292 12.6429 15.5003 12.5139L11.6112 8.62475C11.4822 8.49587 11.3074 8.42346 11.1251 8.42346C10.9428 8.42346 10.768 8.49587 10.639 8.62475Z" fill="#4C4C4C" />
                      </svg>`, // or '>'
                      previous: `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="26" height="26" rx="13" fill="#E6E6E6" />
                        <path d="M14.361 8.62475C14.4899 8.75368 14.5623 8.92851 14.5623 9.11082C14.5623 9.29312 14.4899 9.46795 14.361 9.59688L10.9578 13L14.361 16.4031C14.4862 16.5328 14.5555 16.7065 14.5539 16.8867C14.5524 17.067 14.4801 17.2394 14.3526 17.3669C14.2251 17.4943 14.0527 17.5666 13.8724 17.5682C13.6922 17.5698 13.5185 17.5005 13.3888 17.3753L9.49965 13.4861C9.37077 13.3571 9.29836 13.1823 9.29836 13C9.29836 12.8177 9.37077 12.6429 9.49965 12.5139L13.3888 8.62475C13.5178 8.49587 13.6926 8.42346 13.8749 8.42346C14.0572 8.42346 14.232 8.49587 14.361 8.62475Z" fill="#4C4C4C" />
                      </svg>` // or '<' 
                    },
                },
                fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    // $(nRow).click(function() {
                    //     // redirect
                    //     // alert(aData.non_apparel)
                    //     document.location.href = `/order-details/${aData.store}/${aData.order_id}`;
                        
                    // });
                },
                columnDefs: [
                    // {
                    //     target: 1,
                    //     visible: false,
                    // },
                    {
                        targets: [3,4,5,6,7,8,9],
                        orderable: false,
                    },
                    {
                        targets: [0],
                        createdCell: function(td, cellData, rowData, row, col) {
                            let link = `/order-details/${rowData.store}/${rowData.order_id}`
                            $(td).html(`<a href="${link}" class="order_link" target="_blank">#${cellData}</a>`);
                        },
                        orderSequence: ['desc', 'asc']
                    },
                    {
                        targets: [1],
                        render: function (data, type, row, meta){
                            if(roleId!= 6){
                                return row.store
                            }else{
                                return $('#order-table').DataTable().columns([1]).visible(false);
                            }
                        },
                        orderSequence: ['desc', 'asc']
                    },
                    {
                        targets: [2],
                        orderSequence: ['desc', 'asc']
                    },
                    {
                        targets: [7],
                        createdCell: function(td, cellData, rowData, row, col) {
                            switch(cellData) {
                                case "paid":
                                    $(td).html(`<span class="td-status shipped">${cellData}</span>`);
                                    break;
                                case "unpaid":
                                    $(td).html(`<span class="td-status warning">${cellData}</span>`);
                                    break;
                                default :
                                    $(td).html(`<span class="td-status">${cellData}</span>`);
                                    break;
                            }
                        }
                    },
                    {
                        targets: [8],
                        createdCell: function(td, cellData, rowData, row, col) {
                            switch(cellData) {
                                case "Delivered":
                                    $(td).html(`<span class="td-status shipped">${cellData}</span>`);
                                    break;
                                case "Shipped":
                                    $(td).html(`<span class="td-status warning">${cellData}</span>`);
                                    break;
                                case "Complete":
                                    $(td).html(`<span class="td-status warning">${cellData}</span>`);
                                    break;
                                case "In Progress":
                                    $(td).html(`<span class="td-status warning">${cellData}</span>`);
                                    break;
                                case "Order Placed":
                                    $(td).html(`<span class="td-status warning">In Progress</span>`);
                                    break;
                                case "Cancelled":
                                    $(td).html(`<span class="td-status danger">${cellData}</span>`);
                                    break;
                                default :
                                    $(td).html(`<span class="td-status">${cellData}</span>`);
                                    break;
                            }
                        }
                    }
                ],
            });
        }
        $('#order-table').DataTable().ajax.url(`${process.env.REACT_APP_API_URL}api/orders/${store}/${selectedTab}/${roleId}/${sortBy}/${filterDate || 'all'}/${pastOrder}?store_name=${userInfo.store_name}`).load();

        return (
            <>
                <div className='main-inner-left-table'>
                    <table id="order-table" className='table'>
                    </table>
                </div>
            </>
        )
    }
}
