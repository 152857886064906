import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { printOrders } from "../../../redux/actions/printer/orders";
import $ from 'jquery';
import DataTable from 'datatables.net';
class printerOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            dimensionError: {},
            disableBtn: false,
            store: '',
            status: '',
            apiResponse: false,
            shops: []
        }
    }

    componentDidMount() {
        // loads before page loads
        this.props.printOrders();
        fetch(process.env.REACT_APP_API_URL + 'api/printer/shops')
            .then((response) => response.json())
            .then(shops => {
                this.setState({ shops });
            });
    }
    componentDidUpdate(prevProps) {                 // we are loading api responses here
        if (this.props.orders !== prevProps.orders) {
            let orders = this.props.orders.message;
            if (this.props.orders.success) {
                console.log("orders", orders)
                var error = {};
                if (typeof orders != 'undefined' && orders.length > 0) {
                    this.setState({ orders: orders, apiResponse: true })
                }
            }
        }
    }
    handleStoreChange = (event) => {
        this.setState({ store: event.target.value })
    }
    handleStatusChange = (event) => {
        this.setState({ status: event.target.value })
    }
    handleShip = (orderID) => {
        var error = this.state.dimensionError;
        this.setState({ disableBtn: true })
        console.log(error[orderID]);
        if (error[orderID] !== undefined) {
            alert(error[orderID])
            this.setState({ disableBtn: false })
        }
        else {
            this.props.shipOrder(orderID);
        }
    }
    render() {
        var shops = this.state.shops;
        var orders = this.state.orders;
        // var shop = '';
        var displayLoader = '';
        var opts = {};
        if (this.state.disableBtn) {
            opts['disabled'] = 'disabled';
        }
        if (this.state.apiResponse) {
            displayLoader = 'd-none';
        }
        var store = this.state.store;
        var status = this.state.status;
        if (!$.fn.DataTable.isDataTable('#myStoreTable')) {
            $('#myStoreTable').DataTable({
                processing: true,
                serverSide: true,
                // data: this.state.orders,
                ajax: process.env.REACT_APP_API_URL + 'api/printer/orders/pagination?store=' + store + '&status=' + status,
                columns: [
                    { title: "Order ID", data: "order_id" },
                    { title: "Order Number", data: "order_number" },
                    { title: "User Email", data: "user_email" },
                    // { title: "Total Price", data: "total_price" },
                    // { title: "Currency", data: "currency" },
                    // { title: "Payment Status", data: "payment_status" },
                    { title: "Store", data: "store" },
                    { title: "Delivered Since", data: "date_difference" },
                    { title: "Order Status", data: "ss_order_status" },
                    //{ title: "Processed On", data: "processed_on" },
                    // { title: "SS Order Status", data: "ss_order_status" },
                    // { title: "SS Order ID", data: "ss_orderid" },
                    {
                        data: null,
                        className: "center",
                        render: function (data) {
                            return `<a href="/printer/orderDetails/${data.order_id}/${data.shop_name}/${data.access_token}" class="btn btn-info btn-sm link" style="color:white">Order Details</a>`;
                        }
                    }
                ],
                "order": [[1, "desc"]],
                ordering: true,
                createdRow: function (row, data, dataIndex) {
                    if (data['order_status'] == 'delivered') {
                        $(row).addClass('successTableRow');
                    }
                    else if (data['order_status'] == 'in_transit') {
                        $(row).addClass('infoTableRow');
                    }
                    else if (data['order_status'] == 'failure') {
                        $(row).addClass('dangerTableRow');
                    }
                    else if (data['order_status'] == 'label_printed') {
                        $(row).addClass('warningTableRow');
                    }
                    else {
                        $(row).addClass('bg-light');
                    }
                }
            });
        }
        $('#myStoreTable').DataTable().ajax.url(process.env.REACT_APP_API_URL + 'api/printer/orders/pagination?store=' + store + '&status=' + status).load();
        // $('#myStoreTable').DataTable().ajax.reload()
        const table = {
            width: "100%",
        };
        return (
            <div className="storeOrderTable text-center" style={{ margin: "auto", height: "100%" }}>
                <img className={displayLoader} src="/media/loading.gif" style={{ maxWidth: '50px', position: "absolute", top: "50%" }} />
                {/* <div className="bg-white p-2 mb-3 text-center"><h4>{shop}</h4></div> */}
                <select className="px-5 py-2 border rounded" onChange={this.handleStoreChange}>
                    <option value="">Select Store</option>
                    {shops.map((itm, i) => (
                        <option value={itm.shop_name} key={i}>{itm.shop_name}</option>
                    ))}
                </select>
                <select className="px-5 py-2 border rounded" onChange={this.handleStatusChange}>
                    <option value="">Select Order status</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancelled">Cancelled</option>

                </select>
                <table id="myStoreTable" responsive="true" hover="true" className="project-table table table-striped" style={table}>
                </table>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
            </div>)
    }
}
const mapStateToProps = state => (
    // console.log('here',state)      // getting response  of api and loading in props
    {
        orders: state.PrinterOrders,
        auth: state.auth,
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    printOrders: () => printOrders(dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(printerOrders);