import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';
import Notificationtable from '../../components/allNotificationsTable/index';
import BreadCrumb from '../../components/breadCrumb';

class AllNotifications extends Component {
  state = {
    notifications: []
  }

  // componentDidMount() {
  //   fetch(process.env.REACT_APP_API_URL + 'api/all-notifications')
  //     .then((response) => response.json())
  //     .then(booksList => {
  //       console.log('booksList :::::::::::::', booksList)
  //       this.setState({ notifications: booksList });
  //     });
  // }

  render() {
    // let columns = [
    //   { title: "Order Id", data: "order_id" },
    //   { title: "Shop Name", data: "store" },
    //   { title: "Created On", data: "notify_date" },
    //   {
    //     data: null,
    //     className: "center",
    //     render: function (data, type, row) {
    //       return `<a href="/order-details/${data.store}/${data.order_id}" class=" btn btn-info btn-sm link" style="color:white">View</a>`;
    //     }
    //   }
    // ];
    // if (!$.fn.DataTable.isDataTable('#myTable')) {
    //   $('#myTable').DataTable({
    //     data: this.state.notifications,
    //     columns,
    //     ordering: true,
    //   });
    // }
    // const table = {
    //   width: "100%",
    // };

    return (
      <div className='row mx-0'>
          <div className='col-md-12 main-inr-all-data'>
              <div className='row mx-0 align-items-center'>
                  <div className='col-md-6 px-0'>
                      <BreadCrumb type="notification"/>
                  </div>
                  {/* <div className='col-md-6 justify-flex-end px-0'>
                      <Date handleFilterDate={handleFilterDate} filterDate={filterDate}/>
                  </div> */}
              </div>
              
              <div className='row'>
                  <div className='col-md-12'>
                      <div className='main-all-str-tbl'>
                        {/* <h3>All Notifications</h3> */}
                        <Notificationtable />
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }

}

export default AllNotifications;