import * as Actions from '../actions/cronLogs';
export const GET_CRON_SUCCESS = "GET_CRON_SUCCESS";
export const GET_CRON_FAILURE = "GET_CRON_FAILURE";

const initialState = {
    cronLogError: false,
    cronLog: "",
    cronLogSuccess: false
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_CRON_SUCCESS:
          return {
            ...state,
            cronLogSuccess: true,
            cronLogError: 'success',
            cronLog: action.data,
          };
      case Actions.GET_CRON_FAILURE:
          return {
              ...state,
              cronLogSuccess: false,
              cronLogError: 'error',
              cronLog: action.data
            };
      default:
        return state;
    }
  };
  
  export default reducer;
  