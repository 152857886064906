import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { supplSettings } from "../../../redux/actions/supplier/settings";
import { saveSettings } from "../../../redux/actions/supplier/saveSettings";
class supplierSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            editMode: false
        }
    }

    componentDidMount() {               // loads before page loads
        this.props.supplSettings();

    }

    componentDidUpdate(prevProps) {                 // we are loading api responses here
        // console.log("orders", this.props.orders)
        if (this.props.settings !== prevProps.settings) {
            if (this.props.settings.success) {
                this.setState({ settings: this.props.settings.message[0] })
            }
        }
    }
    handleEdit = () => {
        this.setState({ editMode: true })
    }
    handleSave = () => {
        if (this.state.api_key !== "" && this.state.api_secret !== "") {
            console.log("this.state.api_key",this.state.api_key)
            this.props.saveSettings(this.state.settings.api_key, this.state.settings.api_secret);
            this.setState({ editMode: false })
        } else {
            alert('Fields cannot be empty')
        }
    }
    handleChange = e => {
        let settings = this.state.settings;
        settings[e.target.name] = e.target.value;
        this.setState({
            settings
        });
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row p-5">
                        <div className="col-6">
                            <h3>ShipStation Details</h3>
                        </div>
                        <div className="col-6 text-right">
                            {this.state.editMode ?
                                <button className="btn btn-danger" onClick={this.handleSave}>Save</button>
                                :
                                <button className="btn btn-primary" onClick={this.handleEdit}>Edit</button>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>API KEY</label>
                                <input type="text" name="api_key" placeholder="key" onChange={this.handleChange} value={this.state.settings.api_key || ""} className="form-control" readOnly={!this.state.editMode} />
                            </div>
                            <div className="form-group">
                                <label>API SECRET</label>
                                <input type="text" name="api_secret" placeholder="secret" onChange={this.handleChange} value={this.state.settings.api_secret || ""} className="form-control" readOnly={!this.state.editMode} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >)
    }
}
const mapStateToProps = state => (
    // console.log('here',state)      // getting response  of api and loading in props
    {
        settings: state.SupplierSetting,
        auth: state.auth
    }
);
const mapDispatchToProps = dispatch => ({                       // calling action for api request
    supplSettings: () => supplSettings(dispatch),
    saveSettings: (key, secret) => saveSettings(key, secret, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(supplierSettings);