import React, { useEffect, useState } from 'react'
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";


export default function Steps({ orderDetail, lineItems }) {

    const [totalShipped, setTotalShipped] = useState(null)
    const [totalDelivered, setTotalDelivered] = useState(null)
    const [totalCancelled, SetTotalCancelled] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(loading && orderDetail && orderDetail.length > 0){
            let shipped = 0
            let delivered = 0 
            let cancelled = 0 
            Promise.all(orderDetail.map(async (itm) => {
                if(itm.order_status == 'Shipped' || itm.order_status == 'Complete') { 
                    shipped++
                }else if (itm.order_status == 'Delivered') {
                    delivered++
                }else if (itm.order_status == 'Cancelled') {
                    cancelled++
                }
            }))
            setLoading(false)
            setTotalDelivered(delivered)
            setTotalShipped(shipped)
            SetTotalCancelled(cancelled)
        }
    })

    return (
        <>
            <div className='col-md-12'>
                <div className='Steps-on'>
                    <>
                        <div className='1st-step'>
                            <img src={toAbsoluteUrl('/images/Tick.svg')}></img>
                            <p>Order Received</p>
                        </div>
                        <div className='2st-step'>
                            <img src={toAbsoluteUrl('/images/Tick.svg')}></img>
                            <p>In Progress</p>
                        </div>
                        {totalShipped ?
                            totalShipped > 0 ?
                                <div className='3st-step'>
                                    <img src={toAbsoluteUrl(orderDetail.length == totalShipped ? '/images/Tick.svg' :  '/images/Tick(1).svg')}></img>
                                    {orderDetail.length == totalShipped ? <p>Shipped</p> : <p>Shipped {totalShipped} Items</p>}
                                </div>
                                :
                                <div className='3st-step'>
                                    <img src={toAbsoluteUrl('/images/Tick(2).svg')}></img>
                                    <p>Shipped {totalShipped} Items</p>
                                </div>
                            :
                            totalDelivered > 0 ? 
                                <div className='3st-step'>
                                    <img src={toAbsoluteUrl('/images/Tick.svg')}></img>
                                    <p>Shipped</p>
                                </div>
                                :
                                <div className='3st-step'>
                                    <img src={toAbsoluteUrl('/images/Tick(2).svg')}></img>
                                    <p>Shipped</p>
                                </div>
                        }
                        {totalDelivered ?
                            totalDelivered > 0 ?
                                <div className='4st-step'>
                                    <img src={toAbsoluteUrl(orderDetail.length == totalDelivered ? '/images/Tick.svg' :  '/images/Tick(1).svg')}></img>
                                    {orderDetail.length == totalDelivered ? <p>Delivered</p> : <p>Delivered {totalDelivered} Items</p>}
                                </div>
                                :
                                totalDelivered > 0 ? 
                                    <div className='4st-step'>
                                        <img src={toAbsoluteUrl('/images/Tick.svg')}></img>
                                        <p>Delivered</p>
                                    </div>
                                    :
                                    <div className='4st-step'>
                                        <img src={toAbsoluteUrl('/images/Tick(2).svg')}></img>
                                        <p>Delivered {totalDelivered} Items</p>
                                    </div>
                            :
                            <div className='4st-step'>
                                <img src={toAbsoluteUrl('/images/Tick(2).svg')}></img>
                                <p>Delivered</p>
                            </div>

                        }
                    </>
                </div>
            </div>
        </>
    )
}