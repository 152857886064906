import React, { useState } from 'react'
import Usertable from '../../components/storeDetailtable/index';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';


export default function Stores() {
    const params = useParams()
    const { store } = params
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6'>
                            <div className='main-inner-breadcrum'>
                                <h4>Home - Stores - All store - {store}</h4>
                            </div>
                        </div>
                        <div className='col-md-6 justify-flex-end'>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-all-str-tbl'>
                                <Usertable />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
