import axios from "axios";
export const UPDATE_APP_CRED_SUCCESS = "UPDATE_APP_CRED_SUCCESS";
export const UPDATE_APP_CRED_FAILURE = "UPDATE_APP_CRED_FAILURE";


// calling api to login
export const updateAppCredentials = (id, api_key, api_secret, dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/shop/app/' + id, { api_key, api_secret })
        .then(response => {
            if (response.isSuccess === false) {
                dispatch({
                    type: UPDATE_APP_CRED_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: UPDATE_APP_CRED_SUCCESS,
                    message: response.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

