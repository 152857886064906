import React from 'react';
import { connect } from 'react-redux';
import '../../css/Modal.css';
import loading from '../../images/loading.gif';
import { updateChosenApp } from "../../../redux/actions/updateChosenApp";
import { getShops } from "../../../redux/actions/getShops";
class modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            shops: []
        }
    }
    componentDidMount() {
        this.props.getShops();

    }
    handleSubmitForm = async e => {
        e.preventDefault();
        var form = e.target;
        this.setState({ submitted: true })
        this.props.updateChosenApp(form.store_name.value);
    }
    componentDidUpdate(prevProps) {
        if (this.props.shops !== prevProps.shops) {
            if (this.props.shops.success) {
                this.setState({ shops: this.props.shops.message.data })
            }
        }
        if (this.props.res !== prevProps.res) {
            if (this.props.res.success) {
                this.setState({ submitted: false })
                document.getElementById('closeBtn').click();
            }
        }
    }
    handleChangeKey = e => {
        this.setState({ key: e.target.value })
    }
    handleChangeSecret = e => {
        this.setState({ secret: e.target.value })
    }
    render() {
        const shops = this.state.shops;
        return (
            <div className="main-model" >
                <div className="modal-wrapper"
                    style={{
                        transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                        opacity: this.props.show ? '1' : '0',
                    }}>
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="modal-header">
                            <h4>Shops</h4>
                        </div>
                        <div className="modal-body pb-5">

                            <div className="row">
                                <div className="col-md-12">
                                    {shops ?
                                        shops.map((itm, i) => (
                                            itm.api_key ?
                                                <div className="form-check py-3 bg-light border border-top" key={i}>
                                                    {itm.id === this.props.id ?
                                                        <input className="form-check-input" defaultChecked type="radio" value={itm.id} name="store_name" id={"store_name" + i} />
                                                        :
                                                        <input className="form-check-input" type="radio" value={itm.id} name="store_name" id={"store_name" + i} />
                                                    }
                                                    <label className="form-check-label" htmlFor={"store_name" + i}>
                                                        {itm.shop_name}
                                                    </label>
                                                </div>
                                                : ""
                                        ))
                                        :
                                        ""
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="closeBtn" className="btn btn-warning" onClick={this.props.close}>CLOSE</button>
                            <button disabled={this.state.submitted} id="continueBtn" className="btn btn-success">SAVE</button>
                            <img src={loading} id="loading" style={{ display: this.state.submitted ? "inline-block" : "none" }} width='90px' alt="loading" />
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}
const mapStateToProps = state => (
    {
        res: state.UpdateChosenApp,
        shops: state.GetShops,
    }
)
const mapDispatchToProps = dispatch => ({
    updateChosenApp: (id) => updateChosenApp(id, dispatch),
    getShops: () => getShops(dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(modal);