import axios from "axios";
export const GET_SHOP_DETAIL_SUCCESS = "GET_SHOP_DETAIL_SUCCESS";
export const GET_SHOP_DETAIL_FAILURE = "GET_SHOP_DETAIL_FAILURE";


// calling api to login
export const getShopDetail = (id, dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/shop/' + id)
        .then(response => {
            if (response.isSuccess === false) {
                dispatch({
                    type: GET_SHOP_DETAIL_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: GET_SHOP_DETAIL_SUCCESS,
                    message: response.data
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

