import axios from "axios";
export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CREATE_GIFT_FAILURE = "CREATE_GIFT_FAILURE";


// calling api to create giftcard
export const createGiftCard = (code, initial_value, note, dispatch) => {
    axios.post(process.env.REACT_APP_API_URL + 'api/create-giftcard', { code, initial_value, note }, {
        headers: {
            Authorization: 'Basic ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            if (!response.data.success) {
                dispatch({
                    type: CREATE_GIFT_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: CREATE_GIFT_SUCCESS,
                    message: response.data,
                });
            }

        }).catch(error => {
            console.log("error", error)
        });
};

