import React, { Component } from "react";
import $ from 'jquery';
import DataTable from 'datatables.net';

class StoreOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      shop: '',
      token: '',
      responseApi: false
    };
  }



  componentDidMount() {

    const info = {
      shop: this.props.match.params.shop,
      token: this.props.match.params.token
    }

    fetch(process.env.REACT_APP_API_URL + 'api/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    })
      .then((response) => response.json())
      .then(ordersList => {
        //this.setState({ ordersList });
        this.setState({ order: ordersList, responseApi: true });


      });
    //console.log(this.state.order); 
  }

  render() {
    var shop = this.props.match.params.shop;
    var token = this.props.match.params.token;
    var displayLoader = '';
    if (this.state.responseApi) {
      displayLoader = 'd-none';
    }
    let columns = [
      { title: "Order ID", data: "order_id" },
      { title: "Order Number", data: "order_number" },
      { title: "User Email", data: "user_email" },
      { title: "Total Price", data: "total_price" },
      // { title: "Currency", data: "currency" },
      { title: "Payment Status", data: "payment_status" },
      //{ title: "Processed On", data: "processed_on" },
      { title: "Order Status", data: "order_status" },
      // { title: "SS Order ID", data: "ss_orderid" },

    ];

    if (this.props.match.params.shop == 'flockandfeathers.myshopify.com') {
      columns.push({ title: "SS Order Status", data: "ss_order_status" });
    }
    columns.push({
      data: null,
      className: "center",
      render: function (data) {
        return `<a href="/orderDetails/${data.order_id}/${shop}/${token}" class="btn btn-info btn-sm link" style="color:white">Order Details</a>`;
      }
    });
    $('#myTable').DataTable({
      processing: true,
      serverSide: true,
      ajax: process.env.REACT_APP_API_URL + 'api/orders/pagination?shop=' + this.props.match.params.shop + '&token=' + this.props.match.params.token,
      // data: this.state.order,
      columns,
      ordering: true,
      "order": [[0, "desc"]],
      createdRow: function (row, data, dataIndex) {
        if (data['order_status'] == 'delivered') {
          $(row).addClass('successTableRow');
        }
        else if (data['order_status'] == 'in_transit') {
          $(row).addClass('infoTableRow');
        }
        else if (data['order_status'] == 'failure') {
          $(row).addClass('dangerTableRow');
        }
        else if (data['order_status'] == 'label_printed') {
          $(row).addClass('warningTableRow');
        }
        else {
          $(row).addClass('bg-light');
        }
      }
    });
    const table = {
      width: "100%",
    };

    return (
      <div className="storeOrderTable text-center" style={{ margin: "auto", height: "100%" }}>
        <img className={displayLoader} src="/media/loading.gif" style={{ maxWidth: '50px', position: "absolute", top: "50%" }} />
        <table id="myTable" responsive="true" hover="true" className="project-table table table-striped" style={table}>
        </table>
      </div>
    )
  }

}

export default StoreOrders;