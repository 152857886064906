import React, { useState } from 'react'
import NonApprelOrdertable from '../../components/allNonApparelOrdersTable/index';
import Date from '../../components/date/index'
import PastOrderModal from '../../components/pastOrderModel';
import { shallowEqual, useSelector } from "react-redux";
import BreadCrumb from '../../components/breadCrumb';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

export default function Stores() {
    const { user } = useSelector(
        ({ auth }) => ({
          user: auth.user,
        }),
        shallowEqual
    );
    if (user.role == undefined) {
        window.location.reload();
    }
    const { search } = useLocation()
    let store = search && search.split("=")[1] || 'all'
    const [modalShow, setModalShow] = useState(false);
    const [mode, setMode] = useState(null);
    const [filterDate, setFilterDate] = useState('');
    const [pastOrder, setPastOrder] = useState('all');

    const [selectedTab, setSelectedTab] = useState('all');
    const [edit, setEdit] = useState({
        id: '',
        data: ''
    });

    const handleBtnResponse = (data, mode) => {
        if(mode == 'add'){
            console.log('add ::::', mode)
        }else {
            console.log('mode ::::', mode)
        }
        setModalShow(true)
        setMode(mode)
    }

    const handleFilterDate = (e) => {
        setFilterDate(e.target.value)
    }

    const handlePastOrders = (value) => {
        setPastOrder(value)
        setModalShow(false)
    }

    return (
        <>
            {modalShow &&
                <PastOrderModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    handlePastOrders={handlePastOrders}
                />
            }
            <div className='row mx-0'>
                <div className='col-md-12 main-inr-all-data'>
                    <div className='row mx-0 align-items-center'>
                        <div className='col-md-6 px-0'>
                            <BreadCrumb type="orders"/>
                        </div>
                        {/* <div className='col-md-6 justify-flex-end px-0'>
                            <Date handleFilterDate={handleFilterDate} filterDate={filterDate}/>
                        </div> */}
                    </div>
                    {/* <div className='row mt-5'>
                        <div className='col-md-6'>
                            <div className='all-tabs'>
                                <button className={`${selectedTab === 'all' && 'active'}`} onClick={() => setSelectedTab('all')} >All Orders</button>
                                <button className={`${selectedTab === 'non-apparel' && 'active'}`} onClick={() => setSelectedTab('non-apparel')}>Non Apparel</button>
                                <button className={`${selectedTab === 'sanmar' && 'active'}`} onClick={() => setSelectedTab('sanmar')}>Sanmar</button>
                                <button className={`${selectedTab === 'alphabroder' && 'active'}`} onClick={() => setSelectedTab('alphabroder')}>Alphabroder</button>
                                <button className={`${selectedTab === 'ss' && 'active'}`} onClick={() => setSelectedTab('ss')}>S & S</button>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='ad-stor-btn'>
                                <button onClick={() => setModalShow(true)}>Past Orders</button>
                            </div>
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='main-all-str-tbl'>
                                <NonApprelOrdertable 
                                    store={store}
                                    filterDate={filterDate} 
                                    roleId={user.role} 
                                    handleBtnResponse={handleBtnResponse} 
                                    selectedTab={selectedTab}
                                    pastOrder={pastOrder}
                                    userInfo = {user}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
