import axios from "axios";
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS";
export const GET_SHOPS_FAILURE = "GET_SHOPS_FAILURE";


// calling api to login
export const getShops = (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + 'api/shops')
        .then(response => {
            if (response.data == undefined || response.data.length == 0) {
                dispatch({
                    type: GET_SHOPS_FAILURE,
                    message: response.data
                });
            }
            else {
                dispatch({
                    type: GET_SHOPS_SUCCESS,
                    message: response.data,
                    token: response.token
                });
            }
        }).catch(error => {
            console.log("error", error)
        });
};

